define("admin/models/payment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    number: _emberData.default.attr('number'),
    date: _emberData.default.attr('date'),
    currency: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    exchange: _emberData.default.attr('number'),
    platform: _emberData.default.attr('string'),
    paymentInvoices: _emberData.default.hasMany('invoice-payment', {
      async: false,
      allowNull: true,
      inverse: 'payment'
    }),
    invoices: _emberData.default.hasMany('invoice', {
      allowNull: true,
      inverse: 'payments',
      async: true
    })
  });

  _exports.default = _default;
});