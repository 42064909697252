define("admin/models/group-details", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    CUI: _emberData.default.attr('string'),
    J: _emberData.default.attr('string'),
    adminPhone: _emberData.default.attr('string'),
    administrator: _emberDataModelFragments.default.fragment('group-details-contact', {
      defaultValue: {
        fullName: null,
        phone: null,
        email: null
      }
    }),
    fleetManager: _emberDataModelFragments.default.fragment('group-details-contact', {
      defaultValue: {
        fullName: null,
        phone: null,
        email: null
      }
    }),
    financeManager: _emberDataModelFragments.default.fragment('group-details-contact', {
      defaultValue: {
        fullName: null,
        phone: null,
        email: null
      }
    }),
    correspondence: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    province: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    contractNumber: _emberData.default.attr('string'),
    contractDate: _emberData.default.attr('date'),
    contractDuration: _emberData.default.attr('number'),
    contractLocation: _emberData.default.attr('string'),
    pricePrDevice: _emberData.default.attr('number'),
    subscriptionFee: _emberData.default.attr('number'),
    contractETag: _emberData.default.attr('string', {
      allowNull: true
    }),
    contractFileName: _emberData.default.attr('string', {
      allowNull: true
    }),
    demoRequestETag: _emberData.default.attr('string', {
      allowNull: true
    }),
    demoRequestFileName: _emberData.default.attr('string', {
      allowNull: true
    })
  });

  _exports.default = _default;
});