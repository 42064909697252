define("admin/models/vehicle-sensor", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    type: _emberData.default.attr("string"),
    input: _emberData.default.attr("string"),
    inputUnit: _emberData.default.attr("string"),
    outputUnit: _emberData.default.attr("string"),
    flipped: _emberData.default.attr("boolean"),
    calibration: _emberDataModelFragments.default.fragmentArray("vehicle-sensor-calibration"),
    translation: _emberDataModelFragments.default.fragmentArray("vehicle-sensor-translation"),
    filters: _emberDataModelFragments.default.fragmentArray('vehicle-sensor-filter')
  });

  _exports.default = _default;
});