define("admin/components/company-offers/component", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    api: Ember.inject.service(),
    classNames: ["list-group-item"],
    tagName: 'a',
    editing: false,
    documentFile: null,
    isAddingOffer: false,
    deleteModalOffer: null,
    archiveDialog: false,
    selectedTab: "details",
    newNote: null,
    invoicingIntervals: [{
      value: 1,
      label: "Monthly"
    }, {
      value: 6,
      label: "Biannually"
    }, {
      value: 12,
      label: "Yearly"
    }],
    contractDurations: [{
      value: 12,
      label: '12 months'
    }, {
      value: 24,
      label: '24 months'
    }, {
      value: 36,
      label: '36 months'
    }, {
      value: 48,
      label: '48 months'
    }, {
      value: 60,
      label: '60 months'
    }],
    locales: [{
      name: "Romanian",
      code: "ro"
    }, {
      name: "English",
      code: "en"
    }, {
      name: "Polish",
      code: "pl"
    }, {
      name: "Danish",
      code: "da"
    }, {
      name: "Hungarian",
      code: "hu"
    }],
    selectedLocale: {
      name: "Romanian",
      code: "ro"
    },
    notesSortRules: ['createdAt:desc'],
    sortedNotes: Ember.computed.sort('company.notes', 'notesSortRules'),
    currentUser: Ember.inject.service('current-user'),
    isSuperAdmin: Ember.computed("currentUser.isSuperAdmin", function () {
      return this.get('currentUser.isSuperAdmin');
    }),
    isSuperUser: Ember.computed("currentUser.isSuperAdmin", 'currentUser.isFinancialOfficer', function () {
      return this.get('currentUser.isSuperAdmin') || this.get('currentUser.isFinancialOfficer');
    }),
    taxOptions: [{
      name: "Normala",
      value: 19
    }, {
      name: "SDD",
      value: 0
    }],
    enabledClass: Ember.computed('company.enabled', 'company.isProspect', 'isDemo', function () {
      if (this.get('company.isProspect') || this.get('isDemo')) {
        return 'info';
      } else {
        return this.get('company.enabled') ? 'success' : 'danger';
      }
    }),
    init: function init() {
      this._super.apply(this, arguments); // Set up some defaults for new companies. Sales representatives can change them afterwards


      if (this.get('company.isNew')) {
        this.set('editing', true);
        this.set('company.details.contractDate', new Date());
        this.set('company.invoicingInterval', 12);
        this.set('company.details.contractDuration', 24);
        this.set('selectedTaxOption', this.get('taxOptions')[0]);
      }

      this.set('deleteModalOffer', {
        open: false,
        offerDelete: {}
      });
    },
    didRender: function didRender() {
      var companyID = this.get('company.id');
      var selectTab = this.get('selectedTab');

      if (!companyID) {
        companyID = '';
      }

      this.$("#company-".concat(selectTab, "-").concat(companyID)).addClass('active');
      this.$("#menu-".concat(selectTab, "-").concat(companyID)).addClass('active');
    },
    showModulesOptions: Ember.computed('company.type', 'company.details.contractETag', 'company.details.demoRequestETag', 'isSuperUser', 'contactsError', function () {
      return (this.get('company.type') === 'potential-customer' && (this.get('company.details.contractETag') != null || this.get('company.details.demoRequestETag') != null) || this.get('company.type') === 'group') && this.get('isSuperUser') && !this.get('contactsError');
    }),
    hasApprovedOffers: Ember.computed('company.offers.[]', function () {
      return this.get('company.offers').find(function (offer) {
        return offer.get('approved');
      });
    }),
    detailsError: Ember.computed('nameError', 'cuiError', function () {
      return this.get('nameError') || this.get('cuiError') ? 'error' : null;
    }),
    contactsError: Ember.computed('fullNameError', 'emailError', 'phoneError', function () {
      return this.get('fullNameError') || this.get('emailError') || this.get('phoneError') ? 'error' : null;
    }),
    isDemo: Ember.computed('company.offers.[]', function () {
      var offers = this.get('company.offers');

      if (offers && offers.get('length') == 1 && offers.get('firstObject').get('documentType') === 'demo-custody-request') {
        return true;
      } else {
        return false;
      }
    }),
    validateInput: function validateInput(field, error) {
      if (this.get(field)) {
        this.set(error, null);
      } else {
        this.set(error, "error");
      }

      return this.get(error);
    },
    actions: {
      selectTab: function selectTab(targetTab) {
        var companyID = this.get('company.id');

        if (!companyID) {
          companyID = '';
        }

        this.set('selectedTab', targetTab);
        this.$(".company-tab-".concat(companyID)).removeClass('active');
        this.$(".company-tab-content-".concat(companyID)).removeClass('active');
        this.$("#company-".concat(targetTab, "-").concat(companyID)).addClass('active');
        this.$("#menu-".concat(targetTab, "-").concat(companyID)).addClass('active');
      },
      assignLocale: function assignLocale(locale) {
        this.set("selectedLocale", locale);
        Ember.Logger.log("Assets", this.get("companyAssets"));
      },
      save: function save() {
        var _this = this;

        var timezoneOffset = new Date().getTimezoneOffset();
        var date = moment(this.get('company.details.contractDate')).startOf('day');
        date.add(-timezoneOffset, 'minutes'); // Add the negative timezoneOffset at the beginning of the selected contract date to make sure that the contract date that appears in the contract matches this setting.

        this.set('company.details.contractDate', date.toDate());
        var hasId = this.get('company.id');
        var nameError = this.validateInput('company.name', 'nameError');
        var cuiError = this.validateInput('company.details.CUI', 'cuiError');

        if (nameError || cuiError || this.get("adminEmailInUse") || this.get("fleetManagerEmailInUse") || this.get("financeManagerEmailInUse")) {
          return;
        }

        Ember.Logger.log('Contract Date: ', this.get('company.details.contractDate'));

        if (this.get('company.details.administrator.phone') && !this.get('company.details.adminPhone')) {
          this.set('company.details.adminPhone', this.get('company.details.administrator.phone'));
        }

        if (!this.get('company.settings')) {
          this.set('company.settings', {});
        }

        this.set("company.settings.locale", this.get("selectedLocale.code"));
        this.set("company.settings.VAT", this.get("selectedTaxOption.value"));
        this.get('company').save().then(function (savedCompany) {
          var offerPromises = [];

          if (savedCompany.get('offers.length')) {
            savedCompany.get("offers").forEach(function (offer) {
              offer.set('companyID', savedCompany.get('id'));
              offerPromises.push(offer.save());
            });
          }

          if (offerPromises.length) {
            Ember.RSVP.all(offerPromises).then(function (results) {
              _this.sendAction('saveNewCompanyCallback', savedCompany);

              _this.set('editing', false);
            });
          } else {
            _this.sendAction('saveNewCompanyCallback', savedCompany);

            _this.set('editing', false);
          }
        }).catch(function (error) {
          if (hasId) {
            _this.set('editing', false);

            _this.get('company').rollbackAttributes();
          }

          if (error.errors) {
            _this.sendAction('errorCallback', error.errors[0]);
          } else {
            Ember.Logger.log('ERROR: ', error);
          }
        });
      },
      cancel: function cancel(error) {
        if (error) {
          Ember.Logger.error('Error', error);
        }

        this.set('editing', false);
        this.get('company').rollbackAttributes();
      },
      addOffer: function addOffer(isDemo) {
        var company = this.get('company');

        if (company.get('offers').isAny('isNew')) {
          // check if company has at least one new offer
          return 0;
        }

        Ember.Logger.log('Add offer to company: ', this.get('company.id'));
        var newOffer = this.get("store").createRecord("offer", {
          company: this.get('company'),
          details: [],
          salesAgentID: null
        });

        if (isDemo) {
          newOffer.set('documentType', 'demo-custody-request');
          newOffer.set('demo', {
            startDate: null,
            endDate: null
          });
        } else {
          var contractOffer = company.get('offers').find(function (offer) {
            return offer.get('documentType') === 'contract';
          });

          if (contractOffer) {
            newOffer.set('documentType', 'addendum');
          } else {
            newOffer.set('documentType', 'contract');
          }
        }

        company.get('offers').insertAt(0, newOffer);
        this.set('isAddingOffer', true);
      },
      download: function download(offerId) {
        this.sendAction("downloadOffer", offerId);
      },
      // TODO: unused. Delete it.
      downloadContractTemplate: function downloadContractTemplate() {
        this.sendAction("downloadContractTemplate", this.get("company.id"));
      },
      downloadDocument: function downloadDocument(documentType) {
        this.sendAction("downloadDocument", this.get("company"), documentType);
      },
      loadedFile: function loadedFile(element) {
        var file = element.target.files[0];

        if (file) {
          this.set("newFileSelected", true);
          this.set("showConfirmationButton", false);
          this.set("documentFile", file);
        } else {
          this.$("upload-contract-".concat(this.get('company.id'))).val(null);
          this.set("newFileSelected", false);
          this.set("documentFile", null);
        }
      },
      uploadDocument: function uploadDocument(documentType) {
        var _this2 = this;

        Ember.Logger.log('Company', this.get('company'));
        Ember.Logger.log('Contract', this.get('documentFile'));

        var onComplete = function onComplete() {
          Ember.Logger.log('Completed upload');

          _this2.$("upload-contract-".concat(_this2.get('company.id'))).val(null);

          _this2.set('newFileSelected', false);

          _this2.set("documentFile", null);
        };

        this.sendAction('uploadDocument', this.get("company"), this.get('documentFile'), documentType, onComplete);
      },
      showDeleteModal: function showDeleteModal(offerDelete) {
        var _this3 = this;

        if (offerDelete.get('isNew')) {
          this.get('company.offers').removeObject(offerDelete);
        } else {
          this.sendAction('deleteOffer', offerDelete, function () {
            _this3.get('company.offers').removeObject(offerDelete);
          });
        }
      },
      toggleAddOfferButton: function toggleAddOfferButton() {
        this.set('isAddingOffer', false);
        this.notifyPropertyChange('hasApprovedOffers');
      },
      archivePotentialCustomer: function archivePotentialCustomer() {
        var company = this.get('company');
        company.set('archived', true);
        company.save().then(function (result) {});
      },
      editCompany: function editCompany() {
        var _this4 = this;

        var companyId = this.get('company.id');
        var selectedLocale = this.get('company.settings.locale');
        var selectedTaxOption = this.get('company.settings.VAT');
        var filter = {
          $where: {
            company_id: companyId
          }
        };
        this.get('store').query('offer', filter).then(function (offers) {
          _this4.set('company.offers', offers);
        });
        this.get('store').query('company-note', filter).then(function (companyNotes) {
          _this4.set('company.notes', companyNotes);
        });

        if (!this.get('isSuperUser')) {
          // filter.$where['<='] = { due_date: new Date().getTime() };
          filter.$where.status = 'depasita';
        }

        this.get('store').query('invoice', {
          $where: {
            companyID: companyId
          }
        }).then(function (invoices) {
          _this4.set('company.invoices', invoices);
        }).catch(function (error) {
          Ember.Logger.error('Could not fetch invocies from the API.');
          Ember.Logger.error(error);
        }); // Ember.Logger.log('SUBSCRIPTIONS', this.get('company.subscriptions.firstObject'));

        if (selectedLocale) {
          this.set('selectedLocale', this.get('locales').find(function (locale) {
            return locale.code === selectedLocale;
          }));
        }

        if (selectedTaxOption) {
          this.set('selectedTaxOption', this.get('taxOptions').find(function (taxOption) {
            return taxOption.value === selectedTaxOption;
          }));
        }

        if (this.get('isSuperUser')) {
          this.get('store').query('order', {
            $where: {
              companyID: companyId
            }
          }).then(function (orders) {
            _this4.set('company.orders', orders);
          });
          this.set('subscription', this.get('company.subscription'));

          if (!this.get('subscription')) {
            this.set('subscription', this.get('store').createRecord('group-subscription', {
              subscriptionSeries: null,
              firstIssuingDate: new Date(),
              recurringInterval: null,
              licenses: null,
              ownedVehicles: null,
              subscriptionValue: null,
              contractValue: null,
              company: this.get('company')
            }));
          }
        }

        this.set('editing', true);
        jQuery('html, body').animate({
          scrollTop: jQuery("#company-offers-edit-".concat(this.get('company.id'))).offset().top
        }, 300);
      },
      saveNewNote: function saveNewNote() {
        var _this5 = this;

        this.get('store').createRecord('company-note', {
          company: this.get('company'),
          content: this.get('newNote')
        }).save().then(function (note) {
          _this5.get('company.notes').pushObject(note);

          _this5.set('newNote', null);
        });
      },
      selectInvoicingInterval: function selectInvoicingInterval(_ref) {
        var value = _ref.value;
        Ember.Logger.log('Interval', value);
        this.get('company').set('invoicingInterval', value);
      },
      selectContractDuration: function selectContractDuration(_ref2) {
        var value = _ref2.value;
        this.get('company').set('details.contractDuration', value);
      },
      convertToCustomer: function convertToCustomer() {
        var _this6 = this;

        var fullNameError = this.validateInput('company.details.administrator.fullName', 'fullNameError');
        var phoneError = this.validateInput('company.details.administrator.phone', 'phoneError');
        var emailError = this.validateInput('company.details.administrator.email', 'emailError'); // administrator:      MF.fragment('group-details-contact', { defaultValue: { fullName: null, phone: null, email: null } }),

        if (fullNameError || phoneError || emailError) {
          return;
        } else {
          // Do a save first in order to update the company modules
          this.get('company').save().then(function (company) {
            _this6.sendAction('convertToCustomer', _this6.get('company'), function (group) {
              var adminName = group.get("details.administrator.fullName").split(' ');
              var firstName = adminName[adminName.length - 1];
              adminName.splice(adminName.length - 1, 1); // Remove the first name

              var invitation = _this6.get("store").createRecord("user-invitation", {
                firstName: firstName,
                lastName: adminName.join(' '),
                email: group.get("details.administrator.email"),
                phoneNumber: group.get("details.administrator.phone"),
                groupID: group.get("id"),
                companyID: group.get("id").substring(0, 6),
                details: {
                  locale: group.get("settings.locale")
                },
                signup_url: _environment.default.signup_url
              });

              if (_environment.default.overrideEmail) {
                invitation.set("email", 'agj+dev' + new Date().getTime() + '@valgbar.com');
              }

              invitation.save();
            });
          }).catch(function (error) {
            Ember.Logger.log(error);
          });
        }
      },
      convertToDemoCompany: function convertToDemoCompany() {
        var _this7 = this;

        var fullNameError = this.validateInput('company.details.administrator.fullName', 'fullNameError');
        var phoneError = this.validateInput('company.details.administrator.phone', 'phoneError');
        var emailError = this.validateInput('company.details.administrator.email', 'emailError'); // administrator:      MF.fragment('group-details-contact', { defaultValue: { fullName: null, phone: null, email: null } }),

        if (fullNameError || phoneError || emailError) {
          return;
        } else {
          // Do a save first in order to update the company modules
          this.get('company').save().then(function (company) {
            _this7.sendAction('convertToDemoCompany', _this7.get('company'), function (group) {
              var adminName = group.get("details.administrator.fullName").split(' ');
              var firstName = adminName[adminName.length - 1];
              adminName.splice(adminName.length - 1, 1); // Remove the first name

              var invitation = _this7.get("store").createRecord("user-invitation", {
                firstName: firstName,
                lastName: adminName.join(' '),
                email: group.get("details.administrator.email"),
                phoneNumber: group.get("details.administrator.phone"),
                groupID: group.get("id"),
                details: {
                  locale: group.get("settings.locale")
                },
                signup_url: _environment.default.signup_url
              });

              if (_environment.default.overrideEmail) {
                invitation.set("email", 'agj+dev' + new Date().getTime() + '@valgbar.com');
              }

              invitation.save();
            });
          }).catch(function (error) {
            Ember.Logger.log(error);
          });
        }
      },
      setTaxOption: function setTaxOption(VAT) {
        this.set("selectedTaxOption", VAT);
      },
      changeSalesAgent: function changeSalesAgent() {
        this.set('changeSalesAgent', true);
      },
      setSalesAgent: function setSalesAgent(salesAgent) {
        this.set('company.salesAgent', salesAgent);
      },
      saveSalesAgent: function saveSalesAgent() {
        this.get('company').save();
        this.set('changeSalesAgent', false);
      },
      downloadProforma: function downloadProforma(offerID, callback) {
        this.sendAction('downloadProforma', offerID, callback);
      },
      validateInput: function validateInput(field, error) {
        this.validateInput(field, error);
      },
      selectPayerCompany: function selectPayerCompany(payerCompany) {
        this.get('company').set('payerCompany', payerCompany);
      },
      clearPayerCompany: function clearPayerCompany() {
        this.get('company').set('payerCompany', null);
      },
      rollback: function rollback() {
        var _this8 = this;

        this.get('api').request("potentialCustomers/".concat(this.get('company.id'), "/rollbackToProspect/")).then(function (results) {
          Ember.Logger.log(results);

          _this8.get('store').unloadRecord(_this8.get('company'));
        }).catch(function (error) {
          Ember.Logger.error(error);
        });
      },
      promoteDemoToCustoemr: function promoteDemoToCustoemr() {
        this.get('company').set('isProspect', false);
        this.get('company').save().then(function (updated) {
          Ember.Logger.log('Successfully promoted demo to customer');
        }).catch(function (error) {
          Ember.Logger.error('Could not promote to active customer', error);
        });
      },
      validateEmail: function validateEmail(valuePath) {
        var _this9 = this;

        var email = this.get(valuePath);
        var errorType = 'adminEmailError';

        switch (valuePath.split('.')[2]) {
          case "administrator":
            errorType = "adminEmailError";
            break;

          case "fleetManager":
            errorType = "fleetManagerEmailError";
            break;

          case "financeManager":
            errorType = "financeManagerEmailError";
            break;
        } // Check if this is a real email


        if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]*(\.\w{2,3})+$/.test(email)) {
          this.set(errorType, false);
          Ember.Logger.log(errorType, this.get(errorType));
          this.get('store').query('user', {
            $where: {
              email: email
            }
          }).then(function (user) {
            if (user.get('length')) {
              Ember.Logger.log('This user email is already in use... 💩. Please use another email address! 😃');

              _this9.set(errorType, 'This user email is already in use');
            }
          }).catch(function (error) {
            Ember.Logger.log('Error...', error);
          });
        } else {
          this.set(errorType, 'Invalid email address...');
        }
      },
      endDemo: function endDemo(offer) {
        this.sendAction('endDemo', offer);
      }
    }
  });

  _exports.default = _default;
});