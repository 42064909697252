define("admin/components/vehicle-sensor-calibration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GpEQ6Srq",
    "block": "{\"symbols\":[],\"statements\":[[6,\"td\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"input-group\"],[7],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"class\",\"placeholder\",\"keyUp\",\"value\"],[\"form-control input-value\",\"Input value\",[25,\"action\",[[19,0,[]],\"onChangeInputValue\"],null],[20,[\"calibration\",\"inputValue\"]]]]],false],[0,\"\\n        \"],[6,\"label\"],[9,\"class\",\"input-group-addon\"],[7],[1,[20,[\"sensor\",\"inputUnit\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"input-group\"],[7],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"class\",\"placeholder\",\"keyUp\",\"value\"],[\"form-control output-value\",\"Output value\",[25,\"action\",[[19,0,[]],\"onChangeOutputValue\"],null],[20,[\"calibration\",\"outputValue\"]]]]],false],[0,\"\\n        \"],[6,\"label\"],[9,\"class\",\"input-group-addon\"],[7],[1,[20,[\"sensor\",\"outputUnit\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"input-group\"],[7],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"disabled\",\"class\",\"placeholder\",\"value\"],[true,\"form-control annotation-value\",\"Annotation\",[20,[\"calibration\",\"annotation\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[6,\"label\"],[9,\"class\",\"input-group-addon\"],[7],[1,[20,[\"sensor\",\"outputUnit\"]],false],[0,\" / \"],[1,[20,[\"sensor\",\"inputUnit\"]],false],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n    \"],[4,\"bs-button\",null,[[\"onClick\",\"type\"],[[25,\"action\",[[19,0,[]],\"deleteSelf\"],null],\"danger\"]],{\"statements\":[[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-remove\"],[7],[8]],\"parameters\":[]},null],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/components/vehicle-sensor-calibration/template.hbs"
    }
  });

  _exports.default = _default;
});