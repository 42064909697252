define("admin/components/simple-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M69X8mX7",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[7],[8],[0,\"\\n\"],[6,\"p\"],[7],[0,\"Loading\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/components/simple-loader/template.hbs"
    }
  });

  _exports.default = _default;
});