define("admin/models/vehicle-settings", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    sensor: _emberDataModelFragments.default.fragment('vehicle-settings-sensor', {
      defaultValue: {
        fuelLevelFluctuation: null,
        allowNull: true
      }
    }),
    alerts: _emberData.default.attr(),
    route: _emberData.default.attr(),
    can: _emberDataModelFragments.default.fragment('vehicle-settings-can', {
      defaultValue: {
        fuelLevelFluctuation: null,
        fuelLevelCompensation: null
      }
    }),
    alertTriggers: _emberDataModelFragments.default.fragment('vehicle-settings-alert-triggers', {
      defaultValue: {
        fuelLevelDropStationary: 1,
        fuelConsumptionExcessive: 1
      }
    })
  });

  _exports.default = _default;
});