define("admin/components/vehicle-sensor-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S+I/5CyX",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-4\"],[7],[0,\"\\n        \"],[1,[25,\"ember-selectize\",null,[[\"content\",\"optionLabelPath\",\"optionValuePath\",\"value\",\"onchange\",\"placeholder\"],[[20,[\"filterTypes\"]],\"content.label\",\"content.value\",[20,[\"filter\",\"type\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"filter\",\"type\"]],[20,[\"selected\",\"value\"]]],null]],null],\"Select type\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"filter\",\"type\"]],\"kalman\"],null]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"col-xs-3\"],[7],[0,\"\\n            \"],[1,[25,\"input\",null,[[\"type\",\"step\",\"min\",\"placeholder\",\"class\",\"value\"],[\"number\",\"0.1\",\"0\",\"Q\",\"form-control\",[20,[\"filter\",\"parameters\",\"q\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-3\"],[7],[0,\"\\n            \"],[1,[25,\"input\",null,[[\"type\",\"step\",\"min\",\"placeholder\",\"class\",\"value\"],[\"number\",\"0.1\",\"0\",\"R\",\"form-control\",[20,[\"filter\",\"parameters\",\"r\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"col-xs-6\"],[7],[0,\"\\n            \"],[1,[25,\"input\",null,[[\"type\",\"step\",\"min\",\"placeholder\",\"class\",\"value\"],[\"number\",\"0.1\",\"0\",\"Coefficient\",\"form-control\",[20,[\"filter\",\"parameters\",\"coefficient\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[6,\"div\"],[9,\"class\",\"col-xs-2 text-center\"],[7],[0,\"\\n        \"],[4,\"bs-button\",null,[[\"size\",\"type\",\"onClick\"],[\"sm\",\"danger\",[25,\"action\",[[19,0,[]],\"destroy\",[20,[\"filter\"]]],null]]],{\"statements\":[[0,\"Remove\"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/components/vehicle-sensor-filter/template.hbs"
    }
  });

  _exports.default = _default;
});