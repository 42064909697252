define("admin/serializers/vehicle", ["exports", "admin/serializers/application", "admin/utils/array"], function (_exports, _application, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    _normalizeElement: function _normalizeElement(element) {
      if (element.fuel != null && element.fuel.tanks != null) {
        element.fuel.tanks.forEach(function (fuelTank) {
          var chunked = (0, _array.chunk)(fuelTank.calibration, 2);
          fuelTank.calibration = [];
          chunked.forEach(function (calibration) {
            fuelTank.calibration.push({
              level: calibration[0],
              voltage: calibration[1]
            });
          });
        });
      } else {
        if (!element.fuel) {
          element.fuel = {
            tanks: []
          };
        } else {
          element.fuel.tanks = [];
        }
      }

      if (element.sensors != null) {
        element.sensors.forEach(function (sensor) {
          if (!sensor.filters) return;
          sensor.filters.forEach(function (filter) {
            if (!filter.parameters || !Array.isArray(filter.parameters)) return;

            if (filter.type === 'kalman' && filter.parameters.length > 1) {
              filter.parameters = {
                q: filter.parameters[0],
                r: filter.parameters[1]
              };
            } else if (['exponential', 'custom-temp-1'].includes(filter.type) && filter.parameters.length > 0) {
              filter.parameters = {
                coefficient: filter.parameters[0]
              };
            }
          });
        });
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      var _this = this;

      if (Array.isArray(payload.vehicle)) {
        payload.vehicle.forEach(function (element) {
          _this._normalizeElement(element);
        });
      } else {
        this._normalizeElement(payload.vehicle);
      }

      var done = this._super.apply(this, arguments);

      return done;
    },
    // serialize (snapshot, options) {
    serialize: function serialize() {
      var done = this._super.apply(this, arguments);

      if (done.fuel.tanks != null) {
        done.fuel.tanks.forEach(function (fuelTank) {
          var converted = [];
          fuelTank.calibration.forEach(function (calibration) {
            converted.push(calibration.level);
            converted.push(calibration.voltage);
          });
          fuelTank.calibration = converted;
        });
      }

      if (done.sensors != null) {
        done.sensors.forEach(function (sensor) {
          if (!sensor.filters) return;
          sensor.filters.forEach(function (filter) {
            filter.parameters = Object.values(filter.parameters);
          });
        });
      }

      return done;
    },
    keyForRelationship: function keyForRelationship(key, relationship) {
      if (key === 'device') {
        if (relationship === 'belongsTo') {
          return 'deviceSN';
        } else {
          return "".concat(key, "ID");
        }
      }

      if (relationship === 'belongsTo' || relationship === 'hasMany') {
        return "".concat(key, "ID");
      }
    }
  });

  _exports.default = _default;
});