define("admin/serializers/user-invitation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      return {
        user: json
      };
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return "user";
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return "user";
    },
    primaryKey: "id",
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      id = payload.user.id;
      payload = payload.user;
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});