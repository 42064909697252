define("admin/routes/subscriptions", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    api: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        subscriptions: this.get('store').findAll('subscription-statistic'),
        payerCompanies: this.get('store').findAll('payer-company')
      });
    }
  });

  _exports.default = _default;
});