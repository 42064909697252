define("admin/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    localSettings: Ember.inject.service('local-settings'),
    currentUser: Ember.inject.service('current-user'),
    isSuperAdmin: Ember.computed("currentUser.isSuperAdmin", function () {
      Ember.Logger.log('Is super admin: ', this.get('currentUser.isSuperAdmin'));
      return this.get('currentUser.isSuperAdmin');
    }),
    isFinancialOfficer: Ember.computed("currentUser.isFinancialOfficer", function () {
      return this.get('currentUser.isFinancialOfficer');
    }),
    isSalesAgent: Ember.computed("currentUser.isSalesAgent", function () {
      return this.get('currentUser.isSalesAgent');
    }),
    isElectrician: Ember.computed("currentUser.isSuperAdmin", function () {
      return this.get('currentUser.isElectrician');
    }),
    isOperationalManager: Ember.computed("currentUser.isOperationalManager", function () {
      return this.get('currentUser.isOperationalManager');
    }),
    isSupportEngineer: Ember.computed("currentUser.isSupportEngineer", function () {
      return this.get('currentUser.isSupportEngineer');
    }),
    // isSuperAdmin: false,
    init: function init() {
      this._super(); // Ember.Logger.log('Role', this.get('session.session.authenticated.profile.role'));


      this.get('notifications').setDefaultAutoClear(true);
      this.set('collapsed', true);
      this.set('dropdownIsOpen', false);
      var localSettings = this.get('localSettings');
      var selectedPlatform = localSettings.get('settings.selectedPlatform');

      if (!selectedPlatform) {
        localSettings.set('settings.selectedPlatform', {
          label: 'Tracknamic',
          value: 'tracknamic'
        });
      }
    },
    platform: Ember.computed('localSettings.settings.selectedPlatform', function () {
      var localSettings = this.get('localSettings');
      var selectedPlatform = localSettings.get('settings.selectedPlatform');
      Ember.Logger.log('selectedPlatform', selectedPlatform);

      if (!selectedPlatform) {
        selectedPlatform = {
          label: 'Tracknamic',
          value: 'tracknamic'
        };
      }

      return selectedPlatform.label;
    }),
    platforms: [{
      label: 'Tracknamic',
      value: 'tracknamic'
    }, {
      label: 'Farmis',
      value: 'titan'
    }, {
      label: 'Siteis',
      value: 'siteis'
    }],
    actions: {
      selectPlatform: function selectPlatform(platform) {
        var localSettings = this.get('localSettings');
        var currentPlatform = localSettings.get('settings.selectedPlatform');
        localSettings.set('settings.selectedPlatform', platform);
        this.set('dropdownIsOpen', false);

        if (platform.value != currentPlatform.value) {
          location.reload();
        }
      }
    }
  });

  _exports.default = _default;
});