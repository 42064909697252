define("admin/templates/protected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQogZ2Y0",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h2\"],[7],[0,\"Example of protected route that requires the user to be authenticated.\"],[8],[0,\"\\n\\n\"],[6,\"h3\"],[7],[0,\"Secret code is 12345\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/protected.hbs"
    }
  });

  _exports.default = _default;
});