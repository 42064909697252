define("admin/models/group-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // companyID: DS.attr('string'),
    company: _emberData.default.belongsTo('company', {
      polymorphic: true,
      inverse: 'subscriptions'
    }),
    subscriptionSeries: _emberData.default.attr('string'),
    firstIssuingDate: _emberData.default.attr('date'),
    recurringInterval: _emberData.default.attr('number'),
    licenses: _emberData.default.attr('number'),
    ownedVehicles: _emberData.default.attr('number'),
    subscriptionValue: _emberData.default.attr('number'),
    contractValue: _emberData.default.attr('number'),
    platform: _emberData.default.attr('string'),
    recurringInvoiceReferenceDate: _emberData.default.attr('date', {
      allowNull: true
    })
  });

  _exports.default = _default;
});