define("admin/controllers/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    api: Ember.inject.service('api'),
    currentUser: Ember.inject.service('current-user'),
    selectedTab: 'deviceTypes',
    removePayerCompanyModal: {
      open: false,
      payerCompany: null
    },
    isSuperAdmin: Ember.computed("currentUser.isSuperAdmin", function () {
      return this.get('currentUser.isSuperAdmin');
    }),
    isSuperUser: Ember.computed("currentUser.isSuperAdmin", 'currentUser.isFinancialOfficer', function () {
      return this.get('currentUser.isSuperAdmin') || this.get('currentUser.isFinancialOfficer');
    }),
    userIsDeveloper: Ember.computed('currentUser.isSuperAdmin', function () {
      return this.get('currentUser.user.email') === 'agj@valgbar.com';
    }),
    actions: {
      selectTab: function selectTab(tab) {
        this.set('selectedTab', tab);
      },
      cancelNewDevice: function cancelNewDevice() {
        this.set('showNewDeviceRow', false);
      },
      cancelNewService: function cancelNewService() {
        this.set('showNewServiceRow', false);
      },
      addDevice: function addDevice() {
        this.set('showNewDeviceRow', true);
        var newDeviceType = this.get("store").createRecord("device-type", {
          name: null,
          price: {
            min: 0,
            max: 0
          },
          setup: {
            min: 0,
            max: 0
          },
          monthlyCustody: {
            min: 0,
            max: 0
          },
          yearlyCustody: {
            min: 0,
            max: 0
          },
          services: Ember.A([])
        });
        this.set('newDevice', newDeviceType);
      },
      addService: function addService() {
        this.set('showNewServiceRow', true);
        var newServiceType = this.get("store").createRecord("service-type", {
          name: null,
          monthlyFee: {
            min: 0,
            max: 0
          },
          yearlyFee: {
            min: 0,
            max: 0
          }
        });
        this.set('newService', newServiceType);
      },
      editService: function editService() {},
      updateOldDeviceTypes: function updateOldDeviceTypes() {
        this.get('api').request('deviceTypes/updateOldDeviceTypes', {}).then(function (result) {
          Ember.Logger.log('Updated old device types: ', result);
        }).catch(function (error) {
          Ember.Logger.error('Failed to update old device types: ', error);
        });
      },
      copyDeviceTypesFromProduction: function copyDeviceTypesFromProduction() {
        this.get('api').request('deviceTypes/copyDeviceTypesFromProduction', {}).then(function (result) {
          Ember.Logger.log('Cloned device types from production: ', result);
        }).catch(function (error) {
          Ember.Logger.error('Failed cloning device types from production: ', error);
        });
      },
      migrateSubscriptions: function migrateSubscriptions() {
        this.get('api').request('groupSubscriptions/migrate', {}).then(function (result) {
          Ember.Logger.log('Test result: ', result);
        }).catch(function (error) {
          Ember.Logger.error('Test failed: ', error);
        });
      },
      undoOldOrders: function undoOldOrders() {
        this.get('api').request('orders/undoOldOrders', {}).then(function (result) {
          Ember.Logger.log('Undo orders result: ', result);
        }).catch(function (error) {
          Ember.Logger.error('Undo orders failed: ', error);
        });
      },
      searchDetail: function searchDetail() {
        var _this = this;

        this.get('api').request('orders/searchDetails', {
          method: 'POST',
          data: {
            match: this.get('searchOrderDetails')
          }
        }).then(function (result) {
          _this.set('foundOffers', result.match);
        }).catch(function (error) {
          Ember.Logger.error('Failed to find matching orders: ', error);
        });
      },
      correctDuplicateCompanies: function correctDuplicateCompanies() {
        this.get('api').request('orders/correctDuplicateCompanies', {}).then(function (result) {
          Ember.Logger.log(result);
        }).catch(function (error) {
          Ember.Logger.error('Failed to find matching orders: ', error);
        });
      },
      addPayerCompany: function addPayerCompany() {
        this.set('newPayerCompany', this.get('store').createRecord('payer-company', {
          name: null,
          TIN: null,
          country: null,
          address: null,
          contacts: Ember.A(),
          details: null,
          VAT: 19 // Let this be the default

        }));
        this.set('showNewPayerCompany', true);
      },
      cancelNewPayerCompany: function cancelNewPayerCompany() {
        this.set('newPayerCompany', false);
        this.set('showNewPayerCompany', false);
      },
      removePayerCompany: function removePayerCompany(payerCompany) {
        Ember.Logger.log('Remove Payer Company', payerCompany);
        this.set('removePayerCompanyModal', {
          open: true,
          payerCompany: payerCompany
        });
      },
      confirmRemovePayerCompany: function confirmRemovePayerCompany() {
        var _this2 = this;

        this.get('removePayerCompanyModal.payerCompany').destroyRecord().then(function (response) {
          _this2.set('removePayerCompanyModal', {
            open: false,
            payerCompany: null
          });
        });
      },
      cancelRemovePayerCompany: function cancelRemovePayerCompany() {
        this.set('removePayerCompanyModal', {
          open: false,
          payerCompany: null
        });
      },
      correctOrderSubscriptions: function correctOrderSubscriptions() {
        this.get('api').request('orderSubscriptions/makeCorrections', {}).then(function (result) {
          Ember.Logger.log('Corrected subscriptions: ', result);
        }).catch(function (error) {
          Ember.Logger.error('Failed to correct subscriptions: ', error);
        });
      } // insert() {
      //     this.get('api').request('users/insert', {})
      // 	.then(result => {
      // 		Ember.Logger.log('Corrected subscriptions: ', result);
      // 	}).catch(error => {
      // 		Ember.Logger.error('Failed to correct subscriptions: ', error);
      // 	});
      // }

    }
  });

  _exports.default = _default;
});