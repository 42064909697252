define("admin/components/order-shipping-product/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('newItemSN', null);
    },
    stillNeeded: Ember.computed('product.quantity', 'product.items.length', function () {
      var diff = parseInt(this.get('product.quantity')) - parseInt(this.get('product.items.length'));

      if (diff >= 0) {
        return diff;
      } else {
        return 'Exceeded by ' + diff;
      }
    }),
    allowAdd: Ember.computed('newItemSN', function () {
      var sn = this.get('newItemSN');
      return sn && sn.length >= 6;
    }),
    nullSNs: Ember.computed('product.items.@each', function () {
      return this.get('product.items').reduce(function (total, item) {
        if (!item.belongsTo('device').id()) {
          total++;
        }

        return total;
      }, 0);
    }),
    actions: {
      addItem: function addItem() {
        var _this = this;

        var deviceSN = this.get('newItemSN');
        var product = this.get('product');
        this.set('error', null);
        this.get('store').findRecord('device', deviceSN).then(function (device) {
          var companyID = device.get('companyID');
          var orderCompanyID = product.get('order.company.id');

          if (companyID && companyID != orderCompanyID) {
            _this.set('error', {
              message: "Device SN ".concat(deviceSN, " is already assigned to another organization. ").concat(companyID, " : ").concat(device.get('group.name'), "."),
              type: 'taken',
              device: device
            });

            _this.set('newItemSN', null);

            return null;
          }

          if (device.get('deviceType.id') !== product.get('deviceType.id')) {
            _this.set('error', {
              message: "The device you entered is of type \"".concat(device.get('deviceType.name'), "\". Expected a device of the \"").concat(product.get('deviceType.name'), "\" type."),
              type: 'mismatch',
              device: device
            });

            return null;
          } else {
            var newProductItem = _this.get('store').createRecord('order-product-item', {
              orderProduct: product,
              device: device,
              status: null
            });

            return newProductItem.save().catch(function (error) {
              newProductItem.destroyRecord();

              _this.set('error', {
                message: error.errors[0].message,
                type: 'generic'
              });
            });
          }
        });
      },
      addBulkItems: function addBulkItems() {
        var _this2 = this;

        var inputText = this.get('bulkSNs');
        var product = this.get('product');
        var SNs = [];
        var bulkErrors = [];

        if (inputText.length) {
          SNs = inputText.split(',');
        }

        return this.get('store').query('device', {
          $where: {
            sn: SNs
          }
        }).then(function (devices) {
          devices.forEach(function (device, index) {
            if (index < product.get('quantity')) {
              var companyID = device.get('companyID');
              var orderCompanyID = product.get('order.company.id');

              if (companyID && companyID != orderCompanyID) {
                bulkErrors.push("Device SN ".concat(device.get('sn'), " is already assigned to another organization. ").concat(companyID, " : ").concat(device.get('group.name'), "."));
                return null;
              }

              if (device.get('deviceType.id') !== product.get('deviceType.id')) {
                bulkErrors.push("Device SN ".concat(device.get('sn'), " is of type \"").concat(device.get('deviceType.name'), "\". Expected a device of the \"").concat(product.get('deviceType.name'), "\" type."));
                return null;
              }
            } else {
              bulkErrors.push("The number of selected devices excedes the ordered quantity for ".concat(device.get('deviceType.name'), "."));
            }
          });

          if (bulkErrors.length) {
            _this2.set('bulkErrors', bulkErrors);

            return Ember.RSVP.reject(bulkErrors);
          } else {
            _this2.set('bulkErrors', null);

            var productItemPromises = [];
            productItemPromises = devices.map(function (device) {
              var newProductItem = _this2.get('store').createRecord('order-product-item', {
                orderProduct: product,
                device: device,
                status: null
              });

              return newProductItem.save().catch(function (error) {
                newProductItem.destroyRecord();

                _this2.set('error', error.errors[0].message);
              });
            });
            return Ember.RSVP.all(productItemPromises);
          }
        }).then(function (result) {
          _this2.set('bulkSNs', null);

          _this2.set('newItemSN', null);
        }).catch(function (error) {
          Ember.Logger.error(error);
        });
      },
      removeItem: function removeItem(item) {
        item.set('deleting', true);
      },
      confirmRemoveItem: function confirmRemoveItem(item) {
        item.destroyRecord();
      },
      cancelRemoveItem: function cancelRemoveItem(item) {
        item.set('deleting', false);
      },
      clearNullSNs: function clearNullSNs() {
        var promises = [];
        this.get('product.items').forEach(function (item) {
          if (!item.belongsTo('device').id()) {
            promises.push(item.destroyRecord());
          }
        });
        return Ember.RSVP.all(promises).then(function (results) {
          Ember.Logger.log('Removed NULL SNs: ', results);
        });
      },
      overrideDeviceType: function overrideDeviceType(device) {
        var _this3 = this;

        var product = this.get('product');
        device.set('deviceType', product.get('deviceType'));
        device.save().then(function (device) {
          var newProductItem = _this3.get('store').createRecord('order-product-item', {
            orderProduct: product,
            device: device,
            status: null
          });

          return newProductItem.save().catch(function (error) {
            newProductItem.destroyRecord();

            _this3.set('error', {
              message: error.errors[0].message,
              type: 'generic'
            });
          });
        }).then(function (productItem) {
          _this3.set('newItemSN', null);
        });
      }
    }
  });

  _exports.default = _default;
});