define("admin/components/bs-switch", ["exports", "ember-cli-bootstrap-switch/components/bs-switch"], function (_exports, _bsSwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  	This is just a proxy file requiring the component from the /addon folder and
  	making it available to the dummy application!
   */
  var _default = _bsSwitch.default;
  _exports.default = _default;
});