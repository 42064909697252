define("admin/components/fix-order-product-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    actions: {
      update: function update() {
        var _this = this;

        var item = this.get('item');
        Ember.Logger.log({
          deviceSN: item.get('deviceSN'),
          companyID: item.get('orderProduct.order.company.id')
        });
        return this.get('api').request('orderProductItems/verify', {
          method: 'POST',
          data: {
            deviceSN: item.get('deviceSN'),
            companyID: item.get('orderProduct.order.company.id')
          }
        }).then(function (response) {
          if (response.available) {
            return item.save();
          } else {
            _this.set('feedback', response.feedback);

            Ember.RSVP.resolve(null);
          }
        }).then(function (item) {
          if (item) {
            Ember.Logger.log('Saved item: ', item);
          }
        });
      }
    }
  });

  _exports.default = _default;
});