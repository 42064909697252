define("admin/models/subscription-statistic", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    payerCompany: _emberData.default.belongsTo('payer-company'),
    inUseDevicesQty: _emberData.default.attr('number'),
    subscriptionDevicesQty: _emberData.default.attr('number'),
    diff: _emberData.default.attr('number'),
    diffLicenses: _emberData.default.attr('number'),
    orderDevices: _emberData.default.attr(),
    devices: _emberData.default.attr(),
    subscription: _emberData.default.attr()
  });

  _exports.default = _default;
});