define("admin/components/vehicle-refuels/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service('api'),
    store: Ember.inject.service(),
    classNames: ["refuel"],
    tagName: "div",
    editing: false,
    refuelData: [],
    graphData: [],
    graphDataLoading: false,
    companyVehicles: [],
    filter: {
      dateBefore: (0, _moment.default)().subtract(1, 'days'),
      dateAfter: (0, _moment.default)(),
      vehicle: null
    },
    sourceFilter: Ember.A([{
      value: 'all',
      placeholder: 'All'
    }, {
      value: 'CAN',
      placeholder: 'CAN'
    }, {
      value: 'Sensor',
      placeholder: 'Sensor'
    }]),
    refuelSource: 'all',
    addRefuel: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // if (this.get('serviceType.isNew')) {
      //     this.set('isNewService', true);
      //     this.set('editing', true);
      // }


      this.set('refuelsDateBefore', (0, _moment.default)().subtract(1, 'days'));
      this.set('refuelsDateAfter', (0, _moment.default)());

      if (!this.companyVehicles.length && this.get('company_id')) {
        var vehiclesPromise = this.get('store').query('vehicle', {
          $where: {
            'group_id': this.get('company_id')
          }
        });
        vehiclesPromise.then(function (vehicles) {
          _this.set('companyVehicles', vehicles.map(function (vehicle) {
            return {
              id: vehicle.get('id'),
              licensePlate: vehicle.get('licensePlate')
            };
          }));
        });
      }
    },
    actions: {
      triggerAddRefuel: function triggerAddRefuel() {
        this.set('addRefuel', !this.get('addRefuel'));
      },
      selectCompanyVehicle: function selectCompanyVehicle(data) {
        var id = data ? data.id : null;
        this.set('filter.vehicle', id);
        this.send('refreshRefuelData');
      },
      selectRefuelSource: function selectRefuelSource(data) {
        this.set('refuelSource', data.value);
      },
      refreshRefuelData: function refreshRefuelData() {
        if (this.filter.vehicle) {
          this.send('getGraphData', this.filter.vehicle, this.get('filter.dateBefore'), this.get('filter.dateAfter'));
          this.send('getRefuelData', this.filter.vehicle, this.get('filter.dateBefore'), this.get('filter.dateAfter'));
        }
      },
      refreshRefuelList: function refreshRefuelList() {
        if (this.filter.vehicle) {
          this.send('getRefuelData', this.filter.vehicle, this.get('filter.dateBefore'), this.get('filter.dateAfter'));
        }
      },
      incrementDate: function incrementDate(amount) {
        this.set('filter.dateBefore', (0, _moment.default)(this.get('filter.dateBefore')).add(amount, 'days'));
        this.set('filter.dateAfter', (0, _moment.default)(this.get('filter.dateAfter')).add(amount, 'days'));
        this.send('refreshRefuelData');
      },
      subtractDate: function subtractDate(amount) {
        this.set('filter.dateBefore', (0, _moment.default)(this.get('filter.dateBefore')).subtract(amount, 'days'));
        this.set('filter.dateAfter', (0, _moment.default)(this.get('filter.dateAfter')).subtract(amount, 'days'));
        this.send('refreshRefuelData');
      },
      getRefuelData: function getRefuelData(vehicle, startTime, endTime) {
        var _this2 = this;

        this.get('api').post('refuels/computed', {
          data: {
            $filter: {
              vehicles: [vehicle],
              startDate: startTime.valueOf(),
              endDate: endTime.valueOf()
            }
          }
        }).then(function (data) {
          var refuels = data.refuels.map(function (refuel) {
            refuel.duration = Math.round(refuel.duration / 60) + 's';
            refuel.source = refuel.diffFuelLevelCAN != null ? 'CAN' : refuel.diffFuelLevel != null ? 'Sensor' : 'Manual';
            refuel.date = (0, _moment.default)(refuel.date).format('YYYY/MM/DD HH:mm');
            return refuel;
          });
          Ember.Logger.log(refuels);

          _this2.set('refuelData', refuels);
        }).catch(function (err) {
          Ember.Logger.debug('Error:', err);

          _this2.set('refuelData', []);
        });
      },
      getGraphData: function getGraphData(vehicle, startTime, endTime) {
        var _this3 = this;

        this.set('graphDataLoading', true);
        this.set('graphData', []);
        this.get('api').post("refuels/graph", {
          data: {
            $filter: {
              vehicles: [vehicle],
              startDate: startTime.valueOf(),
              endDate: endTime.valueOf()
            }
          }
        }).then(function (data) {
          _this3.set('graphData', data[vehicle]);

          _this3.set('graphDataLoading', false);
        }).catch(function (err) {
          Ember.Logger.debug('Error:', err);

          _this3.set('graphData', []);

          _this3.set('graphDataLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});