define("admin/models/offer-proforma", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    type: _emberData.default.attr('string'),
    origin: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    document: _emberData.default.attr('string')
  });

  _exports.default = _default;
});