define("admin/components/fuel-level-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gM8Zs7dH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1],[0,\"\\n\"],[4,\"if\",[[20,[\"noData\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"messages/dc-info\",null,[[\"class\",\"msg\"],[\"small\",[25,\"t\",[\"dashboard.noData\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/components/fuel-level-chart/template.hbs"
    }
  });

  _exports.default = _default;
});