define("admin/models/order-product-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    orderProduct: _emberData.default.belongsTo('order-product', {
      inverse: 'items'
    }),
    device: _emberData.default.belongsTo('device'),
    status: _emberData.default.attr('string'),
    deleting: _emberData.default.attr('boolean', {
      defaultValue: false,
      allowNull: true
    }),
    installationTask: _emberData.default.belongsTo('installation-task')
  });

  _exports.default = _default;
});