define("admin/controllers/payments", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    addingPayment: false,
    queryParams: ['startDate', 'endDate'],
    startDate: null,
    endDate: null,
    companies: Ember.computed('model.groups', 'model.potentialCustomers', function () {
      var companies = Ember.A(this.get('model.groups').toArray());
      companies.pushObjects(this.get('model.potentialCustomers').toArray());
      return companies;
    }),
    // invoices: Ember.computed('model.payments', function() {
    // }),
    init: function init() {
      this._super.apply(this, arguments);

      var startDate = (0, _moment.default)().startOf('month');
      var endDate = (0, _moment.default)().endOf('month');
      this.set('paymentsStartDate', startDate);
      this.set('paymentsEndDate', endDate);
      this.set('startDate', startDate.valueOf());
      this.set('endDate', endDate.valueOf());
    },
    actions: {
      addPayment: function addPayment() {
        this.set('addingPayment', true);
      },
      cancelNewPayment: function cancelNewPayment() {
        this.toggleProperty('addingPayment');
      },
      changeMonth: function changeMonth(direction) {
        var startDate = (0, _moment.default)(this.get('paymentsStartDate'));
        var endDate = (0, _moment.default)(this.get('paymentsEndDate'));

        if (direction == "prev") {
          this.set('paymentsStartDate', startDate.subtract(1, 'month').startOf('month'));
          this.set('paymentsEndDate', endDate.subtract(1, 'month').endOf('month'));
        } else {
          this.set('paymentsStartDate', startDate.add(1, 'month').startOf('month'));
          this.set('paymentsEndDate', endDate.add(1, 'month').endOf('month'));
        }

        this.set('startDate', startDate.valueOf());
        this.set('endDate', endDate.valueOf());
      },
      changedStartDate: function changedStartDate(date) {
        this.set('startDate', (0, _moment.default)(date).valueOf());
      },
      changedEndDate: function changedEndDate(date) {
        this.set('endDate', (0, _moment.default)(date).valueOf());
      }
    }
  });

  _exports.default = _default;
});