define("admin/components/device-type-edit/component", ["exports", "admin/mixins/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validations.default, {
    // classNames: ['form-row', 'row', 'deviceTypeEdit'],
    // tagName: 'form',
    classNames: ["list-group-item from-horizontal"],
    tagName: "a",
    serviceTypes: [],
    deviceSelectedItems: [],
    formattedSelection: [],
    stringFormattedSelection: '',
    renderServices: function renderServices() {
      var _this = this;

      this.set('serviceTypes', this.get('serviceTypes'));
      var deviceServices = this.get('deviceType.services');

      if (deviceServices && deviceServices.length > 0) {
        deviceServices.forEach(function (item) {
          var obj = _this.serviceTypes.filter(function (type) {
            return type.id == item;
          })[0];

          if (obj) {
            _this.formattedSelection.addObject(obj);
          }
        });
        this.set('deviceSelectedItems', this.formattedSelection);
        this.set('stringFormattedSelection', this.formattedSelection.map(function (selection) {
          return Ember.get(selection, 'name');
        }).join(', '));
      } else {
        this.set('stringFormattedSelection', 'No services selected');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.deviceSelectedItems = [];
      this.formattedSelection = [];
      this.stringFormattedSelection = '';

      if (this.get('deviceType.isNew')) {
        this.set('isNewDevice', true);
        this.set('editing', true);
      } // this.serviceTypes = this.get('serviceTypes')
      // let deviceServices = this.get('deviceType.services')
      // if (deviceServices) {
      //     deviceServices.forEach(item => {
      //         let obj = this.serviceTypes.filter(type => type.id == item)[0]
      //         if(obj) {
      //             this.formattedSelection.addObject(obj)
      //         }
      //     })
      //     this.deviceSelectedItems = this.formattedSelection
      //     this.stringFormattedSelection = this.formattedSelection.map(selection => Ember.get(selection, 'name')).join(', ')
      // }


      this.renderServices();
    },
    actions: {
      save: function save() {
        var _this2 = this;

        var deviceTypeValidations = this.validateDeviceType(
        /*this.get('deviceTypeValidations'),*/
        this.get('deviceType'));
        this.set('deviceTypeValidations', deviceTypeValidations);
        this.set('saveClicked', true);
        this.set('deviceType.importDesignation', this.get('deviceType.importDesignation').toLowerCase().trim()); // if (deviceTypeValidations.allValid) {

        this.get('deviceType').save().then(function () {
          _this2.set('editing', false);

          if (_this2.get('isNewDevice')) {
            _this2.cancelNewDevice();
          }
        }).catch(function () {
          _this2.get('deviceType').rollbackAttributes();

          if (_this2.get('isNewDevice')) {
            _this2.cancelNewDevice();
          }
        }); // }
      },
      cancel: function cancel() {
        if (this.get('isNewDevice')) {
          this.cancelNewDevice();
        }

        this.get('deviceType').rollbackAttributes();
        this.set('editing', false);
      },
      edit: function edit() {
        this.set('editing', true);
      },
      addPossibleService: function addPossibleService(service) {
        var arr = this.get('deviceType.services');
        if (arr === null) arr = Ember.A([]);
        this.get('deviceType').set('services', arr.addObject(service.id)); // let serviceName = Ember.get(service, 'name')
        // this.set('stringFormattedSelection', this.stringFormattedSelection + `, ${serviceName}`)

        this.renderServices();
      },
      removePossibleService: function removePossibleService(service) {
        var arr = this.get('deviceType.services');
        if (arr === null) arr = Ember.A([]);
        this.get('deviceType').set('services', arr.filter(function (elem) {
          return elem != service.id;
        })); // let serviceName = Ember.get(service, 'name')
        // this.set('stringFormattedSelection', this.stringFormattedSelection.slice(this.stringFormattedSelection.search('/' + serviceName + '/') + serviceName.length + 2, this.stringFormattedSelection.length))
        // Ember.Logger.log(this.stringFormattedSelection)

        this.renderServices();
      }
    }
  });

  _exports.default = _default;
});