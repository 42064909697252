define("admin/models/device", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    config: _emberDataModelFragments.default.fragment("device-config"),
    sn: _emberData.default.attr("string"),
    type: _emberData.default.attr("string", {
      allowNull: true
    }),
    companyID: _emberData.default.attr("string", {
      allowNull: true
    }),
    // groupID:        DS.attr("string",       {allowNull: true}),
    // vehicleID:      DS.attr("string",       {allowNull: true}),
    group: _emberData.default.belongsTo("group", {
      allowNull: true,
      async: true
    }),
    vehicle: _emberData.default.belongsTo("vehicle", {
      allowNull: true,
      async: true
    }),
    electrician: _emberData.default.belongsTo("user", {
      allowNull: true,
      async: true
    }),
    deviceType: _emberData.default.belongsTo("device-type", {
      allowNull: true
    }),
    // price:          DS.attr("number",       {allowNull: true}),
    // monthlyFee:     DS.attr("number",       {allowNull: true}),
    notes: _emberData.default.attr("string"),
    ignoreStatus: _emberData.default.attr("boolean"),
    devicesStock: _emberData.default.belongsTo("devices-stock", {
      allowNull: true
    }),
    transfer: _emberDataModelFragments.default.fragment('device-transfer-data', {
      allowNull: true
    }),
    hasGroup: Ember.computed('group', function () {
      return this.get('group') && this.get('group.id') ? true : false;
    }),
    status: Ember.computed('vehicle.status.updatedAt', function () {
      if (Ember.isEmpty(this.get('vehicle.status.updatedAt'))) {
        return 'unknown';
      }

      var now = moment(); //

      var lastUpdatedTime = moment(this.get('vehicle.status.updatedAt')); // let offlineThreshold = lastUpdatedTime.diff(1, days)

      var warningThreshold = now.clone().subtract(15, 'minutes');
      var offlineThreshold = now.clone().subtract(1, 'day');

      if (lastUpdatedTime.isSameOrBefore(warningThreshold, 'minute') && lastUpdatedTime.isSameOrAfter(offlineThreshold)) {
        return 'warning';
      } else if (lastUpdatedTime.isSameOrBefore(offlineThreshold)) {
        return 'offline';
      }

      return 'online';
    }),
    noteShortened: Ember.computed('notes', function () {
      var note = this.get("notes");

      if (!Ember.isEmpty(note)) {
        if (note.length > 30) {
          return note.substring(0, 29);
        } else {
          return note;
        }
      }
    }),
    hasComment: Ember.computed('notes', function () {
      var note = this.get("notes");

      if (!Ember.isEmpty(note)) {
        return true;
      }

      return false;
    }),
    isIgnored: Ember.computed('ignoreStatus', function () {
      var ignored = this.get("ignoreStatus");

      if (ignored) {
        return "Yes";
      }

      return "No";
    })
  });

  _exports.default = _default;
});