define("admin/controllers/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    addingOrder: null,
    viewOrdersHistory: false,
    queryParams: ['startDate', 'endDate'],
    startDate: null,
    endDate: null,
    menuActiveItem: null,
    companies: Ember.computed('model.groups', 'model.potentialCustomers', function () {
      var companies = Ember.A(this.get('model.groups').toArray());
      companies.pushObjects(this.get('model.potentialCustomers').toArray());
      return companies;
    }),
    menu: Ember.A([Ember.Object.create({
      label: 'Add Order',
      icon: 'plus',
      action: 'addOrder',
      status: null
    }), Ember.Object.create({
      label: 'Draft Orders',
      icon: 'folder-open',
      action: 'openDraftOrders',
      status: 'draft'
    }), Ember.Object.create({
      label: 'Shipping Orders',
      icon: 'globe',
      action: 'openShippingOrders',
      status: 'shipping'
    }), Ember.Object.create({
      label: 'Installation Orders',
      icon: 'wrench',
      action: 'openInstallationOrders',
      status: 'installing'
    }), Ember.Object.create({
      label: 'Subscription Orders',
      icon: 'star-empty',
      action: 'openSubscriptionOrders',
      status: 'subscription'
    }), Ember.Object.create({
      label: null
    }), Ember.Object.create({
      label: 'Termination Requests',
      icon: 'remove-circle',
      action: 'terminationRequests',
      status: 'cancelled'
    })]),
    orderStatuses: [{
      value: 'draft',
      label: 'Draft'
    }, {
      value: 'shipping',
      label: 'Shipping'
    }, {
      value: 'installing',
      label: 'Installing'
    }, {
      value: 'subscription',
      label: 'Subscription'
    }, {
      value: 'cancelled',
      label: 'Cancelled'
    }],
    orders: Ember.computed('model.orders.@each.status', 'menuActiveItem', function () {
      var status = {
        openDraftOrders: 'draft',
        openShippingOrders: 'shipping',
        openInstallationOrders: 'installing',
        openSubscriptionOrders: 'subscription',
        openCancelledOrders: 'cancelled',
        terminationRequests: 'termination'
      }[this.get('menuActiveItem')]; // Only saved orders go to the orders list on the page. Those are the orders that have IDs.

      return this.get('model.orders').filter(function (order) {
        return order.get('id') && order.get('status') === status;
      });
    }),
    updateMenuActiveItem: function updateMenuActiveItem(activeAction) {
      this.get('menu').forEach(function (item) {
        item.set('active', activeAction == item.get('action'));
      });
    },
    pageTitle: Ember.computed('menuActiveItem', function () {
      var action = this.get('menuActiveItem');
      var menuItem = this.get('menu').find(function (item) {
        return action == item.get('action');
      });
      return menuItem.get('label');
    }),
    newOrder: Ember.computed('menuActiveItem', function () {
      if (this.get('menuActiveItem') === 'addOrder') {
        return this.get('_newOrder');
      } else {
        return null;
      }
    }),
    counts: Ember.computed('model.orders.@each.status', function () {
      var counts = {
        draft: 0,
        shipping: 0,
        installing: 0,
        subscription: 0,
        cancelled: 0,
        termination: 0
      };
      this.get('model.orders').forEach(function (order) {
        counts[order.get('status')]++;
      });
      return Ember.Object.create(counts);
    }),
    addOrder: function addOrder() {
      if (!this.get('_newOrder')) {
        var newOrder = this.get('store').createRecord('order');
        newOrder.set('shipping', this.get('store').createRecord('order-shipping'));
        newOrder.set('installation', this.get('store').createRecord('order-installation'));
        newOrder.set('subscription', this.get('store').createRecord('order-subscription'));
        this.set('_newOrder', newOrder);
      }
    },
    openDraftOrders: function openDraftOrders() {},
    openShippingOrders: function openShippingOrders() {},
    openInstallationOrders: function openInstallationOrders() {},
    openSubscriptionOrders: function openSubscriptionOrders() {},
    terminationRequests: function terminationRequests() {},
    deleteModal: {
      open: false,
      order: null
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('menuActiveItem')) {
        // this.set('menuActiveItem', 'openDraftOrders');
        // this.set('menuActiveItem', 'openShippingOrders');
        // this.set('menuActiveItem', 'openInstallationOrders');
        this.set('menuActiveItem', 'openSubscriptionOrders');
      }
    },
    actions: {
      changeMonth: function changeMonth(direction) {
        var startDate = moment(this.get('ordersStartDate'));
        var endDate = moment(this.get('ordersEndDate'));

        if (direction == "prev") {
          this.set('ordersStartDate', startDate.subtract(1, 'month').startOf('month'));
          this.set('ordersEndDate', endDate.subtract(1, 'month').endOf('month'));
        } else {
          this.set('ordersStartDate', startDate.add(1, 'month').startOf('month'));
          this.set('ordersEndDate', endDate.add(1, 'month').endOf('month'));
        }

        this.set('startDate', startDate.valueOf());
        this.set('endDate', endDate.valueOf());
      },
      changedStartDate: function changedStartDate(date) {
        this.set('startDate', moment(date).valueOf());
      },
      changedEndDate: function changedEndDate(date) {
        this.set('endDate', moment(date).valueOf());
      },
      openOrdersHistory: function openOrdersHistory() {
        var startDate = moment().startOf('month');
        var endDate = moment().endOf('month');
        this.set('ordersStartDate', startDate);
        this.set('ordersEndDate', endDate);
        this.set('startDate', startDate.valueOf());
        this.set('endDate', endDate.valueOf());
        this.set('viewOrdersHistory', true);

        if (this.get('_newOrder')) {
          this.get('_newOrder.subscription').deleteRecord();
          this.get('_newOrder.installation').deleteRecord();
          this.get('_newOrder.shipping').deleteRecord();
          this.get('_newOrder.products').forEach(function (product) {
            return product.deleteRecord();
          });
          this.get('_newOrder').deleteRecord();
          this.set('_newOrder', null);
        }
      },
      closeOrdersHistory: function closeOrdersHistory() {
        this.set('ordersStartDate', null);
        this.set('ordersEndDate', null);
        this.set('startDate', null);
        this.set('endDate', null);
        this.set('viewOrdersHistory', false);
      },
      menuAction: function menuAction(action) {
        this.set('menuActiveItem', action);

        if (this[action]) {
          this[action]();
        }
      },
      finishNewOrder: function finishNewOrder(order) {
        this.get('model.orders').pushObject(order);
        var newOrder = this.get('store').createRecord('order');
        newOrder.set('shipping', this.get('store').createRecord('order-shipping'));
        newOrder.set('installation', this.get('store').createRecord('order-installation'));
        newOrder.set('subscription', this.get('store').createRecord('order-subscription'));
        this.set('_newOrder', newOrder);
        var menuAction = {
          draft: 'openDraftOrders',
          shipping: 'openShippingOrders',
          installing: 'openInstallationOrders',
          subscription: 'openSubscriptionOrders',
          cancelled: 'openCancelledOrders'
        }[order.get('status')];
        this.set('menuActiveItem', menuAction);
        this[menuAction]();
      },
      updateExistingOrder: function updateExistingOrder(order) {
        var menuAction = {
          draft: 'openDraftOrders',
          shipping: 'openShippingOrders',
          installing: 'openInstallationOrders',
          subscription: 'openSubscriptionOrders',
          cancelled: 'openCancelledOrders'
        }[order.get('status')];
        var counts = {
          draft: 0,
          shipping: 0,
          installing: 0,
          subscription: 0,
          cancelled: 0
        };
        this.set('menuActiveItem', menuAction);
      },
      deleteOrder: function deleteOrder(order) {
        this.toggleProperty("deleteModal.open");
        this.set("deleteModal.order", order);
      },
      confirmDeleteOrder: function confirmDeleteOrder() {
        var _this = this;

        var orderToDelete = this.get('deleteModal.order');
        var order = this.get('model.orders').find(function (order) {
          return order.id == orderToDelete.id;
        }); // this.get('model.counts').decrementProperty(order.get('status'));

        order.destroyRecord().then(function (response) {
          Ember.Logger.log(_this.get('deleteModal.order'));

          _this.set('deleteModal.order', null);

          _this.set('deleteModal.open', false);

          _this.get('model.orders').removeObject(order); // this.notifyPropertyChange('orders');

        });
      },
      cancelDeleteOrder: function cancelDeleteOrder() {
        this.set('deleteModal.order', null);
        this.set('deleteModal.open', false);
      }
    }
  });

  _exports.default = _default;
});