define("admin/helpers/money-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.moneyFormat = moneyFormat;
  _exports.default = void 0;

  var _formatMoney = function _formatMoney(number, decPlaces, thouSeparator, decSeparator) {
    var n = number,
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSeparator = decSeparator == undefined ? "." : decSeparator,
        thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
        sign = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
  };

  function moneyFormat(params
  /*, hash*/
  ) {
    var number = params[0];
    var decPlaces = params[1];
    var thouSeparator = params[2];
    var decSeparator = params[3];
    return _formatMoney(number, decPlaces, thouSeparator, decSeparator);
  }

  var _default = Ember.Helper.helper(moneyFormat);

  _exports.default = _default;
});