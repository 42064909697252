define("admin/initializers/store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    This initializer is here to keep backwards compatibility with code depending
    on the `store` initializer (before Ember Data was an addon).
  
    Should be removed for Ember Data 3.x
  */
  var _default = {
    name: 'store',
    after: 'ember-data',
    initialize: function initialize() {}
  };
  _exports.default = _default;
});