define("admin/routes/settings", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        deviceTypes: this.get('store').findAll('device-type'),
        serviceTypes: this.get('store').query('service-type', {
          $where: {
            available: true
          }
        }),
        payerCompanies: this.get('store').findAll('payer-company')
      });
    }
  });

  _exports.default = _default;
});