define("admin/components/list-pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dj604PK6",
    "block": "{\"symbols\":[\"pageNumber\",\"&default\"],\"statements\":[[11,2,[[20,[\"paginatedItems\"]]]],[0,\"\\n\"],[6,\"ul\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"showPrevious\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[6,\"li\"],[7],[6,\"button\"],[3,\"action\",[[19,0,[]],\"previousClicked\"]],[7],[1,[18,\"previousText\"],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[6,\"li\"],[9,\"style\",\"text-decoration: line-through;\"],[7],[1,[18,\"previousText\"],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[20,[\"showPages\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"pageNumbers\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[6,\"li\"],[7],[6,\"button\"],[3,\"action\",[[19,0,[]],\"pageClicked\",[19,1,[]]]],[7],[0,\"Page \"],[1,[19,1,[]],false],[8],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[4,\"if\",[[20,[\"showNext\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[6,\"li\"],[7],[6,\"button\"],[3,\"action\",[[19,0,[]],\"nextClicked\"]],[7],[1,[18,\"nextText\"],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[6,\"li\"],[9,\"style\",\"text-decoration: line-through;\"],[7],[1,[18,\"nextText\"],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/components/list-pagination/template.hbs"
    }
  });

  _exports.default = _default;
});