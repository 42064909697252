define("admin/components/apex-chart/component", ["exports", "apexcharts"], function (_exports, _apexcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chart: null,
    options: null,
    tagName: 'div',
    classNames: ['apexchart'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var chart = new _apexcharts.default(this.get('element'), this.get('options'));
      this.set('chart', chart);
      chart.render();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var chart = this.get('chart');

      if (!Ember.isEmpty(chart)) {
        chart.updateSeries(this.get('options.series'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('chart').destroy();
      this.set('chart', null);
    }
  });

  _exports.default = _default;
});