define("admin/models/device-transfer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    deviceSNs: _emberData.default.attr(),
    failedSNs: _emberData.default.attr(),
    reason: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    devicesStock: _emberData.default.belongsTo('devices-stock', {
      allowNull: true,
      async: true
    }),
    installationTask: _emberData.default.belongsTo('installation-task', {
      allowNull: true,
      async: true
    })
  });

  _exports.default = _default;
});