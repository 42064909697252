define("admin/models/installation-task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    orderInstallation: _emberData.default.belongsTo('order-installation'),
    company: _emberData.default.belongsTo('group'),
    electricians: _emberData.default.attr(),
    type: _emberData.default.attr('string'),
    scheduledAt: _emberData.default.attr('date'),
    devices: _emberData.default.attr(),
    installationData: _emberData.default.attr(),
    status: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    contactPerson: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    startedAt: _emberData.default.attr('date'),
    finishedAt: _emberData.default.attr('date'),
    productItems: _emberData.default.hasMany('order-product-item', {
      allowNull: true,
      inverse: 'installationTask'
    })
  });

  _exports.default = _default;
});