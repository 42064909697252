define("admin/components/group-subscription/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    invoicingIntervals: [{
      value: 1,
      label: "Monthly"
    }, {
      value: 6,
      label: "Every 6 months"
    }, {
      value: 12,
      label: "Yearly"
    }],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('loading', false);
    },
    actions: {
      selectInvoicingInterval: function selectInvoicingInterval(_ref) {
        var value = _ref.value;
        this.get('groupSubscription').set('recurringInterval', value);
      },
      save: function save() {
        var _this = this;

        this.set('loading', true);
        var loadingTimeout = null;

        var finishLoading = function () {
          _this.set('loading', false);

          clearTimeout(loadingTimeout);
        }.bind(this);

        this.get('groupSubscription').save().then(function () {
          loadingTimeout = setTimeout(finishLoading, 500);
        }).catch(function (error) {
          loadingTimeout = setTimeout(finishLoading, 500);
        });
      },
      cancel: function cancel() {
        this.get('groupSubscription').rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});