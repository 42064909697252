define("admin/models/company", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    companyID: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    details: _emberDataModelFragments.default.fragment("group-details", {
      defaultValue: {
        CUI: null,
        J: null,
        city: null,
        province: null,
        address: null,
        contractDate: null,
        contractDuration: null,
        pricePrDevice: null,
        subscriptionFee: null,
        adminPhone: null,
        contractETag: null
      }
    }),
    // offers:     DS.hasMany("offer", { async: true, inverse: "company" }),
    notes: _emberData.default.hasMany('company-note', {
      async: true,
      inverse: 'company'
    }),
    // salesAgentID: DS.attr('string'),
    salesAgent: _emberData.default.belongsTo('user'),
    invoicingInterval: _emberData.default.attr('number'),
    // Number of months. Preferably either one or twelve
    settings: _emberDataModelFragments.default.fragment("group-settings"),
    isProspect: _emberData.default.attr('boolean'),
    subscriptions: _emberData.default.hasMany("group-subscription", {
      async: true,
      inverse: "company"
    }),
    subscription: Ember.computed('subscriptions', function () {
      return this.get('subscriptions.firstObject');
    }),
    orders: _emberData.default.hasMany('order'),
    modules: _emberData.default.attr(),
    payerCompany: _emberData.default.belongsTo('payer-company', {
      allowNull: true
    })
  });

  _exports.default = _default;
});