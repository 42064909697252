define("admin/models/vehicle-settings-alert-triggers", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    fuelLevelDropStationary: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    fuelConsumptionExcessive: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    lowBatteryThreshold: _emberData.default.attr('number', {
      defaultValue: 9.3
    }),
    dailyBatteryDischargeThreshold: _emberData.default.attr('number', {
      defaultValue: 0.5
    })
  });

  _exports.default = _default;
});