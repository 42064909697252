define("admin/models/order-installation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    order: _emberData.default.belongsTo('order', {
      inverse: 'installation'
    }),
    // installation: DS.belongsTo('order-installation'),
    location: _emberData.default.attr('string'),
    status: _emberData.default.attr('string', {
      allowNull: true
    }),
    completedAt: _emberData.default.attr('date'),
    installationTasks: _emberData.default.hasMany('installation-task', {
      inverse: 'orderInstallation'
    })
  });

  _exports.default = _default;
});