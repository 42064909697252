define("admin/components/unassigned-vehicle/component", ["exports", "admin/models/vehicle"], function (_exports, _vehicle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'tr',
    classNames: ["list-group-items"],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('SN', null);
    },
    actions: {
      addDevice: function addDevice() {
        var _this = this;

        var sn = this.get("SN");
        this.get("store").findRecord("device", sn).then(function (device) {
          var vehicle = _this.get("vehicle");

          device.set("vehicle", vehicle);
          device.save();
        }).catch(function (e) {
          Ember.Logger.log("ERROR assigning vehicle to device", e);
        });
      },
      vehicleDetails: function vehicleDetails() {
        this.get("vehicleDetails")(this.get("vehicle"));
      }
    }
  });

  _exports.default = _default;
});