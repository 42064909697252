define("admin/models/group", ["exports", "ember-data", "ember-data-model-fragments", "admin/models/company"], function (_exports, _emberData, _emberDataModelFragments, _company) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _company.default.extend({
    // companyID:  DS.attr( "string" ),
    company: _emberData.default.belongsTo("group", {
      inverse: null,
      async: true
    }),
    // name:       DS.attr( 'string' ),
    // details:    MF.fragment( "group-details", { defaultValue: { CUI: null, J: null, address: null, contractDate: null, pricePrDevice: null, subscriptionFee: null, adminPhone: null } } ),
    devices: _emberData.default.hasMany('device'),
    enabled: _emberData.default.attr("boolean"),
    users: _emberData.default.hasMany("user", {
      inverse: "group"
    }),
    vehicles: _emberData.default.hasMany("vehicle", {
      inverse: "group"
    }),
    settings: _emberDataModelFragments.default.fragment("group-settings"),
    offers: _emberData.default.hasMany("offer", {
      async: true,
      inverse: "company"
    }),
    // offers:     DS.hasMany("offer", { polymorphic: true }),
    modules: _emberData.default.attr(),
    debts: _emberData.default.attr('', {
      allowNull: true,
      defaultValue: null
    }),
    admin: Ember.computed("users", function () {
      var admin = null;
      this.get("users").forEach(function (user) {
        if (user.get("role") === "admin") {
          admin = user;
        }
      });
      return admin;
    }),
    subscriptions: _emberData.default.hasMany("group-subscription", {
      async: true,
      inverse: "company"
    }),
    // salesAgent: DS.belongsTo('user'),
    // salesAgentID: DS.attr('string'),
    companyVehicles: _emberData.default.hasMany("vehicle", {
      inverse: "company"
    }),
    type: 'group',
    usersAccess: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});