define("admin/templates/components/bs-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gaU+HZwp",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",null,[[\"type\",\"name\"],[\"checkbox\",[20,[\"name\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/components/bs-switch.hbs"
    }
  });

  _exports.default = _default;
});