define("admin/components/order-product/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var validators = {
    required: function required(fieldOptions) {
      Ember.Logger.log('required', fieldOptions.key);

      if (Ember.isEmpty(this.get(fieldOptions.key))) {
        Ember.Logger.error('failed');
        return {
          field: fieldOptions.key,
          error: "Required field \"".concat(fieldOptions.key, "\" is empty.")
        };
      } else {
        Ember.Logger.debug('passed');
        return true;
      }
    },
    range: function range(fieldOptions) {
      var value = parseFloat(this.get(fieldOptions.key));
      Ember.Logger.log('range', fieldOptions.key, value);
      Ember.Logger.log('min', fieldOptions.min, 'max', fieldOptions.max);

      if (isNaN(value) || value < fieldOptions.min || value > fieldOptions.max) {
        Ember.Logger.debug('failed');
        return {
          field: fieldOptions.key,
          error: "The value for \"".concat(fieldOptions.key, "\" should be in the range: ").concat(fieldOptions.min, " : ").concat(fieldOptions.max)
        };
      }

      Ember.Logger.debug('passed');
      return true;
    },
    conditionedRange: function conditionedRange(fieldOptions) {
      Ember.Logger.log('conditionedRange', fieldOptions.key, this.get(fieldOptions.key));

      if (this.get(fieldOptions.dependsOn)) {
        Ember.Logger.log('Validating range');
        return this.get('validators')['range'](fieldOptions);
      } else {
        Ember.Logger.log('Nothing to validate here');
        return true;
      }
    },
    conditionedRequired: function conditionedRequired(fieldOptions) {
      Ember.Logger.log('conditionedRequired', fieldOptions.key, this.get(fieldOptions.key));

      if (this.get(fieldOptions.dependsOn)) {
        Ember.Logger.log('Validating required field');
        return this.get('validators')['required'](fieldOptions);
      } else {
        Ember.Logger.log('Nothing to validate here');
        return true;
      }
    }
  };

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    deviceTypeServices: Ember.computed('product.deviceType', function () {
      var deviceType = this.get('product.deviceType');

      if (deviceType.get('available')) {
        var services = this.get('product.deviceType.services');

        if (services) {
          return this.get('serviceTypes').filter(function (serviceType) {
            return services.includes(serviceType.get('id'));
          });
        } else {
          return [];
        }
      } else {
        return this.get('serviceTypes');
      }
    }),
    custodyOptions: [{
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
    }],
    selectedCustodyOption: Ember.computed('product.custody', function () {
      var _this = this;

      return this.get('custodyOptions').find(function (_ref) {
        var value = _ref.value;
        return _this.get('product.custody') == value;
      });
    }),
    disabledCustody: Ember.computed('product.custody', function () {
      return !this.get('product.custody');
    }),
    oneTimePayment: Ember.computed('product.quantity', 'product.unitPrice', 'product.unitSetupFee', function () {
      return this.get('product.quantity') * (parseFloat(this.get('product.unitPrice')) + parseFloat(this.get('product.unitSetupFee')));
    }),
    validate: function validate() {
      var _this2 = this;

      var errors = [];
      var fields = [{
        key: 'product.deviceType.id',
        types: ['required']
      }, {
        key: 'product.quantity',
        types: ['required']
      }, {
        key: 'product.unitPrice',
        types: ['required', 'range'],
        min: this.get('product.deviceType.price.min'),
        max: this.get('product.deviceType.price.max')
      }, {
        key: 'product.unitSetupFee',
        types: ['required', 'range'],
        min: this.get('product.deviceType.setup.min'),
        max: this.get('product.deviceType.setup.max')
      }, {
        key: 'product.serviceType.id',
        types: ['conditionedRequired'],
        dependsOn: 'product.deviceType.services.length'
      }, {
        key: 'product.serviceFee',
        types: ['conditionedRange'],
        dependsOn: 'product.serviceType.id',
        min: this.get('subscriptionFeeRange.min'),
        max: this.get('subscriptionFeeRange.max')
      }, {
        key: 'product.custodyFee',
        types: ['conditionedRange'],
        dependsOn: 'product.custody',
        min: this.get('custodyFeeRange.min'),
        max: this.get('custodyFeeRange.max')
      }];
      fields.forEach(function (fieldOptions) {
        var loop = true;
        var index = 0;
        Ember.Logger.log('\n');

        while (loop) {
          var result = _this2.get('validators')[fieldOptions.types[index]](fieldOptions);

          var errorField = "error_".concat(fieldOptions.key.replace(/\./g, '_'));

          if (result !== true) {
            _this2.set(errorField, true);

            errors.push(result.error);
            loop = false;
          } else {
            _this2.set(errorField, false);

            index++;
          }

          if (fieldOptions.types.length == index) loop = false;
        }
      }); // TODO: Add the validations back when needed!
      // if (errors.length) {
      //     this.set('product.isValid', false);
      //     return { errors: errors };
      // } else {
      //     this.set('product.isValid', true);
      //     return true;
      // }

      return true;
    },
    serviceFee: Ember.computed('product.quantity', 'product.serviceFee', 'product.custodyFee', 'product.custody', function () {
      var unitServiceFee = parseFloat(this.get('product.serviceFee'));

      if (this.get('product.custody')) {
        unitServiceFee += parseFloat(this.get('product.custodyFee'));
      }

      return parseInt(this.get('product.quantity')) * unitServiceFee;
    }),
    invoicingIntervalEn: Ember.computed('invoicingInterval', function () {
      var intervals = {
        1: "Monthly",
        6: "Biannual",
        12: "Yearly"
      };

      if (this.get('invoicingInterval')) {
        return intervals[this.get('invoicingInterval')];
      } else {
        return intervals[1];
      }
    }),
    custodyFeeRange: Ember.computed('product.deviceType', 'invoicingInterval', function () {
      var custody = {
        1: this.get('product.deviceType.monthlyCustody'),
        6: this.get('product.deviceType.biannualCustody'),
        12: this.get('product.deviceType.yearlyCustody')
      };

      if (this.get('invoicingInterval')) {
        return custody[this.get('invoicingInterval')];
      } else {
        return custody[1];
      }
    }),
    subscriptionFeeRange: Ember.computed('product.serviceType', 'invoicingInterval', function () {
      var subscriptions = {
        1: this.get('product.serviceType.monthlyFee'),
        6: this.get('product.serviceType.biannualFee'),
        12: this.get('product.serviceType.yearlyFee')
      };

      if (this.get('invoicingInterval')) {
        return subscriptions[this.get('invoicingInterval')];
      } else {
        return subscriptions[1];
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('product.id')) {
        this.set('product.custody', false);
        this.set('editing', true);
      } else {
        this.set('editing', false);
      }

      Ember.Logger.log('Service Type: ', this.get('product.serviceType'));
      var _validators = {
        required: validators.required.bind(this),
        range: validators.range.bind(this),
        conditionedRange: validators.conditionedRange.bind(this),
        conditionedRequired: validators.conditionedRequired.bind(this)
      };
      this.set('validators', _validators);

      if (this.get('product.id')) {
        this.validate();
      }
    },
    actions: {
      selectDeviceType: function selectDeviceType(deviceType) {
        this.get('product').set('deviceType', deviceType);
      },
      selectCustodyOption: function selectCustodyOption(_ref2) {
        var value = _ref2.value;
        this.set('product.custody', value);
      },
      selectServiceType: function selectServiceType(serviceType) {
        this.set('product.serviceType', serviceType);
      },
      finishProduct: function finishProduct() {
        var valid = this.validate();

        if (!valid.errors) {
          this.set('product.isValid', true);
          this.set('editing', false);
        } else {
          this.set('product.isValid', false);
        }
      },
      removeProduct: function removeProduct() {
        // We need multiple promises here because we may need to remove the product items too before removing the product
        var promises = [];

        if (this.get('product.id')) {
          promises.push(this.get('product').destroyRecord());
        } else {
          this.get('product').deleteRecord();
        }

        if (promises.length) {
          return Ember.RSVP.all(promises).then(function () {}).catch(function (error) {
            Ember.Logger.error(error);
          });
        } else {}
      },
      cancel: function cancel() {
        this.set('editing', false);
      }
    }
  });

  _exports.default = _default;
});