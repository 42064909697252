define("admin/routes/orders", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      },
      searchText: {
        refreshModel: false
      },
      requestTextSearch: {
        refreshModel: true
      }
    },
    _ordersDateQuery: function _ordersDateQuery() {
      if (params.startDate) {
        if (!ordersQuery.$where) {
          ordersQuery.$where = {
            '>=': {
              createdAt: moment(parseInt(params.startDate)).format('YYYY-MM-DD')
            }
          };
        } else {
          ordersQuery.$where['>='] = {
            createdAt: moment(parseInt(params.startDate)).format('YYYY-MM-DD')
          };
        }
      }
    },
    _getOrdersQuery: function _getOrdersQuery(params) {
      var ordersQuery = {
        $sort: {
          createdAt: 'desc'
        }
      };
      var possibleParams = [{
        name: 'startDate',
        key: '>='
      }, {
        name: 'endDate',
        key: '<='
      }];
      possibleParams.forEach(function (possibleParam) {
        if (params[possibleParam.name]) {
          if (!ordersQuery.$where) {
            ordersQuery.$where = {};
          }

          ordersQuery.$where[possibleParam.key] = {
            createdAt: moment(parseInt(params[possibleParam.name])).format('YYYY-MM-DD')
          };
        }
      });
      return ordersQuery;
    },
    // Using a cache to retreive and store objects that rarely ever change.
    _getCachedModels: function _getCachedModels(hash) {
      var _this = this;

      var modelOptions = [{
        model: 'device-type',
        hashKey: 'deviceTypes'
      }, {
        model: 'service-type',
        hashKey: 'serviceTypes'
      }, {
        model: 'group',
        hashKey: 'groups'
      }, {
        model: 'potential-customer',
        hashKey: 'potentialCustomers',
        query: {
          $where: {
            archived: false,
            'is null': 'company_id'
          }
        }
      }, {
        model: 'user',
        hashKey: 'user',
        query: {
          $where: {
            type: 'electrician'
          }
        }
      }];
      modelOptions.forEach(function (modelOption) {
        hash[modelOption.hashKey] = _this.get(modelOption.hashKey);

        if (!hash[modelOption.hashKey]) {
          var promise = null;

          if (modelOption.query) {
            promise = _this.get('store').query(modelOption.model, modelOption.query);
          } else {
            promise = _this.get('store').findAll(modelOption.model);
          }

          hash[modelOption.hashKey] = promise.then(function (items) {
            _this.set(modelOption.hashKey, items);

            return Ember.RSVP.resolve(items);
          });
        }
      });
      return hash;
    },
    model: function model(params) {
      var hash = this._getCachedModels({});

      var ordersQuery = this._getOrdersQuery(params);

      hash.orders = this.get('store').query('order', ordersQuery).then(function (orders) {
        return orders.toArray();
      });
      return Ember.RSVP.hash(hash);
    }
  });

  _exports.default = _default;
});