define("admin/models/invoice-payment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    invoice: _emberData.default.belongsTo('invoice', {
      async: true,
      inverse: 'invoicePayments'
    }),
    payment: _emberData.default.belongsTo('payment', {
      async: true,
      inverse: 'paymentInvoices'
    }),
    amount: _emberData.default.attr('number')
  });

  _exports.default = _default;
});