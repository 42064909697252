define("admin/models/order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    offer: _emberData.default.belongsTo('offer', {
      allowNull: true
    }),
    company: _emberData.default.belongsTo('company', {
      polymorphic: true,
      async: false
    }),
    products: _emberData.default.hasMany('order-product', {
      inverse: 'order'
    }),
    shipping: _emberData.default.belongsTo('order-shipping', {
      inverse: 'order'
    }),
    installation: _emberData.default.belongsTo('order-installation', {
      inverse: 'order'
    }),
    subscription: _emberData.default.belongsTo('order-subscription', {
      inverse: 'order'
    }),
    platform: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date')
  });

  _exports.default = _default;
});