define("admin/models/invoice-details", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    company: _emberDataModelFragments.default.fragment('invoice-details-customer'),
    items: _emberDataModelFragments.default.fragmentArray('invoice-details-item')
  });

  _exports.default = _default;
});