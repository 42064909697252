define("admin/components/staff-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["list-group-item from-horizontal"],
    tagName: "a",
    user: {},
    roles: [{
      label: "Sales representative",
      value: "sales-agent"
    }, {
      label: "Electrician",
      value: "electrician"
    }, {
      label: "Operational Manager",
      value: "operational-manager"
    }, {
      label: "Support Engineer",
      value: "support-engineer"
    }],
    // We have to disconnect the component from the user role because otherwise it fires the computed properties dependant on role before saving the object.
    selectedRole: null,
    editMode: false,
    cancelEnabled: true,
    saveButtonText: "Update",
    init: function init() {
      this._super.apply(this, arguments);

      this.set("selectedRole", this.get("user.role"));
    },
    actions: {
      toggleEditMode: function toggleEditMode() {
        var editMode = this.get("editMode");
        this.get("user").rollbackAttributes();
        this.set("editMode", !editMode);
      },
      saveUser: function saveUser() {
        var _this = this;

        var callback = function callback() {
          _this.set("editMode", false);
        };

        this.set("user.role", this.get("selectedRole")); // Can be inviteElectrician on updateElectrician or whatever the assigned controller action given in the route template

        this.sendAction("saveUser", this.get("user"), callback);
      },
      deleteUser: function deleteUser() {
        this.sendAction("deleteConfirmation", this.get("user"));
      }
    }
  });

  _exports.default = _default;
});