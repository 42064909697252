define("admin/components/unassigned-vehicle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VrEiMzH3",
    "block": "{\"symbols\":[],\"statements\":[[6,\"td\"],[7],[0,\"\\n    \"],[6,\"a\"],[3,\"action\",[[19,0,[]],\"vehicleDetails\",[20,[\"vehicle\"]]]],[7],[1,[20,[\"vehicle\",\"make\"]],false],[0,\" \"],[1,[20,[\"vehicle\",\"licensePlate\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n    SN:\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"value\",\"class\"],[\"text\",[20,[\"SN\"]],\"form-control\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n\"],[4,\"bs-button\",null,[[\"onClick\"],[[25,\"action\",[[19,0,[]],\"addDevice\"],null]]],{\"statements\":[[0,\"        Add device\\n\"]],\"parameters\":[]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/components/unassigned-vehicle/template.hbs"
    }
  });

  _exports.default = _default;
});