define("admin/models/order-shipping", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    order: _emberData.default.belongsTo('order', {
      inverse: 'shipping'
    }),
    address: _emberData.default.attr('string'),
    awb: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    completedAt: _emberData.default.attr('date')
  });

  _exports.default = _default;
});