define("admin/components/offer-edit/component", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service('api'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    localSettings: Ember.inject.service('local-settings'),
    classNames: ["list-group-item", "offer-form"],
    editing: false,
    tagName: "div",
    isNewDetail: false,
    currentUser: Ember.inject.service('current-user'),
    isSuperAdmin: Ember.computed("currentUser.isSuperAdmin", function () {
      return this.get('currentUser.isSuperAdmin');
    }),
    isSuperUser: Ember.computed("currentUser.isSuperAdmin", 'currentUser.isFinancialOfficer', function () {
      return this.get('currentUser.isSuperAdmin') || this.get('currentUser.isFinancialOfficer');
    }),
    disableEdit: Ember.computed('offer.invoiceID', 'offer.approved', 'isSuperUser', function () {
      return this.get('offer.invoiceID') && this.get('offer.approved') && !this.get('isSuperUser');
    }),
    documentTypes: [{
      value: 'none',
      label: 'Default'
    }, {
      value: 'contract',
      label: 'Contract'
    }, {
      value: 'addendum',
      label: 'Addendum'
    }, {
      value: 'demo-custody-request',
      label: 'Demo Custody Request'
    }],
    allowGenerateProforma: Ember.computed('offer.documentDate', function () {
      var documentAge = moment().diff(moment(this.get('offer.documentDate')), 'months', true);
      return documentAge < 3 && !this.get('isPendingCreateInvoice'); // allowGenerateProforma: Ember.computed('offer.documentDate', 'isSuperUser', function () {
      //     let documentAge = moment().diff(moment(this.get('offer.documentDate')), 'months', true);
      //     return this.get('isSuperUser') || documentAge < 3;
    }),
    documentTypeName: Ember.computed('offer.documentType', function () {
      var documentType = this.get('offer.documentType');
      var selected = this.get('documentTypes').find(function (docType) {
        return docType.value === documentType;
      });

      if (selected) {
        return selected.label;
      }
    }),
    isDemo: Ember.computed('offer.documentType', function () {
      return this.get('offer.documentType') === 'demo-custody-request';
    }),
    demoStartDateError: Ember.computed('isDemo', 'offer.demo.startDate', function () {
      if (this.get('isDemo')) {
        if (!this.get('offer.demo.startDate')) return true;
      }

      return false;
    }),
    demoEndDateError: Ember.computed('isDemo', 'offer.demo.endDate', function () {
      if (this.get('isDemo')) {
        if (!this.get('offer.demo.endDate')) return true;
      }

      return false;
    }),
    allowMakeOrder: Ember.computed('isSuperUser', 'offer.company.type', 'offer.order', function () {
      var orderID = this.get('offer.order.id');

      if (this.get('isSuperUser') && !orderID && this.get('offer.company.type') == 'group') {
        return true;
      } else {
        return false;
      }
    }),

    /**
     * The Logitech G700s mouse is known to produce WiFi interference when used in wireless mode.
     * Some times it registers very fast double clicks
     */
    _hackFixForDorasBrokenMouse: function _hackFixForDorasBrokenMouse() {
      var onClick = function onClick(event) {
        event.stopPropagation();
        event.preventDefault();
      };

      document.addEventListener("click", onClick, true);
      setTimeout(function () {
        document.removeEventListener("click", onClick, true);
      }, 750);
    },
    // offerDocumentForUpload: Ember.computed('selectedOfferDocument', function() {
    //     Ember.Logger.log('Value changed');
    //     return this.get('selectedOfferDocument')
    // }),
    init: function init() {
      this._super.apply(this, arguments);

      var documentType = this.get('offer.documentType');

      if (this.get("offer.isNew")) {
        this.newDetail();
        this.newOldDetail();
        this.set('newDetails', []);
        this.set('newOldDetails', []);
        this.set('editing', true);
        this.set('offer.documentDate', new Date());

        if (documentType === 'contract') {
          if (this.get('offer.company.details.contractDate')) {
            this.set('offer.documentDate', this.get('offer.company.details.contractDate'));
          } else if (this.get('offer.company.subscription.firstIssuingDate')) {
            this.set('offer.documentDate', this.get('offer.company.subscription.firstIssuingDate'));
          } else {
            this.set('offer.documentDate', new Date());
          }
        }
      }

      if (documentType && documentType == 'demo-custody-request') {
        this.set('offer.approved', true);
        var startDate = moment();
        this.set('offer.demo', {
          startDate: startDate.toDate(),
          endDate: moment(startDate).add(1, "month").toDate()
        });
      }

      this.set('isSaving', false);
    },
    newDetail: function newDetail() {
      this.set('newDetailObject', this.get('store').createFragment('offer-details', {
        deviceTypeID: null,
        serviceTypeID: null,
        monthlyFee: null,
        price: null,
        quantity: null,
        setup: null,
        custodyStatus: 'custody'
      }));
    },
    newOldDetail: function newOldDetail() {
      this.set('newOldDetailObject', this.get('store').createFragment('offer-details-old', {
        deviceType: null,
        serviceType: null,
        monthlyFee: null,
        price: null,
        quantity: null,
        setup: null,
        custodyStatus: 'custody'
      }));
    },
    save: function save() {
      var _this2 = this;

      this.set('isSaving', true);
      var offer = this.get("offer");
      var timezoneOffset = new Date().getTimezoneOffset();
      var documentDate = moment(offer.get('documentDate')).startOf('day');
      documentDate.add(-timezoneOffset, 'minutes'); // Add the negative timezoneOffset at the beginning of the selected contract date to make sure that the contract date that appears in the contract matches this setting.

      offer.set('documentDate', documentDate.toDate());
      var newDetails = this.get('newDetails');
      var backup = [];

      if (newDetails) {
        newDetails.forEach(function (item) {
          var jsItem = JSON.parse(JSON.stringify(item));
          backup.push(Ember.Object.create(jsItem));
          offer.get('details').pushObject(jsItem);
        });
      }

      var newOldDetails = this.get('newOldDetails');
      var backupOld = [];

      if (newOldDetails) {
        newOldDetails.forEach(function (item) {
          var jsItem = JSON.parse(JSON.stringify(item));
          backupOld.push(Ember.Object.create(jsItem));
          offer.get('oldDetails').pushObject(jsItem);
        });
      }

      if (this.get('isDemo')) {
        if (this.get('demoStartDateError') || this.get('demoEndDateError')) {
          Ember.Logger.log('demoStartDateError', this.get('demoStartDateError'));
          Ember.Logger.log('demoEndDateError', this.get('demoEndDateError'));
          return false;
        }
      }

      this.set('newDetails', []);
      this.set('newOldDetails', []);
      this.set('documentDateError', false);
      return offer.save().then(function (savedOffer) {
        Ember.Logger.log('documentDate', offer.get('documentDate')); // this.set('offer', savedOffer);

        _this2.get('toggleAddOfferButton')();

        _this2.set('editing', false);

        backup = [];
        backupOld = [];

        _this2.set('isSaving', false);
      }).catch(function () {
        _this2.set('newDetails', backup);

        _this2.set('newOldDetails', backupOld);

        offer.rollbackAttributes();

        _this2.set('isSaving', false);
      });
    },
    actions: {
      save: function save() {
        this.save();
      },
      download: function download() {
        this.sendAction('downloadOffer', this.get('offer.id'));
      },
      addDetailToOffer: function addDetailToOffer(detail) {
        this.get("newDetails").pushObject(detail);
        this.newDetail();
      },
      addOldDetailToOffer: function addOldDetailToOffer(oldDetail) {
        this.get('newOldDetails').pushObject(oldDetail);
        this.newOldDetail();
      },
      cancelDetail: function cancelDetail() {
        Ember.Logger.log('Cancelling details...');
        this.newDetail();
      },
      cancelOldDetail: function cancelOldDetail() {
        Ember.Logger.log('Cancelling old details...');
        this.newOldDetail();
      },
      removeDetail: function removeDetail(detail) {
        var offer = this.get("offer");
        offer.get("details").popObject(detail);
      },
      removeOldDetail: function removeOldDetail(oldDetail) {
        var offer = this.get("offer");
        offer.get("oldDetails").popObject(oldDetail);
      },
      removeNewDetail: function removeNewDetail(detail) {
        this.get("newDetails").popObject(detail);
      },
      removeNewOldDetail: function removeNewOldDetail(oldDetail) {
        this.get("newOldDetails").popObject(oldDetail);
      },
      editOffer: function editOffer() {
        this.newDetail();
        this.newOldDetail();
        this.set('newDetails', []);
        this.set('newOldDetails', []);

        if (!this.get('offer.documentDate')) {
          this.set('offer.documentDate', new Date());
        }

        this.set('editing', true);
      },
      cancelOffer: function cancelOffer() {
        this.set('editing', false);
        this.set('newDetails', []);
        this.set('newOldDetails', []);

        if (this.get('offer.isNew')) {
          Ember.Logger.log('Trying destroyRecord on offer because it has no ID');
          this.sendAction("deleteOffer", this.get('offer'));
        } else {
          this.get('offer').rollbackAttributes();
        }

        this.get('toggleAddOfferButton')();
      },
      deleteOffer: function deleteOffer() {
        var offer = this.get("offer");
        this.sendAction("deleteOffer", offer);
      },
      createProforma: function createProforma() {
        var _this3 = this;

        this.set('isPendingCreateInvoice', true);

        this._hackFixForDorasBrokenMouse();

        this.get('api').request("invoices/createProforma/".concat(this.get('offer.id'))).then(function (_ref) {
          var invoice = _ref.invoice;

          var newInvoice = _this3.get('store').createRecord('invoice', invoice);

          _this3.set('offer.invoiceID', invoice.id);

          _this3.set('offer.invoice', newInvoice);

          _this3.set('isPendingCreateInvoice', false);

          _this3.set('pollEtagUpdate', setInterval(function () {
            _this3.get('api').request("invoices/".concat(_this3.get('offer.invoiceID'))).then(function (_ref2) {
              var invoice = _ref2.invoice;

              if (invoice.etag) {
                _this3.set('offer.invoice.number', invoice.number);

                _this3.set('offer.invoice.etag', invoice.etag);

                clearInterval(_this3.get('pollEtagUpdate'));

                _this3.set('pollEtagUpdate', null);
              } else {
                Ember.Logger.debug('Got no etag yet');
              }
            });
          }, 1000));
        }).catch(function (error) {
          _this3.set('isPendingCreateInvoice', false);

          Ember.Logger.error('Error while attempting to generate proforma');
          var errorType = error.payload && error.payload.errors ? error.payload.errors[0] : null;

          switch (errorType) {
            case 'group_subscription_not_found':
              _this3.set('error', 'This company does not have a recurring invoice date set up yet. Please contact the financial officer...');

              break;

            case 'zero_value':
              _this3.set('error', 'The invoice amounts to zero. We do not generate invoices with no value.');

              break;
          }

          Ember.Logger.log(error);
        });
      },
      downloadProforma: function downloadProforma() {
        var token = this.get('session.session.authenticated.idToken');
        var req = new XMLHttpRequest();
        req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/invoices/download/").concat(this.get('offer.invoiceID')), true);
        req.responseType = "blob";
        req.setRequestHeader('Accept', 'application/pdf, text/javascript, */*; q=0.01');
        req.setRequestHeader('Accept-Language', 'en-US,en;q=0.9,da;q=0.8,ro;q=0.7,ru;q=0.6');
        req.setRequestHeader('Authorization', "Bearer ".concat(token));

        req.onload = function (event) {
          var blob = req.response;
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "Proforma-Tracknamic.pdf";
          link.click();
        };

        req.send();
      },
      downloadDocument: function downloadDocument() {
        if (this.get('offer.documentDate')) {
          var token = this.get('session.session.authenticated.idToken');
          var fileName = "Tracknamic-".concat(this.get('offer.documentType'), "-").concat(this.get('offer.company.name').replace(new RegExp(' ', 'g'), '-'), ".pdf");
          var req = new XMLHttpRequest();

          if (this.get('isDemo')) {
            req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/offers/demoCustodyRequest/").concat(this.get('offer.id')), true);
          } else {
            req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/offers/contract/").concat(this.get('offer.id')), true);
          }

          req.responseType = "blob";
          req.setRequestHeader('Accept', 'application/pdf, text/javascript, */*; q=0.01');
          req.setRequestHeader('Accept-Language', 'en-US,en;q=0.9,da;q=0.8,ro;q=0.7,ru;q=0.6');
          req.setRequestHeader('Authorization', "Bearer ".concat(token));

          req.onload = function (event) {
            var blob = req.response;
            Ember.Logger.log('File size: ', blob.size);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
          };

          req.send();
        } else {
          this.set('editing', true);
          this.set('documentDateError', true);
        }
      },
      changedApprovedStatus: function changedApprovedStatus() {
        this.toggleProperty('offer.approved');
        this.save();
      },
      selectDocumentType: function selectDocumentType(documentType) {
        if (documentType === 'none') {
          documentType = null;
        } else {
          if (documentType == 'demo-custody-request' && !this.get('offer.demo')) {
            this.set('offer.demo', {
              startDate: null,
              endDate: null
            });
          }
        }

        this.set('offer.documentType', documentType);
      },
      deleteProforma: function deleteProforma() {
        var _this4 = this;

        this.get('offer').get('invoice').then(function (invoice) {
          Ember.Logger.log('invoice: ', invoice);
          invoice.destroyRecord().then(function (response) {
            _this4.toggleProperty('confirmDeleteProforma');
          });
        });
      },
      selectedOfferDocument: function selectedOfferDocument(_ref3) {
        var target = _ref3.target;

        if (target.files && target.files.length) {
          this.set("offerDocument", target.files[0]);
        } else {
          this.set('offerDocument', null);
        }
      },
      cancelOfferDocument: function cancelOfferDocument() {
        this.set('offerDocument', null);
      },
      uploadSignedOfferDocument: function uploadSignedOfferDocument() {
        var _this = this;

        var offer = this.get('offer');
        var url = "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/offers/").concat(offer.get('id'), "/uploadSignedDocument");
        var idToken = this.get('session.session.authenticated.idToken');
        var fd = new FormData();
        fd.append(offer.get('documentType'), this.get('offerDocument'));
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Authorization', "Bearer ".concat(idToken));
        var platform = this.get('localSettings').get('settings.selectedPlatform');
        xhr.setRequestHeader('trk-platform', platform.value);

        xhr.onreadystatechange = function (oEvent) {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              Ember.Logger.log("Upload Response: ", xhr.responseText);
            } else {
              Ember.Logger.log("Upload Error:", xhr.statusText);

              _this.set('uploadProgress', null);
            }
          }
        };

        xhr.upload.onprogress = function (pe) {
          if (pe.lengthComputable) {
            var percentComplete = parseInt(pe.loaded / pe.total * 100);

            _this.set("uploadProgress", percentComplete === 100 ? null : percentComplete);
          }
        };

        xhr.onloadend = function (pe) {
          try {
            var response = JSON.parse(pe.target.responseText);

            _this.set("completedRequest", true);

            _this.set('uploadProgress', null);

            _this.set("response", response);

            _this.set('offer.documentFileName', response.documentFileName);
          } catch (e) {
            _this.set("completedRequest", true);
          }
        };

        xhr.send(fd);
      },
      downloadSignedOfferDocument: function downloadSignedOfferDocument() {
        var token = this.get('session.session.authenticated.idToken');
        var extension = /[^.]+$/.exec(this.get('offer.documentFileName'))[0];
        Ember.Logger.log('Extension', extension);
        var fileName = "Tracknamic-".concat(this.get('offer.documentType'), "-").concat(this.get('offer.company.name').replace(new RegExp(' ', 'g'), '-'), ".").concat(extension);
        var req = new XMLHttpRequest();
        req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/offers/").concat(this.get('offer.id'), "/downloadSignedDocument"), true);
        req.responseType = "blob";
        req.setRequestHeader('Accept', '*/*; q=0.01');
        req.setRequestHeader('Accept-Language', 'en-US,en;q=0.9,da;q=0.8,ro;q=0.7,ru;q=0.6');
        req.setRequestHeader('Authorization', "Bearer ".concat(token));

        req.onload = function (event) {
          var blob = req.response;
          Ember.Logger.log('File: ', blob);
          Ember.Logger.log('File size: ', blob.size);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        };

        req.send();
      },
      deleteSignedOfferDocument: function deleteSignedOfferDocument() {
        var offer = this.get('offer');
        offer.set('documentFileName', null);
        offer.save().then(function (updated) {
          Ember.Logger.log('Updated', updated);
        });
      },
      endDemo: function endDemo() {
        this.sendAction('endDemo', this.get('offer'));
      },
      makeOrder: function makeOrder() {
        var _this5 = this;

        this.get('api').request("offers/".concat(this.get('offer.id'), "/makeOrder"), {
          method: 'post'
        }).then(function (_ref4) {
          var orderID = _ref4.orderID;

          _this5.get('store').findRecord('order', orderID).then(function (order) {
            Ember.Logger.debug('Found order: ', order);
          }).catch(function (error) {
            Ember.Logger.error(error);
          });
        }).catch(function (err) {
          Ember.Logger.log(err);
        });
      }
    }
  });

  _exports.default = _default;
});