define("admin/components/order-shipping/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['row'],
    shippingStatuses: [{
      value: 'preparing',
      label: 'Preparing'
    }, {
      value: 'sent',
      label: 'Sent'
    }, {
      value: 'on_route',
      label: 'On Route'
    }, {
      value: 'delivered',
      label: 'Delivered'
    }],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('editing', false);

      if (this.get('order.status')) {
        this.set('selectedOrderStatus', this.get('order.status'));
      }

      if (this.get('order.shipping.status')) {
        this.set('selectedShippingStatus', this.get('order.shipping.status'));
      } else {
        this.set('selectedShippingStatus', 'preparing');
      }

      this.set('idx', this.get('index') + 1);
    },
    saveShipping: function saveShipping() {
      var shipping = this.get('order.shipping');
      shipping.set('status', this.get('selectedShippingStatus')); // Unfortunately there is no consensus for the serializers extending EmbeddedRecordsMixin on how to save relationships, so we need to use this:

      return shipping.content._internalModel.save(); // instead of simply using .save(). Method .save() apparently is not added to the model on components of an aggregated model that uses EmbeddedRecordsMixin in its serializer.
      // return shipping.save();
    },
    actions: {
      modify: function modify() {
        this.toggleProperty('editing');
      },
      save: function save() {
        var _this2 = this;

        var _this = this; // Update the order in case its status has been modified


        if (this.get('order.status') !== this.get('selectedOrderStatus')) {
          this.set('order.status', this.get('selectedOrderStatus'));
          this.get('order').save().then(function (order) {
            _this.set('editing', false);

            Ember.Logger.log('editing', _this.get('editing'));

            _this2.saveShipping().then(function () {
              if (_this2.get('finishOrder')) {
                _this2.sendAction('finishOrder', order);
              }
            });
          });
        } else {
          this.saveShipping().then(function () {
            _this2.set('editing', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});