define("admin/components/order-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currencies: [{
      value: 'RON',
      label: 'RON'
    }, {
      value: 'EUR',
      label: 'EUR'
    }],
    oneTimeTotals: Ember.computed('order.products.@each.quantity', 'order.products.@each.unitPrice', 'order.products.@each.unitSetupFee', function () {
      var price = 0;
      var install = 0;
      this.get('order.products').forEach(function (product) {
        if (product.get('quantity')) {
          if (product.get('unitPrice')) {
            price += product.get('unitPrice') * product.get('quantity');
          }

          if (product.get('unitSetupFee')) {
            install += product.get('unitSetupFee') * product.get('quantity');
          }
        }
      });
      return {
        price: price,
        setup: install
      };
    }),
    subscriptionTotal: Ember.computed('order.subscription.billingCycle', 'order.products.@each.serviceFee', 'order.products.@each.custodyFee', 'order.products.@each.quantity', 'order.products.@each.custody', function () {
      var products = this.get('order.products');
      var total = 0;

      if (products) {
        products.forEach(function (product) {
          var price = 0;

          if (product.get('serviceFee')) {
            price += parseFloat(product.get('serviceFee'));
          }

          if (product.get('custody')) {
            price += parseFloat(product.get('custodyFee'));
          }

          total += price * parseInt(product.get('quantity'));
        });
      }

      return total;
    }),
    allowSave: Ember.computed('order.products.@each.isValid', function () {
      Ember.Logger.log('Verifying if we can save the order');
      var valid = this.get('order.products.length') ? true : false;
      this.get('order.products').forEach(function (product) {
        if (valid && !product.get('isValid')) {
          valid = false;
        }
      }); // return valid;

      return true;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('order.company')) {
        this.set('selectedCompany', this.get('order.company'));
      }

      if (this.get('order.status')) {
        this.set('selectedStatus', this.get('order.status'));
      }

      if (!this.get('order.id')) {
        this.set('editing', true);
      }

      this.set('idx', this.get('index') + 1);
    },
    matchCompanySearch: function matchCompanySearch(company, term) {
      var searchText = term.toLowerCase();

      if (company.get('name')) {
        var name = company.get('name').toLowerCase();
        var cui = company.get('details.CUI') ? company.get('details.CUI').toLowerCase() : '';
        return name.includes(searchText) || cui.includes(searchText) ? 1 : -1;
      } else {
        return false;
      }
    },
    invoicingIntervalEn: Ember.computed('selectedCompany.invoicingInterval', function () {
      var intervals = {
        1: "Monthly",
        6: "Biannual",
        12: "Yearly"
      };

      if (this.get('selectedCompany.invoicingInterval')) {
        return intervals[this.get('selectedCompany.invoicingInterval')];
      } else {
        return intervals[1];
      }
    }),
    actions: {
      selectCompany: function selectCompany(company) {
        this.set('selectedCompany', company);
      },
      save: function save() {
        var _this2 = this;

        var _this = this;

        this.set('order.company', this.get('selectedCompany'));
        this.set('order.status', this.get('selectedStatus'));
        this.get('order').save().then(function (order) {
          var removables = []; // remove the leftovers...

          _this2.get('order').get('products').forEach(function (product) {
            if (!product || product && !product.get('id')) {
              removables.push(product);
            }
          });

          _this.set('editing', false);

          Ember.Logger.log('editing', _this.get('editing'));

          _this2.get('order').get('products').removeObjects(removables);

          if (_this2.get('finishOrder')) {
            _this2.sendAction('finishOrder', order);
          }
        });
      },
      cancel: function cancel() {
        this.set('editing', false);
      },
      addDeviceType: function addDeviceType() {
        var orderProduct = this.get('store').createRecord('order-product');
        this.get('order').get('products').pushObject(orderProduct);
      },
      delete: function _delete() {
        this.sendAction('deleteOrder', this.get('order'));
      }
    }
  });

  _exports.default = _default;
});