define("admin/models/vehicle-status", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    location: _emberData.default.attr(),
    speed: _emberData.default.attr("number"),
    bearing: _emberData.default.attr("number"),
    fuelLevel: _emberData.default.attr("number"),
    engineState: _emberData.default.attr("boolean"),
    odometer: _emberData.default.attr("number"),
    can: _emberData.default.attr(),
    relayState: _emberData.default.attr(),
    updatedAt: _emberData.default.attr("date"),
    state: _emberData.default.attr('string')
  });

  _exports.default = _default;
});