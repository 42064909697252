define("admin/transforms/numeric-range", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var deserialized = {
        min: 0,
        max: 0
      };

      if (serialized) {
        if (serialized.min) {
          deserialized.min = serialized.min;
        }

        if (serialized.max) {
          deserialized.max = serialized.max;
        }
      }

      return deserialized;
    },
    serialize: function serialize(deserialized) {
      return Ember.isEmpty(deserialized) ? {
        min: 0,
        max: 0
      } : {
        min: deserialized.min,
        max: deserialized.max
      };
    }
  });

  _exports.default = _default;
});