define("admin/models/vehicle-fuel-tank-calibration", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    level: _emberData.default.attr("number", {
      defaultValue: null
    }),
    voltage: _emberData.default.attr("number", {
      defaultValue: null
    })
  });

  _exports.default = _default;
});