define("admin/routes/companies", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        groups: this.get("store").findAll("group")
      });
    } // ,
    // afterModel: function (model, transition) {
    //     model.groups= model.groups.sortBy("id");
    // }

  });

  _exports.default = _default;
});