define("admin/models/vehicle-refuel", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    vehicleID: _emberData.default.attr("string"),
    amount: _emberData.default.attr("number"),
    timestamp: _emberData.default.attr(),
    price: _emberData.default.attr("number"),
    groupID: _emberData.default.attr("string"),
    location: _emberData.default.attr("string"),
    invoice: _emberData.default.attr("string"),
    type: _emberData.default.attr("string"),
    source: _emberData.default.attr("string"),
    duration: _emberData.default.attr("string"),
    createdAt: _emberData.default.attr("string"),
    updatedAt: _emberData.default.attr("string"),
    deletedAt: _emberData.default.attr("string")
  });

  _exports.default = _default;
});