define("admin/components/offer-detail-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editing: false,
    deviceTypes: null,
    serviceTypes: null,
    selectableServices: [],
    currentUser: Ember.inject.service('current-user'),
    isSuperAdmin: Ember.computed("currentUser.isSuperAdmin", function () {
      return this.get('currentUser.isSuperAdmin');
    }),
    custodyOptions: Ember.computed('company', function () {
      var options = [{
        value: 'custody',
        placeholder: 'In custody'
      }];

      if (this.get('isSuperAdmin') || this.get('company.type') !== 'potential-customer' && parseInt(this.get('company.id')) < 504) {
        options.push({
          value: 'sold',
          placeholder: 'Sold'
        });
      }

      return options;
    }),
    selectedCustodyOptionName: Ember.computed('detail.custodyStatus', function () {
      var custodyStatus = this.get('detail.custodyStatus');

      if (custodyStatus === 'sold') {
        return 'Sold';
      }

      return this.get('custodyOptions').find(function (docType) {
        return docType.value === custodyStatus;
      }).placeholder;
    }),
    formatMoney: function formatMoney(number, decPlaces, thouSeparator, decSeparator) {
      var n = number,
          decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
          decSeparator = decSeparator == undefined ? "." : decSeparator,
          thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
          sign = n < 0 ? "-" : "",
          i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
          j = (j = i.length) > 3 ? j % 3 : 0;
      return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
    },
    formatEuropean: function formatEuropean(number) {
      return this.formatMoney(number, 2, '.', ',');
    },
    invoicingIntervalEn: Ember.computed('invoicingInterval', function () {
      var intervals = {
        1: "Monthly",
        6: "Biannually",
        12: "Yearly"
      };

      if (this.get('invoicingInterval')) {
        return intervals[this.get('invoicingInterval')];
      } else {
        return intervals[1];
      }
    }),
    subscription: Ember.computed('invoicingInterval', 'detail.serviceType', function () {
      var subscriptions = {
        1: this.get('detail.serviceType.monthlyFee'),
        6: this.get('detail.serviceType.biannualFee'),
        12: this.get('detail.serviceType.yearlyFee')
      };

      if (this.get('invoicingInterval')) {
        return subscriptions[this.get('invoicingInterval')];
      } else {
        return subscriptions[1];
      }
    }),
    custody: Ember.computed('invoicingInterval', 'detail.deviceType', function () {
      var subscriptions = {
        1: this.get('detail.deviceType.monthlyCustody'),
        6: this.get('detail.deviceType.biannualCustody'),
        12: this.get('detail.deviceType.yearlyCustody')
      };

      if (this.get('invoicingInterval')) {
        return subscriptions[this.get('invoicingInterval')];
      } else {
        return subscriptions[1];
      }
    }),
    costs: Ember.computed('detail.custody', 'detail.subscription', 'showCustody', 'invoicingInterval', function () {
      if (this.get('showCustody')) {
        return this.formatEuropean(parseFloat(this.get('detail.custody')) + parseFloat(this.get('detail.recurringServiceFee')));
      } else {
        return this.formatEuropean(parseFloat(this.get('detail.recurringServiceFee')));
      }
    }),
    showCustody: Ember.computed('detail.custodyStatus', 'isProspect', function () {
      return this.get('detail.custodyStatus') === 'custody' && !this.get('isProspect');
    }),
    showServiceCost: Ember.computed('selectableServices', 'isProspect', function () {
      return this.get('selectableServices.length') && !this.get('isProspect');
    }),
    fixedCustody: Ember.computed('custody.min', 'custody.max', function () {
      var custodyMin = this.get('custody.min');

      if (custodyMin == this.get('custody.max')) {
        return custodyMin;
      } else {
        return false;
      }
    }),
    fixedPrice: Ember.computed('detail.deviceType.price.min', 'detail.deviceType.price.max', function () {
      var priceMin = this.get('detail.deviceType.price.min');

      if (priceMin == this.get('detail.deviceType.price.max')) {
        return priceMin;
      } else {
        return false;
      }
    }),
    fixedSubscription: Ember.computed('subscription.min', 'subscription.max', function () {
      var subscriptionMin = this.get('subscription.min');

      if (subscriptionMin == this.get('subscription.max')) {
        return subscriptionMin;
      } else {
        return false;
      }
    }),
    fixedSetup: Ember.computed('detail.deviceType.setup.min', 'detail.deviceType.setup.max', function () {
      var setupMin = this.get('detail.deviceType.setup.min');

      if (setupMin == this.get('detail.deviceType.setup.max')) {
        return setupMin;
      } else {
        return false;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var isPotentialCustomer = this.get('company.type') === 'potential-customer';
      Ember.Logger.debug('isPotentialCustomer', isPotentialCustomer);
      Ember.Logger.debug('company.type', this.get('company.name'));
      var disabledCustodyOptions = this.get('isSuperAdmin') ? false : isPotentialCustomer || !isPotentialCustomer && parseInt(this.get('company.id')) > 504;
      this.set('disabledCustodyOptions', disabledCustodyOptions);
      Ember.Logger.debug('disabled custody options', disabledCustodyOptions);
      this.setDetailObjectDefaultValues();
      this.set('deviceTypes', this.get('store').peekAll('device-type'));
      this.set('serviceTypes', this.get('store').peekAll('service-type'));
      this.set('selectableServices', this.get('serviceTypes'));
      var validObj = {
        price: {
          isValid: true
        },
        recurringServiceFee: {
          isValid: true
        },
        setup: {
          isValid: true
        },
        devType: {
          isValid: true,
          message: ""
        },
        custody: {
          isValid: true
        },
        quantity: {
          isValid: true
        }
      };
      this.set('validDetail', validObj);
    },
    setDetailObjectDefaultValues: function setDetailObjectDefaultValues() {
      var detail = this.get("detail");
      var defaultDetail = {
        deviceTypeID: null,
        serviceTypeID: null,
        recurringServiceFee: null,
        price: null,
        quantity: null,
        setup: null,
        custodyStatus: this.get('isProspect') ? 'custody' : null,
        custody: null
      };

      if (detail) {
        defaultDetail = {
          deviceTypeID: detail.get("deviceTypeID"),
          serviceTypeID: detail.get("serviceTypeID"),
          recurringServiceFee: detail.get("recurringServiceFee"),
          price: detail.get("price"),
          quantity: detail.get("quantity"),
          setup: detail.get("setup"),
          custodyStatus: detail.get('custodyStatus'),
          custody: detail.get('custody')
        };
      }

      this.set("detailObject", this.get('store').createFragment('offer-details', defaultDetail));
    },
    rollback: function rollback() {
      var initialDetail = this.get("detailObject");

      if (this.get('detail')) {
        this.set('detail.deviceTypeID', initialDetail.get('deviceTypeID'));
        this.set('detail.serviceTypeID', initialDetail.get('serviceTypeID'));
        this.set('detail.recurringServiceFee', initialDetail.get('recurringServiceFee'));
        this.set('detail.price', initialDetail.get('price'));
        this.set('detail.quantity', initialDetail.get('quantity'));
        this.set('detail.setup', initialDetail.get('setup'));
        this.set('detail.custodyStatus', initialDetail.get('custodyStatus'));
        this.set('detail.custody', initialDetail.get('custody'));
      }
    },
    validationDeviceType: function validationDeviceType(min, max, val) {
      if (val >= min && val <= max) return true;
      return false;
    },
    detailIsValid: Ember.computed('detail.deviceType', 'detail.quantity', function () {
      return this.get('detail.deviceType') && parseInt(this.get('detail.quantity'));
    }),
    validateDetail: function validateDetail(detail) {
      var deviceType = detail.get('deviceType');
      var validObj = {
        price: {
          isValid: false
        },
        recurringServiceFee: {
          isValid: false
        },
        setup: {
          isValid: false
        },
        devType: {
          isValid: false,
          message: ""
        },
        custodyStatus: {
          isValid: false
        },
        custody: {
          isValid: false
        },
        quantity: {
          isValid: false
        }
      };

      if (!deviceType) {
        validObj.price.isValid = true;
        validObj.recurringServiceFee.isValid = true;
        validObj.setup.isValid = true;
        validObj.devType.isValid = false;
        validObj.quantity.isValid = true;
        validObj.devType.message = "Select a device type.";
        return validObj;
      } else {
        validObj.devType.isValid = true;
      }

      if (!this.get('isProspect')) {
        if (this.validationDeviceType(deviceType.get("price.min"), deviceType.get("price.max"), detail.get("price"))) {
          validObj.price.isValid = true;
        } else {
          validObj.price.isValid = false;
        }

        if (this.get('showCustody')) {
          if (this.validationDeviceType(this.get("custody.min"), this.get("custody.max"), detail.get("custody"))) {
            validObj.custody.isValid = true;
          } else {
            validObj.custody.isValid = false;
          }
        } else {
          validObj.custody.isValid = true;
        }

        if (this.get('selectableServices.length')) {
          if (this.validationDeviceType(this.get("subscription.min"), this.get("subscription.max"), detail.get("recurringServiceFee"))) {
            validObj.recurringServiceFee.isValid = true;
          } else {
            validObj.recurringServiceFee.isValid = false;
          }
        } else {
          validObj.recurringServiceFee.isValid = true;
        }

        if (this.validationDeviceType(deviceType.get("setup.min"), deviceType.get("setup.max"), detail.get("setup"))) {
          validObj.setup.isValid = true;
        } else {
          validObj.setup.isValid = false;
        }

        if (!this.get('detail.quantity')) {
          validObj.quantity.isValid = false;
        } else {
          validObj.quantity.isValid = true;
        }

        Ember.Logger.log('This is NOT a prospect');
      } else {
        if (this.validationDeviceType(0, deviceType.get("price.max"), detail.get("price"))) {
          validObj.price.isValid = true;
        } else {
          validObj.price.isValid = false;
        }

        if (!this.get('detail.quantity')) {
          validObj.quantity.isValid = false;
        } else {
          validObj.quantity.isValid = true;
        }

        validObj.custody.isValid = true;
        validObj.recurringServiceFee.isValid = true;
        validObj.setup.isValid = true;
        Ember.Logger.log('This is a prospect');
      }

      if (deviceType.get('custodyStatus')) {
        validObj.custodyStatus.isValid = true;
      } else {
        validObj.custodyStatus.isValid = false;
        validObj.custodyStatus.message = 'Select a custody status';
      }

      return validObj;
    },
    actions: {
      save: function save() {
        var detail = this.get("detail");
        var validDetail = this.validateDetail(detail);
        var allValid = validDetail.price.isValid && validDetail.recurringServiceFee.isValid && validDetail.setup.isValid && validDetail.devType.isValid && validDetail.custody.isValid;
        this.set('validDetail', validDetail);

        if (!allValid) {
          return false;
        }

        var isNewDetail = this.get("isNewDetail");

        if (isNewDetail) {
          this.get("addDetailToOffer")(detail);
        }

        Ember.Logger.log(allValid, detail, isNewDetail);
        this.set('editing', false);
      },
      cancel: function cancel() {
        this.rollback();
        this.set('editing', false);
        this.get('cancelDetail')();
      },
      remove: function remove() {
        var detail = this.get("detail");
        this.get("removeDetail")(detail);
      },
      selectDeviceType: function selectDeviceType(deviceTypeID) {
        this.get('detail').set('deviceTypeID', deviceTypeID);
        var deviceObj = this.deviceTypes.filter(function (type) {
          return type.id == deviceTypeID;
        })[0];
        var deviceServices = Ember.get(deviceObj, 'services');

        if (deviceServices && deviceServices.length > 0) {
          this.set('selectableServices', this.serviceTypes.filter(function (service) {
            return deviceServices.includes(service.id);
          }));
        } else {
          this.set('selectableServices', []);
        }

        if (this.get('detail.custodyStatus') && this.get('detail.deviceType.custodiable')) {
          var fixedCustody = this.get('fixedCustody');

          if (fixedCustody !== false) {
            this.set('detail.custody', fixedCustody);
          }

          this.set('detail.price', 0);
        } else {
          var fixedPrice = this.get('fixedPrice');

          if (fixedPrice !== false) {
            this.set('detail.price', fixedPrice);
          }

          this.set('detail.custody', 0);
        }

        var fixedSetup = this.get('fixedSetup');

        if (fixedSetup) {
          this.set('detail.setup', fixedSetup);
        }
      },
      onInit: function onInit() {
        var _this = this;

        if (!this.get('detail.deviceTypeID')) return;
        var deviceObj = this.deviceTypes.filter(function (type) {
          return type.id == _this.get('detail.deviceTypeID');
        })[0];
        var deviceServices = Ember.get(deviceObj, 'services');

        if (deviceServices && deviceServices.length > 0) {
          this.set('selectableServices', this.serviceTypes.filter(function (service) {
            return deviceServices.includes(service.id);
          }));
        } else {
          this.set('selectableServices', []);
        }
      },
      selectServiceType: function selectServiceType(service) {
        this.get('detail').set('serviceTypeID', Ember.get(service, 'id'));
        var fixedSubscription = this.get('fixedSubscription');

        if (fixedSubscription !== false) {
          this.set('detail.recurringServiceFee', fixedSubscription);
        }
      },
      selectCustodyStatus: function selectCustodyStatus(status) {
        this.get('detail').set('custodyStatus', status);

        if (status) {
          var fixedCustody = this.get('fixedCustody');

          if (fixedCustody !== false) {
            this.set('detail.custody', fixedCustody);
          }

          this.set('detail.price', 0);
        } else {
          var fixedPrice = this.get('fixedPrice');

          if (fixedPrice !== false) {
            this.set('detail.price', fixedPrice);
          }

          this.set('detail.custody', 0);
        }
      }
    }
  });

  _exports.default = _default;
});