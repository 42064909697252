define("admin/models/device-config", ["exports", "ember-data-model-fragments"], function (_exports, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DS from 'ember-data';
  var _default = _emberDataModelFragments.default.Fragment.extend({
    sim: _emberDataModelFragments.default.fragment("device-config-sim"),
    device: _emberDataModelFragments.default.fragment("device-config-device")
  });

  _exports.default = _default;
});