define("admin/routes/fix-orders", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var _this = this;

      // orderProducts: this.store.findAll('order-product'),
      // orders: this.store.findAll('order')
      var hash = {
        orderProductItems: null,
        orderProducts: null,
        orders: null,
        groups: null,
        devices: null
      };
      return this.store.query('order-product-item', {
        $where: {
          'is null': 'deviceSN'
        }
      }).then(function (items) {
        hash.orderProductItems = items;
        var productIDs = items.reduce(function (itemIDs, item) {
          var id = item.belongsTo('orderProduct').id();
          if (!itemIDs.includes(id)) itemIDs.push(id);
          return itemIDs;
        }, []);
        Ember.Logger.log('productIDs: ', productIDs);
        return _this.store.query('order-product', {
          $where: {
            id: productIDs
          }
        });
      }).then(function (products) {
        hash.orderProducts = products;
        var orderIDs = products.reduce(function (orderIDs, product) {
          var id = product.belongsTo('order').id();
          if (!orderIDs.includes(id)) orderIDs.push(id);
          return orderIDs;
        }, []);
        Ember.Logger.log('orderIDs: ', orderIDs);
        return _this.store.query('order', {
          $where: {
            id: orderIDs
          }
        });
      }).then(function (orders) {
        hash.orders = orders;
        var groupIDs = orders.reduce(function (groupIDs, order) {
          var id = order.belongsTo('company').id();
          if (!groupIDs.includes(id)) groupIDs.push(id);
          return groupIDs;
        }, []);
        Ember.Logger.log('groupIDs: ', groupIDs);
        return _this.store.query('group', {
          $where: {
            id: groupIDs
          }
        }); // return Ember.RSVP.all([
        //     this.store.query('group', {
        //         $where: { id: groupIDs }
        //     }),
        //     this.store.query('device', {
        //         $where: {
        //             group_id: groupIDs
        //         }
        //     })
        // ]);
      }).then(function (results) {
        // hash.groups = results[0];
        // hash.devices = results[1];
        hash.groups = results;
        return Ember.RSVP.hash(hash);
      });
    }
  });

  _exports.default = _default;
});