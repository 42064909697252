define("admin/controllers/orders-required", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    api: Ember.inject.service('api'),
    store: Ember.inject.service('store'),
    session: Ember.inject.service(),
    total: Ember.computed('model.devicesMissingFromOrders', function () {
      return this.get('model.devicesMissingFromOrders').reduce(function (total, orphans) {
        total += parseInt(orphans.qty);
        return total;
      }, 0);
    }),
    actions: {
      selectItem: function selectItem(index) {
        Ember.$(".orphan-item").removeClass('active-light');
        Ember.$("#orphan-item-".concat(index)).addClass('active-light');
      },
      getSNs: function getSNs(orphans) {
        this.get('api').request('orders/getFreeCompanyDevicesOfType', {
          data: {
            deviceTypeID: orphans.get('deviceType.id'),
            companyID: orphans.get('company.id')
          }
        }).then(function (response) {
          orphans.set('devices', response.device);
        }).catch(Ember.Logger.error);
      },
      removeSN: function removeSN(orphans, index) {
        orphans.get('devices').removeAt(index);
      },
      copyToClipboard: function copyToClipboard(deviceSN) {
        var el = document.createElement('textarea');
        el.value = deviceSN;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      },
      copyAllToClipboard: function copyAllToClipboard(devices) {
        var el = document.createElement('textarea');
        var SNs = devices.map(function (device) {
          return device.sn;
        }).join(',');
        el.value = SNs;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    }
  });

  _exports.default = _default;
});