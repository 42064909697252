define("admin/controllers/staff", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    newUser: {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      role: null
    },
    deleteUserModal: {
      user: {},
      open: false
    },
    electricians: Ember.computed.filter("model.users.@each.role", function (user) {
      return user.get("role") === "electrician";
    }),
    salesAgents: Ember.computed.filter("model.users.@each.role", function (user) {
      return user.get("role") === "sales-agent";
    }),
    operationalManagers: Ember.computed.filter("model.users.@each.role", function (user) {
      return user.get("role") === "operational-manager";
    }),
    supportEngineers: Ember.computed.filter("model.users.@each.role", function (user) {
      return user.get("role") === "support-engineer";
    }),
    actions: {
      inviteUser: function inviteUser() {
        var currentUser = this.get("session.session.authenticated.profile");

        var _this = this;

        if (currentUser.role === "super-admin") {
          var invitation = this.get("store").createRecord("user-invitation", {
            firstName: this.get("newUser.firstName"),
            lastName: this.get("newUser.lastName"),
            email: this.get("newUser.email"),
            phoneNumber: this.get("newUser.phoneNumber"),
            role: this.get("newUser.role"),
            cnp: this.get("newUser.cnp"),
            groupID: _environment.default.electriciansGroupID,
            signup_url: _environment.default.signup_url
          });

          if (_environment.default.overrideEmail) {
            invitation.set("email", _environment.default.overrideEmail);
          }

          invitation.save().then(function (response) {
            var user = _this.get("store").createRecord("user", {
              firstName: invitation.get("firstName"),
              lastName: invitation.get("lastName"),
              email: invitation.get("email"),
              phoneNumber: invitation.get("phoneNumber"),
              role: invitation.get("role"),
              groupID: invitation.get("groupID"),
              companyID: invitation.get("companyID"),
              id: response.get("id"),
              cnp: invitation.get('cnp')
            });

            _this.set("newUser", {
              firstName: null,
              lastName: null,
              email: null,
              phoneNumber: null,
              role: null,
              cnp: null
            });
          });
        }
      },
      updateUser: function updateUser(user, callback) {
        Ember.Logger.log("Updating user:", user);
        user.save().then(function (response) {
          Ember.Logger.log("Success: ", JSON.parse(JSON.stringify(response)));

          if (callback) {
            callback();
          }
        }, function (error) {
          Ember.Logger.log("Error: ", error);
        });
      },
      deleteConfirmation: function deleteConfirmation(user) {
        this.set("deleteUserModal.user", user);
        this.set("deleteUserModal.open", true);
      },
      confirmDeleteUser: function confirmDeleteUser() {
        this.get("deleteUserModal.user").destroyRecord();
        this.set("deleteUserModal.user", {});
        this.set("deleteUserModal.open", false);
      },
      cancelDelete: function cancelDelete() {
        this.set("deleteUserModal.user", {});
        this.set("deleteUserModal.open", false);
      }
    }
  });

  _exports.default = _default;
});