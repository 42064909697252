define("admin/components/order-subscription/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['row'],
    subscriptionStatuses: [{
      value: 'pending',
      label: 'Pending'
    }, {
      value: 'active',
      label: 'Active'
    }, {
      value: 'suspended',
      label: 'Suspended'
    }, // Temporary inactive
    {
      value: 'cancelled',
      label: 'Cancelled'
    }, {
      value: 'terminated',
      label: 'Terminated'
    }],
    billingCycles: [{
      value: 1,
      label: "Monthly"
    }, {
      value: 6,
      label: "Biannually"
    }, {
      value: 12,
      label: "Yearly"
    }],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('editing', false);

      if (this.get('order.status')) {
        this.set('selectedOrderStatus', this.get('order.status'));
      }

      if (this.get('order.subscription.status')) {
        this.set('selectedSubscriptionStatus', this.get('order.subscription.status'));
      } else {
        this.set('selectedSubscriptionStatus', 'pending');
      }

      this.set('idx', this.get('index') + 1);
    },
    saveSubscription: function saveSubscription() {
      var subscription = this.get('order.subscription');
      subscription.set('status', this.get('selectedSubscriptionStatus')); // Unfortunately there is no consensus for the serializers extending EmbeddedRecordsMixin on how to save relationships, so we need to use this:

      return subscription.content._internalModel.save(); // instead of simply using .save(). Method .save() apparently is not added to the model on components of an aggregated model that uses EmbeddedRecordsMixin in its serializer.
      // return shipping.save();
    },
    actions: {
      modify: function modify() {
        this.toggleProperty('editing');
      },
      save: function save() {
        var _this2 = this;

        var _this = this; // Update the order in case its status has been modified


        if (this.get('order.status') !== this.get('selectedOrderStatus')) {
          this.set('order.status', this.get('selectedOrderStatus'));
          this.get('order').save().then(function (order) {
            _this2.saveSubscription().then(function () {
              _this.set('editing', false);

              if (_this2.get('finishOrder')) {
                _this2.sendAction('finishOrder', order);
              }
            });
          });
        } else {
          this.saveSubscription().then(function () {
            _this2.set('editing', false);
          });
        }
      },
      selectBillingCycle: function selectBillingCycle(_ref) {
        var value = _ref.value;
        this.get('order.subscription').set('billingCycle', value);
      }
    }
  });

  _exports.default = _default;
});