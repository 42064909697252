define("admin/components/vehicle-sensor/component", ["exports", "xml-js", "lodash"], function (_exports, _xmlJs, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sensorTypes: [{
      label: "Fuel level (tank #1)",
      value: "fuel-1"
    }, {
      label: "Fuel level (tank #2)",
      value: "fuel-2"
    }, {
      label: "Temperature (zone 1)",
      value: "temperature-1"
    }, {
      label: "Temperature (zone 2)",
      value: "temperature-2"
    }, {
      label: "Temperature (zone 3)",
      value: "temperature-3"
    }, {
      label: "Temperature (zone 4)",
      value: "temperature-4"
    }, {
      label: "Temperature (zone 5)",
      value: "temperature-5"
    }, {
      label: "Temperature (zone 6)",
      value: "temperature-6"
    }, {
      label: "Temperature (zone 7)",
      value: "temperature-7"
    }, {
      label: "Temperature (zone 8)",
      value: "temperature-8"
    }, {
      label: "Temperature (deprecated - do not use)",
      value: "temperature"
    }, {
      label: "Door Front Left",
      value: "door-front-left"
    }, {
      label: "Door Front Right",
      value: "door-front-right"
    }, {
      label: "Door Back Left",
      value: "door-back-left"
    }, {
      label: "Door Back Right",
      value: "door-back-right"
    }, {
      label: "Door Trunk",
      value: "door-trunk"
    }, {
      label: "Freezer",
      value: "freezer"
    }, {
      label: "Taxi Available",
      value: "taxi-available"
    }, {
      label: "Taxi Occupied",
      value: "taxi-occupied"
    }, {
      label: "Engine load",
      value: "engine-load"
    }, {
      label: "Engine running",
      value: "engine-running"
    }, {
      label: "Auxiliary heating",
      value: "aux-heating"
    }, {
      label: "Auxiliary cooling",
      value: "aux-cooling"
    }, {
      label: "Relay",
      value: "relay"
    }, {
      label: "iButton",
      value: "ibutton"
    }, {
      label: "Power external",
      value: "power-external"
    }, {
      label: "Tacho D8",
      value: "tacho-d8"
    }, {
      label: "Custom",
      value: "custom"
    }],
    sensorInputs: [{
      label: "Digital Input 1",
      value: "digital-in-1"
    }, {
      label: "Digital Input 2",
      value: "digital-in-2"
    }, {
      label: "Digital Input 3",
      value: "digital-in-3"
    }, {
      label: "Digital Input 4",
      value: "digital-in-4"
    }, {
      label: "Digital Input 5",
      value: "digital-in-5"
    }, {
      label: "Digital Input 6",
      value: "digital-in-6"
    }, {
      label: "Digital Input 7",
      value: "digital-in-7"
    }, {
      label: "Digital Input 8",
      value: "digital-in-8"
    }, {
      label: "Digital Output 1",
      value: "digital-out-1"
    }, {
      label: "Digital Output 2",
      value: "digital-out-2"
    }, {
      label: "Analog Input 1",
      value: "analog-in-1"
    }, {
      label: "Analog Input 2",
      value: "analog-in-2"
    }, {
      label: "Power external",
      value: "power-external"
    }, {
      label: "Power battery",
      value: "power-battery"
    }, {
      label: "1-Wire Temperature #1",
      value: "1-wire-temp-1"
    }, {
      label: "1-Wire Temperature #2",
      value: "1-wire-temp-2"
    }, {
      label: "1-Wire Temperature #3",
      value: "1-wire-temp-3"
    }, {
      label: "1-Wire Temperature #4",
      value: "1-wire-temp-4"
    }, {
      label: "Digital Thermograph Temperature #1",
      value: "thermograph-temp-1"
    }, {
      label: "Digital Thermograph Temperature #2",
      value: "thermograph-temp-2"
    }, {
      label: "Digital Thermograph Temperature #3",
      value: "thermograph-temp-3"
    }, {
      label: "Digital Thermograph Temperature #4",
      value: "thermograph-temp-4"
    }, {
      label: "Digital Thermograph Temperature #5",
      value: "thermograph-temp-5"
    }, {
      label: "Digital Thermograph Temperature #6",
      value: "thermograph-temp-6"
    }, {
      label: "Always True",
      value: "always-true"
    }],
    locales: [{
      label: "Romanian",
      value: "ro"
    }, {
      label: "English",
      value: "en"
    }, {
      label: "Polish",
      value: "pl"
    }, {
      label: "Danish",
      value: "da"
    }, {
      label: "Hungarian",
      value: "hu"
    }],
    translationValues: [{
      label: "On",
      value: 1
    }, {
      label: "Off",
      value: 0
    }],
    filterTypes: [{
      label: 'Kalman',
      value: 'kalman'
    }, {
      label: 'Exponential',
      value: 'exponential'
    }, {
      label: 'Temperature',
      value: 'custom-temp-1'
    }],
    newCalibration: null,
    uploadProgress: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("newCalibration", new Ember.Object({
        inputValue: null,
        outputValue: null
      }));
    },
    parseXml: function parseXml(xml) {
      var obj = (0, _xmlJs.xml2js)(xml);
      var sensorObj = (0, _lodash.chain)(obj).get('elements.0.elements').find(function (element) {
        return element.name === 'sensor' && element.type === 'element';
      }).value();
      var tankNumber = Number((0, _lodash.get)(sensorObj, 'attributes.number'));
      var calibration = (0, _lodash.chain)(sensorObj).get('elements').filter(function (elem) {
        return elem.type === 'element';
      }).map(function (row) {
        var v = (0, _lodash.get)(row, 'attributes.code') / 1000;
        var l = (0, _lodash.get)(row, 'elements.0.text') / 10;
        return {
          l: l,
          v: v
        };
      }).value();
      calibration.pop();
      return {
        tankNumber: tankNumber,
        calibration: calibration
      };
    },
    updateParsedCalibrations: function updateParsedCalibrations(calibrations) {
      var _this = this;

      if (!(calibrations === null || calibrations === void 0 ? void 0 : calibrations.length)) return;
      this.set('sensor.calibration', []);
      calibrations.forEach(function (step, index, array) {
        var annotationStep;

        if (!step.v && !step.l) {
          annotationStep = 0;
        } else {
          var _array$index, _array, _array$index2, _array2;

          annotationStep = ((((_array$index = array[index]) === null || _array$index === void 0 ? void 0 : _array$index.v) - ((_array = array[index - 1]) === null || _array === void 0 ? void 0 : _array.v)) / (((_array$index2 = array[index]) === null || _array$index2 === void 0 ? void 0 : _array$index2.l) - ((_array2 = array[index - 1]) === null || _array2 === void 0 ? void 0 : _array2.l))).toFixed(3);
        }

        _this.get("sensor.calibration").pushObject({
          inputValue: step.v,
          outputValue: step.l,
          annotation: annotationStep || 0
        });
      });
      this.set("newCalibration.inputValue", null);
      this.set("newCalibration.outputValue", null);
      this.set("newCalibration.annotation", null);
      this.set("newFileSelected", false);
    },
    updateAnnotationField: function updateAnnotationField(calibrations, calibrationIndex, inputType, value) {
      var allCalibrations = this.get("sensor.calibration"); //current calibration

      var currentCalibration = this.get("sensor.calibration").objectAt(calibrationIndex);
      var inputValueCurrentCalibration = +(inputType !== 'inputValue' ? currentCalibration.get('inputValue') : value);
      var outputValueCurrentCalibration = +(inputType !== 'outputValue' ? currentCalibration.get('outputValue') : value);

      if (calibrationIndex >= 1) {
        var previousCalibration = this.get("sensor.calibration").objectAt(calibrationIndex - 1);
        var inputValuePreviousCalibration = +previousCalibration.get('inputValue');
        var outputValuePreviousCalibration = +previousCalibration.get('outputValue');
        allCalibrations.replaceContent(calibrationIndex, 1, [{
          inputValue: inputValueCurrentCalibration,
          outputValue: outputValueCurrentCalibration,
          annotation: ((inputValueCurrentCalibration - inputValuePreviousCalibration) / (outputValueCurrentCalibration - outputValuePreviousCalibration)).toFixed(3)
        }]);
      } //next calibration


      var isLastCalibration = this.get("sensor.calibration").objectAt(calibrationIndex + 1) ? false : true;

      if (!isLastCalibration) {
        var nextCalibration = this.get("sensor.calibration").objectAt(calibrationIndex + 1);
        var inputValueNextCalibration = nextCalibration.get('inputValue');
        var outputValueNextCalibration = nextCalibration.get('outputValue');
        allCalibrations.replaceContent(calibrationIndex + 1, 1, [{
          inputValue: inputValueNextCalibration,
          outputValue: outputValueNextCalibration,
          annotation: ((inputValueNextCalibration - inputValueCurrentCalibration) / (outputValueNextCalibration - outputValueCurrentCalibration)).toFixed(3)
        }]);
      }
    },
    actions: {
      loadCalibrations: function loadCalibrations(element) {
        var file = element.target.files[0];

        if (file) {
          this.set("newFileSelected", true);
          this.set("showConfirmationButton", false);
          this.set("calibrations_file", file);
        } else {
          this.$(element.target.id).val(null);
          this.set("newFileSelected", false);
          this.set("calibrations_file", null);
        }

        element.target.value = null;
      },
      downloadCalibrations: function downloadCalibrations(sensor) {
        var xmlData = {
          sensor: this.get("sensor.type"),
          calibration: this.get("sensor.calibration").toArray().map(function (calibration) {
            return {
              inputValue: calibration.get("inputValue"),
              outputValue: calibration.get("outputValue")
            };
          })
        };
        xmlData.calibration.pushObject({
          inputValue: 0,
          outputValue: 0
        });

        var xmlVAR = require("xml-js").js2xml({
          _declaration: {
            _attributes: {
              version: "1.0",
              encoding: "UTF-8"
            }
          },
          "tns:vehicle": {
            _attributes: {
              "xmlns:tns": "http://schemes.omnicomm.ru/vehicle",
              "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
              "xsi:schemaLocation": "http://schemes.omnicomm.ru/vehicle http://schemes.omnicomm.ru/vehicle.xsd"
            },
            id: {
              _text: 1
            },
            sensor: {
              _attributes: {
                number: (xmlData.sensor || "").slice(-1) - 1
              },
              value: (0, _lodash.map)(xmlData.calibration, function (pair) {
                return {
                  _attributes: {
                    code: pair.inputValue * 1000
                  },
                  _text: pair.outputValue * 10
                };
              })
            }
          }
        }, {
          compact: true
        });

        var element = document.createElement("a");
        var file = new Blob([xmlVAR], {
          type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "".concat(this.get("sensor.type"), "_").concat(new Date().getTime(), ".xml");
        document.body.appendChild(element);
        element.click();
      },
      updateCalibrations: function updateCalibrations(sensor) {
        var _this2 = this;

        var file = this.get("calibrations_file");
        var reader = new FileReader();

        reader.onload = function () {
          var content = reader.result;

          var parsedCalibrations = _this2.parseXml(content);

          if (+sensor.get("type").slice(-1) !== parsedCalibrations.tankNumber + 1) return;

          _this2.updateParsedCalibrations(parsedCalibrations.calibration);
        };

        reader.readAsText(file);
      },
      cancelCalibrations: function cancelCalibrations() {
        this.$("calibrations-file").val(null);
        this.set("newFileSelected", false);
        this.set("calibrations_file", null);
      },
      addSensorCalibration: function addSensorCalibration() {
        var calibration = {
          inputValue: this.get("newCalibration.inputValue"),
          outputValue: this.get("newCalibration.outputValue"),
          annotation: this.get("newCalibration.annotation")
        };
        this.get("sensor.calibration").pushObject(calibration);
        this.set("newCalibration.inputValue", null);
        this.set("newCalibration.outputValue", null);
        this.set("newCalibration.annotation", null);
      },
      addSensorTranslation: function addSensorTranslation() {
        this.get("sensor.translation").pushObject({});
      },
      removeSensorTranslation: function removeSensorTranslation(translation) {
        this.get("sensor.translation").removeObject(translation);
      },
      addSensorValueTranslation: function addSensorValueTranslation(translation) {
        translation.get("values").pushObject({});
      },
      removeSensorValueTranslation: function removeSensorValueTranslation(translation, valueTranslation) {
        translation.get("values").removeObject(valueTranslation);
      },
      addSensorFilter: function addSensorFilter() {
        this.get("sensor.filters").pushObject({
          type: "exponential",
          parameters: []
        });
      },
      removeSensorFilter: function removeSensorFilter(filter) {
        this.get("sensor.filters").removeObject(filter);
      },
      remove: function remove() {
        this.sendAction("remove", this.get("sensor"), this.get("index"));
      },
      updateCalibrationInputValue: function updateCalibrationInputValue(index, value) {
        this.updateAnnotationField([], index, "inputValue", value);
      },
      updateCalibrationOutputValue: function updateCalibrationOutputValue(index, value) {
        this.updateAnnotationField([], index, "outputValue", value);
      }
    }
  });

  _exports.default = _default;
});