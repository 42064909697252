define("admin/components/company-invoice/component", ["exports", "moment", "admin/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["list-group-item", "invoice-line"],
    session: Ember.inject.service(),
    statusClass: Ember.computed('invoice.status', function () {
      switch (this.get('invoice.status')) {
        case 'emisa':
          return 'info';

        case 'incasata':
          return 'success';

        case 'depasita':
          return 'danger';
      }
    }),
    actions: {
      download: function download() {
        var token = this.get('session.session.authenticated.idToken');
        var fileName = "".concat(this.get('invoice.type') == 'proforma' ? 'Proforma' : 'Factura', "-").concat(this.get('invoice.series')).concat(this.get('invoice.number'), ".pdf");
        var req = new XMLHttpRequest();
        req.open("GET", "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/invoices/download/").concat(this.get('invoice.id')), true);
        req.responseType = "blob";
        req.setRequestHeader('Accept', 'application/pdf');
        req.setRequestHeader('Authorization', "Bearer ".concat(token));

        req.onload = function (event) {
          var blob = req.response;
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        };

        req.send();
      }
    }
  });

  _exports.default = _default;
});