define("admin/controllers/subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    activeCompaniesOnly: true,
    showIssuesOnly: true,
    selfPayingCompanies: Ember.computed.filter('model.subscriptions', function (company) {
      return !company.belongsTo('payerCompany').id();
    }),
    filteredResults: Ember.computed('showIssuesOnly', 'activeCompaniesOnly', 'selfPayingCompanies', function () {
      var subscriptions = this.get("selfPayingCompanies");
      var issuesOnly = this.get('showIssuesOnly');
      var activeOnly = this.get('activeCompaniesOnly');

      var issuesCheck = function issuesCheck() {
        return true;
      };

      var activeCheck = function activeCheck() {
        return true;
      };

      if (issuesOnly) {
        issuesCheck = function issuesCheck(diff, diffLicenses) {
          return diff || diffLicenses;
        };
      }

      if (activeOnly) {
        activeCheck = function activeCheck(company) {
          return company.get('enabled');
        };
      }

      return subscriptions.filter(function (subscription) {
        return issuesCheck(subscription.get('diff'), subscription.get('diffLicenses')) && activeCheck(subscription);
      });
    }),
    searchResults: Ember.computed('search', 'filteredResults', function () {
      var subscriptions = this.get("filteredResults");
      var search = this.get("search");

      if (search.length) {
        search = search.toLowerCase();
        return subscriptions.filter(function (subscription) {
          var name = subscription.get('name');
          return name ? name.toLowerCase().includes(search) || subscription.get('id').includes(search) : false;
        });
      } else {
        return subscriptions;
      }
    }),
    search: ''
  });

  _exports.default = _default;
});