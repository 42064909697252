define("admin/models/device-type", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    services: _emberData.default.attr(),
    custodyable: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    price: _emberData.default.attr('numeric-range'),
    setup: _emberData.default.attr('numeric-range'),
    monthlyCustody: _emberData.default.attr('numeric-range'),
    yearlyCustody: _emberData.default.attr('numeric-range'),
    biannualCustody: Ember.computed('yearlyCustody', function () {
      return {
        min: this.get('yearlyCustody.min') / 2,
        max: this.get('yearlyCustody.max') / 2
      };
    }),
    importDesignation: _emberData.default.attr('string'),
    available: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});