define("admin/controllers/devices-stocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    newStock: {
      name: null,
      managers: [],
      defaultTransferReason: null
    },
    isAddingDevicesStock: false,
    searchUsersInput: null,
    searchManagersInput: null,
    deselectedUsers: Ember.computed('newStock.managers', 'model.users', function () {
      var newStockManagers = this.get('newStock.managers');
      return this.get('model.users').filter(function (user) {
        return !newStockManagers.includes(user.get('id'));
      });
    }),
    newStockSelectedManagers: Ember.computed('newStock.managers', 'model.users', function () {
      var newStockManagers = this.get('newStock.managers');
      return this.get('model.users').filter(function (user) {
        return newStockManagers.includes(user.get('id'));
      });
    }),
    usersSearchResults: Ember.computed('searchUsersInput', 'deselectedUsers', function () {
      if (this.get('searchUsersInput')) {
        var searchText = this.get('searchUsersInput').toLowerCase();
        return this.get('deselectedUsers').filter(function (user) {
          var userValues = "".concat(user.get('firstName')).concat(user.get('lastName')).concat(user.get('email'));
          return userValues.toLowerCase().indexOf(searchText) > -1;
        });
      } else {
        return this.get('deselectedUsers');
      }
    }),
    managersSearchResults: Ember.computed('searchManagersInput', 'newStockSelectedManagers', function () {
      if (this.get('searchManagersInput')) {
        var searchText = this.get('searchManagersInput').toLowerCase();
        return this.get('newStockSelectedManagers').filter(function (user) {
          var userValues = "".concat(user.get('firstName')).concat(user.get('lastName')).concat(user.get('email'));
          return userValues.toLowerCase().indexOf(searchText) > -1;
        });
      } else {
        return this.get('newStockSelectedManagers');
      }
    }),
    resetNewStockForm: function resetNewStockForm() {
      this.set('newStock', {
        name: null,
        managers: [],
        defaultTransferReason: null
      });
    },
    actions: {
      addManager: function addManager(user) {
        Ember.Logger.log('User ID: ', user.get('id'));
        var managers = this.get('newStock.managers');
        managers.push(user.get('id'));
        this.set('newStock.managers', managers);
        this.notifyPropertyChange('newStock.managers');
      },
      removeManager: function removeManager(user) {
        var managers = this.get('newStock.managers');
        var userIndex = managers.findIndex(function (manager) {
          return user.get('id') === manager;
        });
        managers.splice(userIndex, 1);
        this.set('newStock.managers', managers);
        this.notifyPropertyChange('newStock.managers');
      },
      addDevicesStock: function addDevicesStock() {
        this.set('isAddingDevicesStock', true);
        this.set('selectedDevicesStockID', null);
        this.resetNewStockForm();
      },
      createDevicesStock: function createDevicesStock() {
        var _this = this;

        var devicesStock = this.get('store').createRecord('devices-stock', this.get('newStock'));
        devicesStock.save().then(function (devicesStock) {
          _this.resetNewStockForm();

          _this.set('isAddingDevicesStock', false);
        }).catch(function (error) {
          Ember.Logger.log('Error while creating devices stock: ', error);
        });
      },
      cancelDevicesStock: function cancelDevicesStock() {
        this.set('isAddingDevicesStock', false);
      },
      deleteDevicesStock: function deleteDevicesStock(devicesStock) {
        devicesStock.destroyRecord().then(function () {
          Ember.Logger.log('Devices Stock deleted successfully');
        });
      },
      selectDevicesStock: function selectDevicesStock(deviceStock) {
        var _this2 = this;

        this.set('selectedDevicesStockID', deviceStock.get('id'));
        deviceStock.get('quantities.deviceTypes').forEach(function (deviceType) {
          if (deviceType.deviceTypeID) {
            var _deviceStock = _this2.get('store').peekRecord('device-type', deviceType.deviceTypeID);

            if (_deviceStock) {
              Ember.set(deviceType, 'name', _deviceStock.get('name'));
            } else {
              Ember.set(deviceType, 'name', 'Other / Older');
            }
          } else {
            Ember.set(deviceType, 'name', 'Other / Older');
          }
        });
      }
    }
  });

  _exports.default = _default;
});