define("admin/components/vehicle-sensor-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filter: {},
    actions: {
      destroy: function destroy() {
        this.sendAction.apply(this, ['destroy'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});