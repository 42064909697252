define("admin/components/electrician-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["list-group-item from-horizontal"],
    tagName: "a",
    electrician: {},
    editMode: false,
    cancelEnabled: true,
    saveButtonText: "Update",
    actions: {
      toggleEditMode: function toggleEditMode() {
        var editMode = this.get("editMode");
        this.set("editMode", !editMode);
      },
      saveElectrician: function saveElectrician() {
        var _this = this;

        var callback = function callback() {
          _this.set("editMode", false);
        }; // Can be inviteElectrician on updateElectrician or whatever the assigned controller action given in the route template


        this.sendAction("saveElectrician", this.get("electrician"), callback);
      },
      deleteElectrician: function deleteElectrician() {
        this.sendAction("deleteConfirmation", this.get("electrician"));
      }
    }
  });

  _exports.default = _default;
});