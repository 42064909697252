define("admin/models/service-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    monthlyFee: _emberData.default.attr('numeric-range'),
    yearlyFee: _emberData.default.attr('numeric-range'),
    createdBy: _emberData.default.attr('string'),
    biannualFee: Ember.computed('yearlyFee', function () {
      return {
        min: this.get('yearlyFee.min') / 2,
        max: this.get('yearlyFee.max') / 2
      };
    }),
    available: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});