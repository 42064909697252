define("admin/models/order-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    order: _emberData.default.belongsTo('order', {
      inverse: 'subscription'
    }),
    groupSubscription: _emberData.default.belongsTo('group-subscription'),
    status: _emberData.default.attr('string'),
    proformaSeries: _emberData.default.attr('string'),
    proformaNumber: _emberData.default.attr('string'),
    invoiceSeries: _emberData.default.attr('string'),
    invoiceNumber: _emberData.default.attr('string'),
    billingStartDate: _emberData.default.attr('date'),
    billingCycle: _emberData.default.attr('number')
  });

  _exports.default = _default;
});