define("admin/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    keyForRelationship: function keyForRelationship(key, relationship) {
      if (key === 'device') {
        if (relationship === 'belongsTo') {
          return 'deviceSN';
        } else {
          return "".concat(key, "ID");
        }
      }

      if (relationship === 'belongsTo' || relationship === 'hasMany') {
        return "".concat(key, "ID");
      }
    },
    keyForPolymorphicType: function keyForPolymorphicType(key, relationship) {
      return relationship + 'Type';
    }
  });

  _exports.default = _default;
});