define("admin/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ember-get-config"], function (_exports, _unauthenticatedRouteMixin, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    showLock: function showLock() {
      var lockOptions = {
        autoclose: true,
        closable: false,
        allowSignUp: false,
        avatar: null,
        auth: {
          redirect: false,
          params: {
            scope: 'openid profile'
          }
        },
        configurationBaseUrl: 'https://cdn.eu.auth0.com'
      };
      this.get('session').authenticate('authenticator:tracknamic-admin', lockOptions).catch(function (error) {
        Ember.Logger.error("Login failed !", error);
        window.location.replace(_emberGetConfig.default['ember-simple-auth'].baseURL);
      });
    },
    activate: function activate() {
      this.showLock();
    }
  });

  _exports.default = _default;
});