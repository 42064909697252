define("admin/controllers/invitation", ["exports", "admin/controllers/application", "admin/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    api: Ember.inject.service('api'),
    locales: [{
      name: "Romanian",
      code: "ro"
    }, {
      name: "English",
      code: "en"
    }, {
      name: "Polish",
      code: "pl"
    }, {
      name: "Danish",
      code: "da"
    }, {
      name: "Hungarian",
      code: "hu"
    }],
    selectedLocale: {
      name: "Romanian",
      code: "ro"
    },
    init: function init() {
      this._super.apply(this, arguments); //this.set("selectedLocale",{name: "Romanian", code: "ro"});
      // this.set("clicked", false);

    },
    actions: {
      assignLocale: function assignLocale(locale) {
        this.set("selectedLocale", locale);

        if (this.get("companyAssets.length")) {
          var assets = this.get("companyAssets");
          assets.forEach(function (asset) {
            asset.user.details.locale = locale.code;
          });
        }

        Ember.Logger.log("Assets", this.get("companyAssets"));
      },
      invite: function invite() {
        Ember.Logger.log("[ INVITE ] Data :", this.get("newInvitation"));
        var companies = this.get("model.groups");

        var _this = this;

        var companyId = 0;
        var formIsValid = true;
        var errors = [];
        this.set("feedback", null);
        this.set("errors", errors);
        this.get("requiredFields").forEach(function (field) {
          var value = _this.get(field);

          if (value == null || value.length === 0) {
            formIsValid = false;
            errors.push(_this.get("requiredFieldsLabels")[field] + " is required");
          }
        });

        if (formIsValid) {
          companies.forEach(function (item) {
            var id = parseInt(item.get("companyID"));

            if (companyId < id) {
              companyId = id;
            }
          });
          companyId++;
          companyId = String("000000" + companyId).slice(-6); // Padding zeroes to the left of the number to get a fixed length of 6 characters

          var company = this.get("store").createRecord("group", this.get("newCompany"));
          company.set("settings.locale", this.get("selectedLocale.code"));
          company.set("id", companyId);
          company.set("enabled", true);

          _this.set("pleaseWaitModal", true);

          company.save().then(function (response) {
            Ember.Logger.log("[ INVITE ] Created company : ", response);

            var invitation = _this.get("store").createRecord("user-invitation", {
              firstName: _this.get("newInvitation.firstName"),
              lastName: _this.get("newInvitation.lastName"),
              email: _this.get("newInvitation.email"),
              phoneNumber: _this.get("newInvitation.phoneNumber"),
              groupID: company.get("id"),
              signup_url: _environment.default.signup_url,
              details: {
                locale: company.get("settings.locale")
              }
            });

            if (_environment.default.overrideEmail) {
              invitation.set("email", 'agj+dev' + new Date().getTime() + '@valgbar.com');
            }

            invitation.save().then(function (response) {
              Ember.Logger.log("[ INVITE ] Response: ", response);

              var assets = _this.get("companyAssets");

              Ember.Logger.log("[ ASSETS ] ", assets);

              if (assets.length) {
                assets.forEach(function (asset) {
                  asset.user.groupID = companyId;
                });

                _this.get('api').post("/groups/".concat(invitation.get("companyID"), "/assets"), {
                  dataType: "json",
                  data: {
                    assets: JSON.stringify(assets),
                    signup_url: _environment.default.signup_url,
                    modules: JSON.stringify(company.get('modules'))
                  }
                }).then(function (response) {
                  Ember.Logger.log(response);

                  _this.set("pleaseWaitModal", false);

                  _this.set("feedback", company.get("name") + " was created successfuly.");

                  _this.set("success", true);

                  _this.set("failed", false);

                  _this.resetForm();
                }, function (error) {
                  Ember.Logger.error("Failed to save company assets !", error);

                  _this.set("feedback", company.get("name") + " was created successfuly, but there was an error when saving the company assets.");

                  _this.set("failed", true);

                  _this.set("success", false);

                  _this.get('notifications').error('Failed to save company assets !');
                });
              } else {
                _this.set("pleaseWaitModal", false);

                _this.set("feedback", company.get("name") + " was created successfuly.");

                _this.set("success", true);

                _this.set("failed", false);

                _this.resetForm();

                _this.get('notifications').success('Company created successfully !');
              }
            }, function (error) {
              Ember.Logger.log("[ INVITE ] Invitation Error: ", error);
              invitation.destroyRecord().then(function () {
                company.destroyRecord();
              });

              _this.set("feedback", company.get("name") + " could not be created at this time. Please contact the development team.");

              _this.set("failed", true);

              _this.set("success", false);

              _this.get('notifications').error('Failed to create invitation !');
            });
          }, function (error) {
            Ember.Logger.log("[ INVITE ] Failed to create company : ", error);

            _this.set("success", false);

            company.destroyRecord();

            _this.set("feedback", company.get("name") + " could not be created at this time. Please contact the development team.");

            _this.set("failed", true);

            _this.get('notifications').error('Failed to create company !');
          });
        } else {
          _this.set("errors", errors);
        }
      },
      upload: function upload() {
        var file = $("#csv-file-upload")[0].files[0];
        var reader = new FileReader();

        var _this = this;

        var assets = _this.get("companyAssets");

        var emailOverride = null;

        if (_environment.default.overrideEmail) {
          var time = new Date().getTime();
          emailOverride = 'agj+' + time + '@valgbar.com';
        }

        assets = [];

        if (file) {
          reader.onload = function (event) {
            var rows = _this.get("rows");

            rows = 0;
            csvtojson({
              checkType: false
            }).fromString(event.srcElement.result).on('json', function (data) {
              rows++;

              _this.set("rows", rows);

              if (data["Device SN"].length) {
                var categories = data["Driving License"].toUpperCase().split(",");

                for (var i = 0; i < categories.length; i++) {
                  if (categories[i].indexOf("Categoria ") === -1) {
                    categories[i] = "Categoria " + categories[i];
                  }
                }

                assets.push({
                  device: {
                    sn: data["Device SN"]
                  },
                  vehicle: {
                    make: data.Make,
                    model: data.Model,
                    color: data.Color,
                    year: data.Year != null && data.Year.length ? parseInt(data.Year.substr(0, 4)) : 0,
                    vin: data.VIN,
                    licensePlate: data["License Plate"],
                    registrationDate: new Date(data["Registration Date"]).getTime(),
                    type: data.Type,
                    tyreSize: data["Tyre Size"],
                    seats: data["Number of Seats"] != null && data["Number of Seats"].length ? parseInt(data["Number of Seats"]) : 0,
                    engine: {
                      capacity: data["Engine Capacity"] != null && data["Engine Capacity"].length ? parseInt(data["Engine Capacity"]) : 0,
                      power: data["Engine Power"] != null && data["Engine Power"].length ? parseInt(data["Engine Power"]) : 0
                    },
                    fuel: {
                      type: data["Fuel Type"],
                      capacity: data["Fuel Capacity"] != null && data["Fuel Capacity"].length ? parseInt(data["Fuel Capacity"]) : 0,
                      consumption: {
                        distanceWise: data["Fuel Consumption pr Distance"] != null && data["Fuel Consumption pr Distance"].length ? parseFloat(data["Fuel Consumption pr Distance"].replace(",", ".")) : 0,
                        timeWise: data["Fuel Consumption pr Hour"] != null && data["Fuel Consumption pr Hour"].length ? parseFloat(data["Fuel Consumption pr Hour"].replace(",", ".")) : 0,
                        weightWise: data["Fuel Consumption pr Weight"] != null && data["Fuel Consumption pr Weight"].length ? parseFloat(data["Fuel Consumption pr Weight"].replace(",", ".")) : 0
                      }
                    },
                    payloadCapacity: data["Payload Capacity"] != null && data["Payload Capacity"].length ? parseFloat(data["Payload Capacity"].replace(",", ".")) : 0,
                    status: {
                      odometer: data.Odometer != null && data.Odometer.length ? parseInt(data.Odometer) : 0
                    },
                    hasRelay: data["Has Relay"] != null && data["Has Relay"].length ? true : false
                  },
                  user: {
                    firstName: data["First Name"],
                    lastName: data["Last Name"],
                    email: data["Email"] != null && data["Email"].length ? _environment.default.overrideEmail ? emailOverride : data["Email"] : null,
                    details: {
                      drivingLicense: {
                        categories: categories
                      },
                      locale: _this.get("selectedLocale.code")
                    },
                    phoneNumber: data["Phone Number"],
                    role: "driver",
                    groupID: null
                  }
                });
              }
            }).on("end_parsed", function () {
              _this.set("companyAssets", assets);
            });
          };

          reader.readAsText(file);
        }
      },
      saveAssets: function saveAssets() {
        var assets = this.get("companyAssets");

        if (assets.length) {
          Ember.Logger.log("[ ASSETS ] ", assets);
        }
      }
    },
    resetForm: function resetForm() {
      this.set("newInvitation", {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null
      });
      this.set("newCompany", {
        name: null,
        details: {
          CUI: null,
          J: null,
          address: null,
          adminPhone: null,
          contractDate: null,
          pricePrDevice: null,
          subscriptionFee: null
        },
        settings: {
          locale: 'ro'
        }
      });
      this.set("companyAssets", []);
      this.set("rows", 0);
      $("#csv-file-upload").val('');

      var _this = this;

      Ember.run.later(function () {
        _this.set("success", false);

        _this.set("failed", false);

        _this.set("feedback", null);
      }, 4000);
    },
    newInvitation: {
      firstName: null,
      //"Alex",
      lastName: null,
      //"J",
      email: null,
      //"jgr.alex@live.com",
      phoneNumber: null //"0040734179691"

    },
    newCompany: {
      name: null,
      //"Jucan Grigore Alexandru PFA",
      details: {
        CUI: null,
        //"34522998",
        J: null,
        //"F5/986/2015",
        address: null,
        //"Str. Iza nr. 25",
        adminPhone: null,
        //"0040734179691",
        contractDate: null,
        //"2017-10-03",
        pricePrDevice: null,
        //"200",
        subscriptionFee: null //"100"

      },
      settings: {
        locale: 'ro'
      }
    },
    requiredFields: ["newInvitation.email", "newInvitation.phoneNumber", "newCompany.name", "newCompany.details.CUI", "newCompany.details.address"],
    requiredFieldsLabels: {
      "newInvitation.email": "Admin Email",
      "newInvitation.phoneNumber": "Admin Phone",
      "newCompany.name": "Company Name",
      "newCompany.details.CUI": "Company CUI",
      "newCompany.details.address": "Company Address"
    },
    csvRequiredFields: [],
    companyAssets: [],
    rows: 0,
    pleaseWaitModal: false,
    errors: [],
    success: false,
    failed: false,
    feedback: null
  });

  _exports.default = _default;
});