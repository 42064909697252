define("admin/routes/offers", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    api: Ember.inject.service('api'),
    model: function model() {
      return this.get('api').request('orders/orphans', {}).then(function (response) {
        return Ember.RSVP.resolve(response);
      });
    }
  });

  _exports.default = _default;
});