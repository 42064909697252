define("admin/components/devices-stock-device/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    store: Ember.inject.service(),
    deviceStockEvents: Ember.inject.service('device-stock-events'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('temporaryDevicesStock', this.get('device.devicesStock'));
      this.set('temporaryTransferReason', null);
      this.set('visibleTransferOptions', false);
      this.set('transferDocument', null);
      this.set('transferNote', null);
    },
    matchDevicesStock: function matchDevicesStock(devicesStock, term) {
      Ember.Logger.log(term, devicesStock.get('name'));
      return devicesStock.get('name').toLowerCase().indexOf(term.toLowerCase());
    },
    actions: {
      changeDeviceStock: function changeDeviceStock(devicesStock) {
        if (!this.get('temporaryTransferReason') || devicesStock.get('defaultTransferReason') !== this.get('temporaryTransferReason')) {
          this.set('temporaryTransferReason', devicesStock.get('defaultTransferReason'));
        }

        this.set('temporaryDevicesStock', devicesStock);
      },
      updateDevice: function updateDevice() {
        var _this = this;

        var device = this.get('device'); // Programmatically updating the count in order to avoid requesting all devices for each stock with a computed property on the model
        // A computed property on the model would make unnecessary requests to the devices endpoint for each stock when populating the models in the route.

        var oldDevicesStock = this.get('device.devicesStock');
        device.set('devicesStock', this.get('temporaryDevicesStock'));
        device.set('transfer', {
          document: this.get('transferDocument'),
          reason: this.get('temporaryTransferReason'),
          note: this.get('transferNote')
        });
        device.save().then(function (device) {
          Ember.Logger.log('Device transferred successfully');
          oldDevicesStock.decrementProperty('quantities.totalCount');
          var deviceTypeStats = oldDevicesStock.get('quantities.deviceTypes').find(function (deviceTypeStat) {
            return deviceTypeStat.deviceTypeID == _this.get('device.deviceType.id');
          });
          Ember.set(deviceTypeStats, 'devicesCount', deviceTypeStats.devicesCount - 1);

          _this.get('deviceStockEvents').movedDevice(device.get('devicesStock.id'), device);
        });
      },
      showTransferOptions: function showTransferOptions() {
        this.set('visibleTransferOptions', true);
      },
      hideTransferOptions: function hideTransferOptions() {
        this.set('visibleTransferOptions', false);
      } // toggleCheckDevice() {
      //     this.set('device.checked', !this.get('device.checked'));
      //     this.sendAction("toggleCheckDevice", this.get('device.sn'))
      // }

    }
  });

  _exports.default = _default;
});