define("admin/components/fix-order-product-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h2H2SCzT",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-sm-4 col-lg-4\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"input-group input-groups-sm\"],[7],[0,\"\\n            \"],[6,\"label\"],[9,\"class\",\"input-group-addon\"],[7],[0,\"Device ID\"],[8],[0,\"\\n            \"],[1,[25,\"input\",null,[[\"type\",\"class\",\"readonly\",\"value\"],[\"text\",\"form-control input-sm\",true,[20,[\"item\",\"id\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-sm-4 col-lg-3\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"input-group\"],[7],[0,\"\\n            \"],[6,\"label\"],[9,\"class\",\"input-group-addon\"],[7],[0,\"Device SN\"],[8],[0,\"\\n            \"],[1,[25,\"input\",null,[[\"type\",\"class\",\"value\"],[\"text\",\"form-control input-sm\",[20,[\"item\",\"deviceSN\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-md-4 col-lg-5\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"col-sm-6 col-md-4 col-lg-2\"],[7],[0,\"\\n                \"],[4,\"bs-button\",null,[[\"type\",\"onClick\",\"class\"],[\"primary\",[25,\"action\",[[19,0,[]],\"update\"],null],\"btn-sm\"]],{\"statements\":[[0,\"Update\"]],\"parameters\":[]},null],[0,\"\\n            \"],[8],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"col-sm-6 col-md-8 col-lg-10\"],[7],[0,\"\\n                \"],[1,[18,\"feedback\"],false],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/components/fix-order-product-item/template.hbs"
    }
  });

  _exports.default = _default;
});