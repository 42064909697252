define("admin/controllers/offers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    api: Ember.inject.service('api'),
    session: Ember.inject.service(),
    total: Ember.computed('model.orphans', function () {
      return this.get('model.orphans').reduce(function (total, orphans) {
        total += parseInt(orphans.qty);
        return total;
      }, 0);
    }),
    actions: {
      selectItem: function selectItem(index) {
        Ember.$(".orphan-item").removeClass('active-light');
        Ember.$("#orphan-item-".concat(index)).addClass('active-light');
      }
    }
  });

  _exports.default = _default;
});