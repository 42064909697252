define("admin/models/invoice", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    offer: _emberData.default.belongsTo('offer', {
      allowNull: true
    }),
    company: _emberData.default.belongsTo('company', {
      polymorphic: true,
      async: true
    }),
    potentialCustomer: _emberData.default.belongsTo('potential-customer'),
    type: _emberData.default.attr('string'),
    series: _emberData.default.attr('string'),
    number: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    currency: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    dueDate: _emberData.default.attr('date'),
    details: _emberDataModelFragments.default.fragment('invoice-details'),
    etag: _emberData.default.attr('string'),
    platform: _emberData.default.attr('string'),
    invoicePayments: _emberData.default.hasMany('invoice-payment', {
      async: false,
      inverse: 'invoice'
    }),
    payments: _emberData.default.hasMany('payment', {
      allowNull: true,
      inverse: 'invoices',
      async: false
    }),
    total: Ember.computed('company.settings.VAT', 'amount', function () {
      var amount = this.get('amount');
      var vat = this.get('company.settings.VAT');
      return amount + vat * amount / 100;
    }),
    paidAmount: Ember.computed('invoicePayments.@each.amount', function () {
      return this.get('invoicePayments').reduce(function (total, invoicePayment) {
        return total + parseFloat(invoicePayment.get('amount'));
      }, 0);
    }),
    remainingAmount: Ember.computed('paidAmount', 'amount', function () {
      return this.get('total') - this.get('paidAmount');
    })
  });

  _exports.default = _default;
});