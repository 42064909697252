define("admin/components/devices-stock/component", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    localSettings: Ember.inject.service('local-settings'),
    deviceStockEvents: Ember.inject.service('device-stock-events'),
    temporarySelectedStock: null,
    transferDeviceSN: null,
    selected: Ember.computed('devicesStock.id', 'selectedDevicesStockID', function () {
      var _this2 = this;

      var selected = this.get('devicesStock.id') === this.get('selectedDevicesStockID');

      if (selected) {
        this.set('loadingDevices', true);
        this.get('store').query('device', {
          $where: {
            devices_stock_id: this.get('devicesStock.id')
          }
        }).then(function (devices) {
          _this2.set('devices', devices.toArray());

          _this2.set('loadingDevices', false);
        }).catch(function (error) {
          Ember.Logger.error('Error: ', erorr);
        });
      }

      return selected;
    }),
    deselectedUsers: Ember.computed('devicesStock.managers', 'users', function () {
      var newStockManagers = this.get('devicesStock.managers');
      return this.get('users').filter(function (user) {
        return !newStockManagers.includes(user.get('id'));
      });
    }),
    newStockSelectedManagers: Ember.computed('devicesStock.managers', 'users', function () {
      var newStockManagers = this.get('devicesStock.managers');
      return this.get('users').filter(function (user) {
        return newStockManagers.includes(user.get('id'));
      });
    }),
    usersSearchResults: Ember.computed('searchUsersInput', 'deselectedUsers', function () {
      if (this.get('searchUsersInput')) {
        var searchText = this.get('searchUsersInput').toLowerCase();
        return this.get('deselectedUsers').filter(function (user) {
          var userValues = "".concat(user.get('firstName')).concat(user.get('lastName')).concat(user.get('email'));
          return userValues.toLowerCase().indexOf(searchText) > -1;
        });
      } else {
        return this.get('deselectedUsers');
      }
    }),
    managersSearchResults: Ember.computed('searchManagersInput', 'newStockSelectedManagers', function () {
      if (this.get('searchManagersInput')) {
        var searchText = this.get('searchManagersInput').toLowerCase();
        return this.get('newStockSelectedManagers').filter(function (user) {
          var userValues = "".concat(user.get('firstName')).concat(user.get('lastName')).concat(user.get('email'));
          return userValues.toLowerCase().indexOf(searchText) > -1;
        });
      } else {
        return this.get('newStockSelectedManagers');
      }
    }),
    devicesSearchResults: Ember.computed('searchDeviceInput', 'devices', function () {
      if (this.get('searchDeviceInput')) {
        var searchText = this.get('searchDeviceInput').toLowerCase();
        return this.get('devices').filter(function (device) {
          return device.get('sn').toLowerCase().indexOf(searchText) > -1;
        });
      } else {
        return this.get('devices');
      }
    }),
    selectedDevicesCount: Ember.computed('devices.@each.checked', function () {
      return this.get('devices').reduce(function (checked, device) {
        if (device.get('checked')) {
          checked++;
        }

        return checked;
      }, 0);
    }),
    disabledMoveAction: Ember.computed('selectedDevicesCount', function () {
      if (this.get('selectedDevicesCount')) {
        return false;
      } else {
        return true;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('devices', Ember.A());
      this.set('activeTab', 'devices');
      this.set('isDeletingDevicesStock', false);
      this.set('loadingDevices', true);
      this.resetDeviceTransfer();
    },
    resetDeviceTransfer: function resetDeviceTransfer() {
      this.set('deviceTransfer', {
        deviceSNs: [],
        reason: this.get('devicesStock.defaultTransferReason'),
        devicesStock: this.get('devicesStock'),
        type: 'stock_movement'
      });
    },
    didInsertElement: function didInsertElement() {
      var deviceStockTransferListener = this.deviceStockTransferListener.bind(this);
      this.get('deviceStockEvents').subscribe(this.get('devicesStock.id'), deviceStockTransferListener);
    },
    didDestroyElement: function didDestroyElement() {
      this.get('deviceStockEvents').unsubscribe(this.get('devicesStock.id'));
    },
    deviceStockTransferListener: function deviceStockTransferListener(device) {
      this.get('devices').pushObject(device);
      this.incrementProperty('devicesStock.quantities.totalCount');
      var deviceTypeStats = this.get('devicesStock.quantities.deviceTypes').find(function (deviceTypeStat) {
        return deviceTypeStat.deviceTypeID == device.get('deviceType.id');
      });

      if (!deviceTypeStats) {
        deviceTypeStats = {
          devicesCount: 1,
          deviceTypeID: device.get('deviceType.id')
        };
        this.get('devicesStock.quantities.deviceTypes').push(deviceTypeStats);
      } else {
        Ember.set(deviceTypeStats, 'devicesCount', parseInt(deviceTypeStats.devicesCount) + 1);
      }
    },
    matchDevicesStock: function matchDevicesStock(devicesStock, term) {
      return devicesStock.get('name').toLowerCase().indexOf(term.toLowerCase());
    },
    disableDelete: Ember.computed('temporarySelectedStock.id', 'devicesStock.id', function () {
      return this.get('temporarySelectedStock.id') === this.get('devicesStock.id');
    }),
    actions: {
      updateDevicesStock: function updateDevicesStock() {
        this.get('devicesStock').save().then(function (devicesStock) {
          Ember.Logger.log('Devices stock updated successfully');
        });
      },
      addManager: function addManager(user) {
        Ember.Logger.log('User ID: ', user.get('id'));
        var managers = this.get('devicesStock.managers');
        managers.push(user.get('id'));
        this.set('devicesStock.managers', managers);
        this.notifyPropertyChange('devicesStock.managers');
      },
      removeManager: function removeManager(user) {
        var managers = this.get('devicesStock.managers');
        var userIndex = managers.findIndex(function (manager) {
          return user.get('id') === manager;
        });
        managers.splice(userIndex, 1);
        this.set('devicesStock.managers', managers);
        this.notifyPropertyChange('devicesStock.managers');
      },
      cancelEditDevicesStock: function cancelEditDevicesStock() {
        this.get('devicesStock').rollbackAttributes();
      },
      transferDevice: function transferDevice() {
        var _this3 = this;

        this.get('store').findRecord('device', this.get('transferDeviceSN')).then(function (device) {
          device.set('devicesStock', _this3.get('devicesStock'));
          return device.save();
        }).then(function (device) {
          _this3.get('deviceStockEvents').movedDevice(_this3.get('devicesStock.id'), device); // this.get('devices').pushObject(device);


          _this3.set('transferDeviceSN', null);
        }).catch(function (error) {
          Ember.Logger.error('Error: ', erorr);
        });
      },
      deleteDeviceStock: function deleteDeviceStock() {
        // Prepare the selected value for the picker in case devices nned to be moved from stock before deletion
        if (this.get('devicesStock.devicesCount')) {
          this.set('temporarySelectedStock', this.get('devicesStock'));
        }

        this.set('isDeletingStock', true);
      },
      confirmDelete: function confirmDelete() {
        this.get('devicesStock').destroyRecord().then(function () {
          Ember.Logger.log('Successfully deleted devices stock.');
        });
      },
      cancelDelete: function cancelDelete() {
        this.set('isDeletingStock', false);
      },
      selectDevicesStock: function selectDevicesStock(devicesStock) {
        this.set('temporarySelectedStock', devicesStock);
      },
      batchMoveDevicesAndDeleteStock: function batchMoveDevicesAndDeleteStock() {
        var _this4 = this;

        var devicesPromise = null;

        if (!this.get('devices')) {
          devicesPromise = this.get('store').query('device', {
            $where: {
              devices_stock_id: this.get('devicesStock.id')
            }
          });
        } else {
          devicesPromise = Ember.RSVP.resolve(this.get('devices'));
        } // move all devices from the stock


        devicesPromise.then(function (devices) {
          var deviceMovePromisses = [];
          devices.forEach(function (device) {
            device.set('devicesStock', _this4.get('temporarySelectedStock'));
            deviceMovePromisses.push(device.save());
          });
          return Ember.RSVP.all(deviceMovePromisses);
        }).then(function (results) {
          // finally delete the stock
          return _this4.get('devicesStock').destroyRecord();
        }).then(function (result) {
          // and reset the temporarySelectedStock
          _this4.set('temporarySelectedStock', null);
        }).catch(function (error) {
          Ember.Logger.error(error);
        });
      },
      openTab: function openTab(tabName) {
        this.set('activeTab', tabName);
      },
      loadDevicesCSVFile: function loadDevicesCSVFile(event) {
        var file = event.target.files[0];

        if (file) {
          Ember.Logger.log('Selected file:', file);
          this.set("showConfirmationButton", false);
          this.set("devicesCSVFile", file);
        } else {
          this.$("upload-contract-".concat(this.get('company.id'))).val(null);
          this.set("devicesCSVFile", null);
        }
      },
      uploadDevicesCSVFile: function uploadDevicesCSVFile() {
        var _this = this;

        var url = "".concat(_environment.default.api.host, "/").concat(_environment.default.api.namespace, "/devicesStocks/").concat(this.get('devicesStock.id'), "/csv");
        var idToken = this.get('session.session.authenticated.idToken');
        var fd = new FormData();
        fd.append('devices_csv', this.get('devicesCSVFile'));
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Authorization', "Bearer ".concat(idToken));
        var platform = this.get('localSettings').get('settings.selectedPlatform');
        xhr.setRequestHeader('trk-platform', platform.value);

        xhr.onreadystatechange = function (oEvent) {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              Ember.Logger.log("Upload Response: ", xhr.responseText);
            } else {
              Ember.Logger.log("Upload Error:", xhr.statusText);

              _this.set('uploadProgress', null);
            }
          }
        };

        xhr.upload.onprogress = function (pe) {
          if (pe.lengthComputable) {
            var percentComplete = parseInt(pe.loaded / pe.total * 100);

            _this.set("uploadProgress", percentComplete === 100 ? null : percentComplete);
          }
        };

        xhr.onloadend = function (pe) {
          _this.get('store').query('device', {
            $where: {
              devices_stock_id: _this.get('devicesStock.id')
            }
          }).then(function (devices) {
            _this.set('devices', devices.toArray());

            _this.set('loadingDevices', false);
          });
        };

        xhr.send(fd);
      },
      clearDevicesCSVFile: function clearDevicesCSVFile() {
        this.set("devicesCSVFile", null);
      },
      openStockDevices: function openStockDevices() {
        this.set('isDeletingStock', false);
      },
      toggleCheckAll: function toggleCheckAll() {
        var selectedCount = this.get('selectedDevicesCount');

        if (this.get('devices.length') == selectedCount) {
          this.get('devices').forEach(function (device) {
            device.set('checked', false);
          });
        } else {
          this.get('devices').forEach(function (device) {
            device.set('checked', true);
          });
        }
      },
      changeDevicesStock: function changeDevicesStock(devicesStock) {
        this.set('deviceTransfer.devicesStock', devicesStock);
        this.set('deviceTransfer.reason', devicesStock.get('defaultTransferReason'));
      },
      transfer: function transfer() {
        var _this5 = this;

        var transfer = this.get('deviceTransfer');
        transfer.deviceSNs = this.get('devices').reduce(function (deviceSNs, device) {
          if (device.get('checked')) {
            deviceSNs.push(device.get('sn'));
          }

          return deviceSNs;
        }, []);
        var deviceTransfer = this.get('store').createRecord('device-transfer', transfer);
        deviceTransfer.save().then(function () {
          var newDevicsStock = deviceTransfer.get('devicesStock');

          _this5.get('devices').forEach(function (device) {
            var deviceTypeStats = _this5.get('devicesStock.quantities.deviceTypes').find(function (deviceTypeStat) {
              return deviceTypeStat.deviceTypeID == device.get('deviceType.id');
            });

            if (deviceTypeStats) {
              if (device.get('checked')) {
                // Ember.set(deviceTypeStats, 'devicesCount', parseInt(deviceTypeStats.devicesCount) - 1);
                device.set('devicesStock', newDevicsStock);
                device.set('checked', false);

                _this5.get('deviceStockEvents').movedDevice(newDevicsStock.get('id'), device);
              }
            }
          });

          _this5.resetDeviceTransfer();
        });
      }
    }
  });

  _exports.default = _default;
});