define("admin/controllers/companies", ["exports", "admin/controllers/application", "admin/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _actions;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    api: Ember.inject.service('api'),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    newCompany: {},
    electricians: [],
    // to do. get when open modal
    deleteGroupModal: {
      open: false,
      groupObject: {}
    },
    vehicleDetailsModal: {
      open: false,
      vehicle: {}
    },
    deviceDetailsModal: {
      open: false,
      device: {}
    },
    userDetailsModal: {
      open: false,
      user: {}
    },
    resendInvitationModal: {
      open: false,
      user: {},
      group: {}
    },
    toggleCompanyStatusModal: {
      open: false,
      company: {}
    },
    toggleUsersAccessModal: {
      open: false,
      company: null
    },
    newCompanyModal: {
      open: false
    },
    newConversion: {
      type: null,
      value: null
    },
    conversionTypes: ['gps.longitude', 'gps.latitude', 'gps.altitude', 'gps.course', 'gps.speed', 'gps.satellites', 'io.ignition', 'io.power.external', 'io.power.battery.voltage', 'io.power.battery.current', 'io.can.speed', 'io.can.rpm', 'io.can.acceleration', 'io.can.distance', 'io.can.fuel.used', 'io.can.fuel.level.litres', 'io.can.fuel.level.percent', 'io.can.engine.load', 'io.can.engine.temperature', 'io.can.engine.work_time', 'io.analog.in.1', 'io.analog.in.2', 'io.digital.in.1', 'io.digital.in.2', 'io.digital.in.3', 'io.digital.in.4', 'io.digital.in.5', 'io.digital.in.6', 'io.digital.in.7', 'io.digital.in.8', 'io.digital.out.1', 'io.digital.out.2', 'io.ibutton_id'],
    canFuelLevelValueTypes: [{
      value: 'liters',
      label: 'Liters (L)'
    }, {
      value: 'percentage',
      label: 'Percentage (%)'
    }],
    canFuelUsedParameters: [{
      value: 'io.can.j1708.fuel_used',
      label: 'J1708 Total fuel used'
    }, {
      value: 'io.can.fuel.instant_consumption',
      label: 'Instant consumption',
      disabled: true
    }],
    canFuelConsumptionSources: [{
      value: 'fuel-used',
      label: 'CAN fuel used (default)',
      disabled: true
    }, {
      value: 'fuel-level',
      label: 'CAN fuel level'
    }],
    newCompanyData: null,
    requiredCompanyFields: ["name", "details.CUI", "details.J", "details.address", "details.contractDate", "details.pricePerDevice", "details.subscriptionFee", "details.adminPhone"],
    duplicateCUI: false,
    searchText: "",
    itemsOnPage: 15,
    page: 1,
    vehicleTypes: [{
      value: 'car',
      label: 'Car'
    }, {
      value: 'autoturism',
      label: 'Autoturism'
    }, {
      value: 'trailer',
      label: 'Trailer'
    }, {
      value: 'semi-trailer',
      label: 'Semitrailer'
    }, {
      value: 'bus',
      label: 'Bus'
    }, {
      value: 'minibus',
      label: 'Minibus'
    }, {
      value: 'truck',
      label: 'Truck'
    }, {
      value: 'utility',
      label: 'Utility'
    }, {
      value: 'construction',
      label: 'Construction'
    }, {
      value: 'tractor',
      label: 'Tractor'
    }, {
      value: 'harvester',
      label: 'Harvester'
    }],
    fuelTypes: [{
      value: 'diesel',
      label: 'Diesel'
    }, {
      value: 'gasoline',
      label: 'Gasoline'
    }, {
      value: 'hybrid',
      label: 'Hybrid-petrol'
    }, {
      value: 'electric',
      label: 'Electric'
    }, {
      value: 'hydrogen',
      label: 'Hydrogen'
    }, {
      value: 'propane',
      label: 'Propane'
    }, {
      value: 'methane',
      valabellue: 'Methane'
    }, {
      value: 'ethanol',
      label: 'Ethanol'
    }, {
      value: 'kerosene',
      label: 'Kerosene'
    }, {
      value: 'coal',
      label: 'Coal'
    }, {
      value: 'cng',
      label: 'Compressed Natural Gas (CNG)'
    }],
    newVehicleModal: {
      open: false,
      vehicle: null,
      deviceSN: null,
      company: null
    },
    resetNewVehicleModal: function resetNewVehicleModal() {
      this.set('newVehicleModal', {
        open: false,
        vehicle: {
          type: null,
          make: null,
          model: null,
          year: null,
          vin: null,
          licensePlate: null,
          fuel: {
            type: null,
            capacity: null
          }
        },
        deviceSN: null,
        company: null,
        electrician: null
      });
    },
    sortedGroups: Ember.computed.sort('model.groups', 'sortDefinition'),
    sortDefinition: ['id:asc'],
    isSalesAgent: Ember.computed('currentUser.isSalesAgent', function () {
      return this.get('currentUser.isSalesAgent');
    }),
    searchResults: Ember.computed('sortedGroups', 'searchText', function () {
      var groups = this.get('sortedGroups');

      if (this.get('searchText.length')) {
        this.set('page', 1);
        var searchText = this.get('searchText').toLowerCase();
        return this.get('sortedGroups').filter(function (group) {
          var groupName = group.get('name');
          var groupID = group.get('id');
          return groupName && (groupName.toLowerCase().includes(searchText) || groupID.includes(searchText));
        });
      } else {
        return groups;
      }
    }),
    companiesCount: Ember.computed('searchResults', function () {
      return this.get('searchResults.length');
    }),
    showPagination: Ember.computed("companiesCount", "itemsOnPage", function () {
      return this.get("companiesCount") > this.get("itemsOnPage");
    }),
    paginationGroups: Ember.computed('searchResults', 'page', 'itemsOnPage', function () {
      if (!this.get('showPagination')) {
        return this.get('searchResults');
      } else {
        var startIndex = (this.get('page') - 1) * this.get('itemsOnPage');
        var lastIndex = startIndex + this.get('itemsOnPage');
        return this.get('searchResults').slice(startIndex, lastIndex);
      }
    }),
    resetNewCompanyFormData: function resetNewCompanyFormData() {
      this.set('newCompanyModal.company', {
        name: null,
        details: {
          CUI: null,
          J: null,
          address: null,
          contractDate: new Date(),
          pricePerDevice: null,
          subscriptionFee: null,
          adminPhone: null
        },
        settings: {
          locale: 'ro'
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.resetNewCompanyFormData();
      this.resetNewVehicleModal();
    },
    matchElectrician: function matchElectrician(electrician, term) {
      Ember.Logger.log("Electr", electrician);
      var matchAgainst = electrician.get("firstName") + electrician.get("lastName") + electrician.get("email") + electrician.get("phoneNumber");
      return matchAgainst.toLowerCase().indexOf(term.toLowerCase());
    },
    actions: (_actions = {
      create: function create() {
        var companyData = this.get("newCompanyModal.company");
        var companies = this.get("model.groups");

        var _this = this;

        var companyId = 0;
        companies.forEach(function (item) {
          var id = parseInt(item.get("companyID"));

          if (companyId < id) {
            companyId = id;
          }
        });
        companyId++;
        companyId = String("000000" + companyId).slice(-6); // Padding zeroes to the left of the number to get a fixed length of 6 characters

        var company = this.get("store").createRecord("group", companyData);
        company.set("id", companyId);
        company.set("enabled", true);
        company.save().then(function (response) {
          Ember.Logger.log("[ COMPANIES ] [ CREATE ] [ SUCCESS ] : ", response);

          _this.set("newCompany", {});
        }, function (response) {
          Ember.Logger.log("[ COMPANIES ] [ CREATE ] [ ERROR ] : ", response);
          company.destroyRecord();
        });
      },
      update: function update(groupData, groupComponent, assets, callback) {
        var _this2 = this;

        Ember.Logger.log("Data in controller", groupData.get("details"));
        groupData.save().then(function (response) {
          Ember.Logger.log("[ COMPANIES ] [ UPDATE ] [ SUCCESS ] : ", response);

          if (!assets.length) {
            groupComponent.toggleProperty("collapsedEdit");
            groupComponent.toggleProperty("collapsedHeading");
          }

          _this2.get('notifications').success("Company updated successfully !");

          _this2.get('api').post("/groups/".concat(groupData.get("companyID"), "/assets"), {
            dataType: "json",
            data: {
              assets: JSON.stringify(assets),
              signup_url: _environment.default.signup_url,
              modules: JSON.stringify(groupData.get('modules'))
            }
          }).then(function () {
            groupComponent.toggleProperty("collapsedEdit");
            groupComponent.toggleProperty("collapsedHeading");

            if (callback) {
              callback();
            }

            _this2.get('notifications').success("Company assets updated successfully !");
          }, function (error) {
            Ember.Logger.error("Failed to update company assets !", error);
            groupComponent.errors = response;

            _this2.get('notifications').error('Failed to update company assets !');
          });
        }).catch(function (response) {
          Ember.Logger.log("[ COMPANIES ] [ UPDATE ] [ ERROR ] : ", response);

          _this2.get('notifications').error('Failed to update company !');
        });
      },
      confirmDelete: function confirmDelete() {
        this.toggleProperty("deleteGroupModal.open");
        this.get("deleteGroupModal.groupObject").destroyRecord();
        this.set("deleteGroupModal.groupObject", {});
      },
      showDeleteModal: function showDeleteModal(groupObject) {
        this.toggleProperty("deleteGroupModal.open");
        this.set("deleteGroupModal.groupObject", groupObject);
      },
      getDevices: function getDevices(group, callback) {
        var store = this.get('store');
        store.query("vehicle", {
          $where: {
            company_id: group.get("id")
          }
        }).then(function () {
          store.query("device", {
            $where: {
              group_id: group.get("id")
            }
          });
        }).then(function () {
          if (callback != null) {
            callback();
          }
        }).catch(function (error, test) {
          console.error('Error: ', error, test);
        });
      },
      getDevice: function getDevice(device) {
        this.set("deviceDetailsModal.device", device);
        this.toggleProperty("deviceDetailsModal.open");
      },
      getVehicle: function getVehicle(vehicle) {
        Ember.Logger.log('Vehicle: ', vehicle);
        this.set("vehicleDetailsModal.vehicle", vehicle);
        this.toggleProperty("vehicleDetailsModal.open");
      },
      getUser: function getUser(user) {
        this.set("userDetailsModal.user", user);
        this.toggleProperty("userDetailsModal.open");
      },
      setReinvite: function setReinvite(user, group) {
        this.set("resendInvitationModal.user", user);
        this.set("resendInvitationModal.group", group);
        this.set("resendInvitationModal.open", true);
      },
      reinvite: function reinvite() {
        var user = this.get("resendInvitationModal.user");
        var group = this.get('resendInvitationModal.group');
        var invitation = this.get("store").createRecord("user-invitation", {
          firstName: user.get("firstName"),
          lastName: user.get("lastName"),
          email: user.get("email"),
          groupID: group.get("id"),
          companyID: group.get("id").substring(0, 6),
          role: 'admin',
          signup_url: _environment.default.signup_url
        });

        if (_environment.default.overrideEmail) {
          invitation.set("email", _environment.default.overrideEmail);
        }

        var _this = this;

        invitation.save().then(function () {
          Ember.Logger.log("[REINVITE] SUCCESS");

          _this.set("resendInvitationModal.user", null);

          _this.set("resendInvitationModal.group", null);

          _this.set("resendInvitationModal.open", false);

          _this.get('notifications').success("Invitation resent successfully !");
        }, function (error) {
          Ember.Logger.log("[REINVITE] FAILED ", error);

          _this.get('notifications').error('Failed to resend invitation !');
        });
      },
      getInviteLink: function getInviteLink(user, group) {
        this.get('api').request("/users/".concat(user.get('id'), "/invitation")).then(function (result) {
          prompt('Invitation URL:', result.url);
        });
      },
      addFuelTank: function addFuelTank() {
        Ember.Logger.log("Add fuel tank");
        this.get('vehicleDetailsModal.vehicle.fuel.tanks').createFragment({
          capacity: 0,
          calibration: [{
            level: 0.0,
            voltage: 0.0
          }]
        });
      },
      removeFuelTank: function removeFuelTank(fuelTankIndex) {
        Ember.Logger.log("Remove fuel tank:", fuelTankIndex);
        this.get('vehicleDetailsModal.vehicle.fuel.tanks').removeAt(fuelTankIndex);
      },
      addFuelCalibration: function addFuelCalibration(fuelTankIndex) {
        Ember.Logger.log("Add fuel calibration:", fuelTankIndex);
        this.get('vehicleDetailsModal.vehicle.fuel.tanks').objectAt(fuelTankIndex).get('calibration').createFragment({
          level: 0.0,
          voltage: 0.0
        });
      },
      removeFuelCalibration: function removeFuelCalibration(fuelTankIndex, calibrationIndex) {
        Ember.Logger.log("Remove fuel calibration:", fuelTankIndex, calibrationIndex);
        this.get('vehicleDetailsModal.vehicle.fuel.tanks').objectAt(fuelTankIndex).get('calibration').removeAt(calibrationIndex);
      },
      updateVehicle: function updateVehicle() {
        var _this3 = this;

        Ember.Logger.log("Update vehicle:");
        var veh = this.store.peekRecord('vehicle', this.get('vehicleDetailsModal.vehicle.id'));
        if (veh.get('licensePlate')) veh.set('licensePlate', veh.get('licensePlate').trim());
        veh.save().then(function () {
          _this3.get('notifications').success('Vehicle updated');
        }).catch(function (error) {
          Ember.Logger.error('Failed to update vehicle !', error);

          _this3.get('notifications').error('Could not update vehicle !');
        });
      },
      changeAdminEmail: function changeAdminEmail() {},
      toggleCompanyStatus: function toggleCompanyStatus(company) {
        this.set("toggleCompanyStatusModal.open", true);
        this.set("toggleCompanyStatusModal.company", company);
      },
      confirmToggleCompanyStatus: function confirmToggleCompanyStatus() {
        var _this = this;

        var newStatus = !this.get("toggleCompanyStatusModal.company.enabled");
        this.set("toggleCompanyStatusModal.company.enabled", newStatus);
        this.get("toggleCompanyStatusModal.company").save().then(function (updated) {
          Ember.Logger.log("Updated company status", updated);

          _this.set("toggleCompanyStatusModal.open", false);

          _this.set("toggleCompanyStatusModal.company", null);
        }, function (error) {
          Ember.Logger.log("Error on company status change:", error);
        });
      },
      cancelToggleCompanyStatus: function cancelToggleCompanyStatus() {
        this.set("toggleCompanyStatusModal.open", false);
        this.set("toggleCompanyStatusModal.company", null);
      },
      addSensor: function addSensor() {
        if (Ember.isNone(this.get('vehicleDetailsModal.vehicle.sensors'))) {
          this.set('vehicleDetailsModal.vehicle.sensors', []);
        }

        this.get('vehicleDetailsModal.vehicle.sensors').createFragment();
      },
      removeSensor: function removeSensor(sensor, sensorIndex) {
        Ember.Logger.log("Remove sensor:", sensor, sensorIndex);
        this.get('vehicleDetailsModal.vehicle.sensors').removeAt(sensorIndex);
      },
      addConversion: function addConversion() {
        if (Ember.isNone(this.get('vehicleDetailsModal.vehicle.conversions'))) {
          this.set('vehicleDetailsModal.vehicle.conversions', []);
        }

        this.get('vehicleDetailsModal.vehicle.conversions').pushObject(this.get('store').createFragment('vehicle-conversion', {
          type: this.get('newConversion.type'),
          value: this.get('newConversion.value')
        }));
        this.set('newConversion.type', null);
        this.set('newConversion.value', null);
      },
      removeConversion: function removeConversion(conversion, conversionIndex) {
        Ember.Logger.log("Remove conversion:", conversion, conversionIndex);
        this.get('vehicleDetailsModal.vehicle.conversions').removeAt(conversionIndex);
      }
    }, _defineProperty(_actions, "addConversion", function addConversion() {
      if (Ember.isNone(this.get('vehicleDetailsModal.vehicle.conversions'))) {
        this.set('vehicleDetailsModal.vehicle.conversions', []);
      }

      this.get('vehicleDetailsModal.vehicle.conversions').pushObject(this.get('store').createFragment('vehicle-conversion', {
        type: this.get('newConversion.type'),
        value: this.get('newConversion.value')
      }));
      this.set('newConversion.type', null);
      this.set('newConversion.value', null);
    }), _defineProperty(_actions, "removeConversion", function removeConversion(conversion, conversionIndex) {
      Ember.Logger.log("Remove conversion:", conversion, conversionIndex);
      this.get('vehicleDetailsModal.vehicle.conversions').removeAt(conversionIndex);
    }), _defineProperty(_actions, "setCanFuelLevelValueType", function setCanFuelLevelValueType(valueType) {
      this.set('vehicleDetailsModal.vehicle.settings.can.fuelLevelValueType', valueType.value);
    }), _defineProperty(_actions, "setCanFuelUsedParameter", function setCanFuelUsedParameter(parameter) {
      var value = null;

      if (!Ember.isEmpty(parameter)) {
        value = parameter.value;
      }

      this.set('vehicleDetailsModal.vehicle.settings.can.fuelUsedParameter', value);
    }), _defineProperty(_actions, "setCanFuelConsumptionSource", function setCanFuelConsumptionSource(source) {
      var value = null;

      if (!Ember.isEmpty(source)) {
        value = source.value;
      }

      this.set('vehicleDetailsModal.vehicle.settings.can.fuelConsumptionSource', value);
    }), _defineProperty(_actions, "openNewCompanyModal", function openNewCompanyModal() {
      this.set('newCompanyModal.open', true); // this.notifyPropertyChange('newCompanyModal.open')
    }), _defineProperty(_actions, "saveNewCompany", function saveNewCompany() {
      var _this4 = this;

      var _this = this;

      var formValid = true;
      this.get("requiredCompanyFields").forEach(function (field) {
        var value = _this.get("newCompanyModal.company.".concat(field));

        console.log(field, value);

        if (value == null || value.length === 0) {
          formValid = false;
        }
      });

      if (formValid) {
        var newCompany = _this.get("store").createRecord("group", {
          name: this.get("newCompanyModal.company.name"),
          details: {
            J: _this.get("newCompanyModal.company.details.J"),
            CUI: _this.get("newCompanyModal.company.details.CUI"),
            address: _this.get("newCompanyModal.company.details.address"),
            contractDate: _this.get("newCompanyModal.company.details.contractDate"),
            pricePrDevice: _this.get("newCompanyModal.company.details.pricePerDevice"),
            subscriptionFee: _this.get("newCompanyModal.company.details.subscriptionFee"),
            adminPhone: _this.get("newCompanyModal.company.details.adminPhone")
          },
          enabled: false,
          settings: {
            locale: 'ro'
          },
          modules: this.get("newCompanyModal.company.modules")
        });

        newCompany.save().then(function () {
          _this4.set('newCompanyModal.open', false);

          _this4.resetNewCompanyFormData();
        }).catch(function (error) {
          _this4.set("duplicateCUI", true);

          newCompanyStoreObject.rollbackAttributes();
          console.error(error);

          _this4.set("errorToShow", error.errors);
        });
      } else {
        console.error('Invalid form');
      }
    }), _defineProperty(_actions, "cancelNewCompanyModal", function cancelNewCompanyModal() {
      this.set('newCompanyModal.open', false);
    }), _defineProperty(_actions, "updateDevice", function updateDevice() {
      var _this5 = this;

      Ember.Logger.log("Update device:");
      this.store.peekRecord('device', this.get('deviceDetailsModal.device.sn')).save().then(function () {
        _this5.get('notifications').success('Device updated');
      }).catch(function (error) {
        Ember.Logger.error('Failed to update device!', error);

        _this5.get('notification').error('Could not update device!');
      });
      this.set("deviceDetailsModal.open", false);
    }), _defineProperty(_actions, "selectPage", function selectPage(pageNumber) {
      if (!pageNumber) {
        return false;
      }

      this.set("page", pageNumber);
    }), _defineProperty(_actions, "addVehicle", function addVehicle(company) {
      this.set('newVehicleModal.company', company);
      this.set('newVehicleModal.open', true);
    }), _defineProperty(_actions, "assignElectrician", function assignElectrician(electrician) {
      this.set("newVehicleModal.electrician", electrician);
    }), _defineProperty(_actions, "saveNewVehicle", function saveNewVehicle() {
      var _this6 = this;

      var store = this.get('store');
      var deviceSN = this.get('newVehicleModal.deviceSN');
      var veh = this.get('newVehicleModal.vehicle');
      if (veh.licensePlate) Ember.set(veh, 'licensePlate', veh.licensePlate.trim());
      var vehicle = store.createRecord('vehicle', veh);
      var company = this.get('newVehicleModal.company');
      var electrician = this.get('newVehicleModal.electrician');
      vehicle.set('group', this.get('newVehicleModal.company'));
      vehicle.save().then(function (vehicle) {
        // For some reason querying the device model does not work in this function anymore. Because of this we are loading it using the API service
        return _this6.get('api').request("/devices/".concat(deviceSN)).catch(function (error) {
          return null;
        });
      }).then(function (response) {
        // Push the low level queried device to the store in order to convert to get its model instance
        if (response.device) {
          store.pushPayload({
            device: response.device
          });
          var device = store.peekRecord('device', deviceSN);
          device.set('vehicle', vehicle);
          device.set('group', company);
          device.set('electrician', electrician);
          return device.save();
        } else return null;
      }).then(function (response) {
        _this6.resetNewVehicleModal();
      }).catch(function (error) {
        Ember.Logger.error(error);
      });
    }), _defineProperty(_actions, "toggleUsersAccess", function toggleUsersAccess(group) {
      if (group) {
        this.set('toggleUsersAccessModal.company', group);
        this.set('toggleUsersAccessModal.open', true);
      } else {
        this.set('toggleUsersAccessModal.open', false);
        this.set('toggleUsersAccessModal.company', null);
      }
    }), _defineProperty(_actions, "confirmToggleUsersAccess", function confirmToggleUsersAccess() {
      var _this7 = this;

      var company = this.get('toggleUsersAccessModal.company');
      company.set('usersAccess', !company.get('usersAccess'));
      company.save().then(function (response) {
        _this7.set('toggleUsersAccessModal.open', false);

        _this7.set('toggleUsersAccessModal.company', null);
      });
    }), _actions)
  });

  _exports.default = _default;
});