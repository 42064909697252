define("admin/controllers/packets", ["exports", "flat"], function (_exports, _flat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function abbr(text) {
    return text.toLowerCase().replace(/[._-]/g, ' ').split(' ').map(function (w) {
      return isNaN(w) ? w[0] : w;
    }).join('').toUpperCase();
  }

  var _default = Ember.Controller.extend({
    date: new Date(),
    deviceSN: null,
    packets: [],
    loading: false,
    api: Ember.inject.service(),
    actions: {
      loadPackets: function loadPackets() {
        var _this = this;

        Ember.Logger.debug('Load packets');
        this.set('loading', true);
        this.get('api').request("devices/".concat(this.get('deviceSN'), "/packets"), {
          data: {
            day: this.get('date').getTime()
          },
          dataType: 'json'
        }).then(function (result) {
          Ember.Logger.debug('Got packets', result.length);

          _this.set('packets', result.map(function (packet) {
            return packet.parsed.formatted.map(function (element) {
              var flat = (0, _flat.default)(element, {
                safe: true
              });
              var data = [];

              for (var key in flat) {
                var item = {
                  name: key,
                  abbr: abbr(key),
                  value: flat[key]
                };

                switch (key) {
                  case 'timestamp':
                    item.value = new Date(item.value).toISOString();
                    break;

                  case 'gps.latitude':
                  case 'gps.longitude':
                    item.value = item.value ? item.value.toFixed(6) : undefined;
                    break;
                }

                data.push(item);
              }

              return {
                data: data.reverse()
              };
            });
          }));
        }, function (error) {
          Ember.Logger.error('Failed to get packets !', error);
        }).then(function () {
          _this.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});