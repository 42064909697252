define("admin/controllers/obu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    date: new Date(),
    obuID: null,
    points: [],
    loading: false,
    api: Ember.inject.service(),
    chartOptions: Ember.computed('points.[]', function () {
      var points = this.get('points');
      return {
        chart: {
          height: 350,
          type: 'area',
          animations: {
            enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            },
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        series: [{
          name: 'Points',
          data: points
        }],
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          show: false,
          tickAmount: 1,
          min: 0,
          max: 1
        },
        tooltip: {
          x: {
            show: false,
            format: 'HH:mm:ss'
          },
          custom: function custom() {
            return '';
          }
        }
      };
    }),
    actions: {
      loadChart: function loadChart() {
        var _this = this;

        Ember.Logger.debug('Load chart');
        this.set('loading', true);
        this.get('api').request("obu/".concat(this.get('obuID'), "/data"), {
          data: {
            day: this.get('date').getTime()
          },
          dataType: 'json'
        }).then(function (result) {
          Ember.Logger.debug('Got OBU data', result.length);
          var points = [];
          result.forEach(function (point) {
            points.push({
              x: new Date(point.timestamp).getTime(),
              y: point.speed != null ? 1 : 0
            });
          });

          _this.set('points', points);
        }, function (error) {
          Ember.Logger.error('Failed to get OBU data !', error);
        }).then(function () {
          _this.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});