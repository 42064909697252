define("admin/models/user", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    groupID: _emberData.default.attr("string"),
    companyID: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    role: _emberData.default.attr("string"),
    cnp: _emberData.default.attr('string'),
    avatar: _emberData.default.attr("string"),
    details: _emberDataModelFragments.default.fragment("user-details", {
      allowNull: true
    }),
    acl: null,
    repassword: _emberData.default.attr("string"),
    phoneNumber: _emberData.default.attr("string"),
    group: _emberData.default.belongsTo("group", {
      inverse: "users"
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return "".concat(this.get('firstName'), " ").concat(this.get('lastName'));
    }),
    settings: _emberData.default.attr()
  });

  _exports.default = _default;
});