define("admin/routes/pipeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model() {
      var hash = {
        potentialCustomers: this.get('store').query('potential-customer', {
          $where: {
            archived: false,
            'is null': 'company_id'
          }
        }),
        groupSubscriptions: this.get('store').findAll('group-subscription'),
        deviceTypes: this.get('store').findAll('device-type'),
        serviceTypes: this.get('store').findAll('service-type'),
        payerCompanies: this.get('store').findAll('payer-company')
      };

      if (this.get('session.session.authenticated.profile.role') === 'super-admin') {
        hash.salesAgents = this.get("store").query("user", {
          $where: {
            type: "sales-agent"
          }
        });
      }

      return Ember.RSVP.hash(hash);
    }
  });

  _exports.default = _default;
});