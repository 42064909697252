define("admin/components/vehicle-sensor-calibration/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    calibration: null,
    owner: null,
    tagName: "tr",
    actions: {
      deleteSelf: function deleteSelf() {
        if (this.get("owner")) {
          this.get("owner").removeObject(this.get("calibration"));
        } else {
          this.sendAction("clearCalibration", this.get("calibration"));
        }
      },
      onChangeInputValue: function onChangeInputValue() {
        this.sendAction("updateCalibrationInputValue", this.get('calibrationIndex'), this.get("calibration.inputValue"));
      },
      onChangeOutputValue: function onChangeOutputValue() {
        this.sendAction("updateCalibrationOutputValue", this.get('calibrationIndex'), this.get("calibration.outputValue"));
      }
    }
  });

  _exports.default = _default;
});