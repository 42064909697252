define("admin/utils/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chunk = chunk;

  function chunk(arr, size) {
    var i,
        j = arr.length,
        tempArray = [];

    for (i = 0; i < j; i += size) {
      tempArray.push(arr.slice(i, i + size));
    }

    return tempArray;
  }
});