define("admin/router", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('protected');
    this.route('login');
    this.route('invitation');
    this.route('companies');
    this.route('user');
    this.route('devices');
    this.route('staff');
    this.route('settings');
    this.route('offers');
    this.route('devices-stocks');
    this.route('obu');
    this.route('packets');
    this.route('pipeline');
    this.route('portfolio');
    this.route('orders', function () {});
    this.route('payments');
    this.route('fix-orders');
    this.route('orders-required');
    this.route('subscriptions');
  });
  var _default = Router;
  _exports.default = _default;
});