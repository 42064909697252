define("admin/components/module-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w09EdfbI",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[4,\"x-tree\",null,[[\"model\",\"checkable\",\"recursiveCheck\",\"expandDepth\",\"onCheck\"],[[19,0,[\"tree\"]],true,true,0,[25,\"action\",[[19,0,[]],\"onCheck\"],null]]],{\"statements\":[[0,\"  \"],[1,[19,1,[\"toggle\"]],false],[0,\"\\n  \"],[1,[19,1,[\"checkbox\"]],false],[0,\"\\n  \"],[6,\"span\"],[10,\"title\",[19,1,[\"model\",\"id\"]],null],[7],[1,[19,1,[\"model\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/components/module-picker/template.hbs"
    }
  });

  _exports.default = _default;
});