define("admin/templates/fix-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DRWAhbAb",
    "block": "{\"symbols\":[\"order\",\"product\",\"item\"],\"statements\":[[4,\"each\",[[20,[\"model\",\"orders\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[7],[0,\"[ \"],[1,[25,\"moment-format\",[[19,1,[\"createdAt\"]],\"DD / MM - YYYY\"],null],false],[0,\" ] \"],[1,[19,1,[\"company\",\"name\"]],false],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"panel panel-default\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"panel-body\"],[7],[0,\"\\n\"],[4,\"each\",[[19,1,[\"products\"]]],null,{\"statements\":[[0,\"                \"],[6,\"h5\"],[7],[1,[19,2,[\"deviceType\",\"name\"]],false],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"list-group\"],[7],[0,\"\\n\"],[4,\"each\",[[19,2,[\"items\"]]],null,{\"statements\":[[0,\"                        \"],[1,[25,\"fix-order-product-item\",null,[[\"item\",\"class\"],[[19,3,[]],\"list-group-item\"]]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"                \"],[8],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    No problems found\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/templates/fix-orders.hbs"
    }
  });

  _exports.default = _default;
});