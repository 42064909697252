define("admin/routes/payments", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      },
      searchText: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      var hash = {
        potentialCustomers: this.get('potentialCustomers'),
        groups: this.get('groups'),
        invoices: this.get('invoices'),
        payments: this.get('payments')
      }; // Preventing unnecessary reloads for groups and potential customers

      if (!hash.potentialCustomers) {
        hash.potentialCustomers = this.get('store').query('potential-customer', {
          $where: {
            archived: false,
            'is null': 'company_id'
          }
        }).then(function (customers) {
          _this.set('potentialCustomers', customers);

          return Ember.RSVP.resolve(customers);
        });
      }

      if (!hash.groups) {
        hash.groups = this.get("store").findAll("group").then(function (groups) {
          _this.set('groups', groups);

          return Ember.RSVP.resolve(groups);
        });
      }

      hash.payments = this.get('store').query('payment', {
        $where: {
          '>=': {
            'date': moment(parseInt(params.startDate)).format('YYYY-MM-DD')
          },
          '<=': {
            'date': moment(parseInt(params.endDate)).format('YYYY-MM-DD')
          }
        }
      }).then(function (payments) {
        _this.set('payments', payments);

        return Ember.RSVP.resolve(payments);
      });
      return Ember.RSVP.hash(hash).then(function (model) {
        // Only request from the API the invoices that we have payments for between the selected dates.
        var invoiceIDs = [];
        model.payments.forEach(function (payment) {
          payment.get('paymentInvoices').forEach(function (paymentInvoice) {
            var invoiceID = paymentInvoice.belongsTo('invoice').id();

            if (!invoiceIDs.includes(invoiceID)) {
              invoiceIDs.push(invoiceID);
            }
          });
        });

        if (invoiceIDs.length) {
          return _this.get('store').query('invoice', {
            $where: {
              id: invoiceIDs
            }
          }).then(function (invoices) {
            model.invoices = invoices;

            _this.set('invoices', invoices);

            return Ember.RSVP.resolve(model);
          });
        } else {
          return Ember.RSVP.resolve(model);
        }
      });
    }
  });

  _exports.default = _default;
});