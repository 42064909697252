define("admin/authenticators/tracknamic-admin", ["exports", "ember-simple-auth-auth0/authenticators/auth0-lock", "ember-simple-auth-auth0/utils/create-session-data-object"], function (_exports, _auth0Lock, _createSessionDataObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _auth0Lock.default.extend({
    authenticate: function authenticate(options) {
      var auth0 = this.get("auth0");
      var lock = auth0.getAuth0LockInstance(options);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        lock.on('unrecoverable_error', reject);
        lock.on('authenticated', function (authenticatedData) {
          if (Ember.isEmpty(authenticatedData)) {
            return reject(new Error('Missing authenticated data'));
          }

          resolve((0, _createSessionDataObject.default)(authenticatedData.idTokenPayload, authenticatedData));
        });
        lock.show();
      }).then(function (sessionData) {
        Ember.Logger.debug("Authenticate sessionData:", sessionData);

        if (['super-admin', 'sales-agent', 'electrician', 'financial-officer', 'support-engineer', 'operational-manager'].indexOf(Ember.get(sessionData, 'profile.role')) === -1) {
          throw new Error('You are not authorized to perform this action');
        } else {}

        lock.hide();
        return sessionData;
      });
    }
  });

  _exports.default = _default;
});