define("admin/components/payer-company-contact/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contactTypeOptions: [{
      label: 'Person',
      value: 'person'
    }, {
      label: 'Department',
      value: 'department'
    }],
    contactDetailTypeOptions: [{
      label: 'Position',
      value: 'position'
    }, {
      label: 'Email',
      value: 'email'
    }, {
      label: 'Phone',
      value: 'phone'
    }],
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('contact.type')) {
        this.set('contact.type', 'person');
      }

      this.resetNewContactDetail();

      if (this.get('contact.isNew')) {
        this.set('editing', true);
      } else {
        this.set('editings', false);
      }
    },
    resetNewContactDetail: function resetNewContactDetail() {
      this.set('newDetail', Ember.Object.create({
        type: null,
        value: null
      }));
    },
    preventAddNew: Ember.computed('newDetail.value', function () {
      var newValue = this.get('newDetail.value');
      return !(newValue && newValue.length);
    }),
    preventValueNew: Ember.computed('newDetail.type', function () {
      return this.get('newDetail.type') == null;
    }),
    // name: {
    //     first: null,
    //     last: null,
    //     department: null
    // },
    // type: 'person',
    preventContact: Ember.computed('contact.name.first', 'contact.name.last', 'contact.name.department', 'contact.type', function () {
      var type = this.get('contact.type');
      var firstName = this.get('contact.name.first');
      var lastName = this.get('contact.name.last');
      var department = this.get('contact.name.department');

      if (type) {
        if (type == 'person' && !(firstName || lastName)) return true;
        if (type == 'department' && !department) return true;
      } else {
        return true;
      }

      return false;
    }),
    actions: {
      selectContactType: function selectContactType(value) {
        this.get('contact').set('type', value);
      },
      selectContactDetailType: function selectContactDetailType(contactDetail, _ref) {
        var value = _ref.target.value;
        contactDetail.set('type', value);
      },
      addContactNewDetail: function addContactNewDetail() {
        this.get('contact.details').pushObject(Ember.Object.create({
          type: this.get('newDetail.type'),
          value: this.get('newDetail.value')
        }));
        this.resetNewContactDetail();
      },
      removeContactDetail: function removeContactDetail(detail) {
        this.get('contact.details').removeObject(detail);
      },
      setContact: function setContact() {
        if (this.get('contact.isNew')) {
          this.sendAction('addContact');
        } else {
          this.set('editing', false);
        }
      },
      removeContact: function removeContact() {
        this.sendAction('removeContact', this.get('contact'));
      },
      edit: function edit() {
        this.set('editing', true);
      },
      cancel: function cancel() {
        this.set('editing', false);
      }
    }
  });

  _exports.default = _default;
});