define("admin/components/subscription-element/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('expanded', false);
    },
    billingInterval: Ember.computed('company.subscription.recurringInterval', function () {
      Ember.Logger.log(this.get('company.subscription.recurringInterval'));
      return {
        1: 'Monthly',
        6: 'Biannual',
        12: 'Yearly'
      }[this.get('company.subscription.recurringInterval')];
    })
  });

  _exports.default = _default;
});