define("admin/serializers/order-product-item", ["exports", "admin/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForRelationship: function keyForRelationship(key, relationship) {
      if (key === 'device') return 'deviceSN';

      if (relationship === 'belongsTo' || relationship === 'hasMany') {
        return "".concat(key, "ID");
      }
    }
  });

  _exports.default = _default;
});