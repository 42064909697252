define("admin/models/vehicle-fuel-consumption", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    distanceWise: _emberData.default.attr("number"),
    timeWise: _emberData.default.attr("number"),
    weightWise: _emberData.default.attr("number")
  });

  _exports.default = _default;
});