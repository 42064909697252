define("admin/models/user-invitation", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    groupID: _emberData.default.attr("string"),
    companyID: _emberData.default.attr("string"),
    phoneNumber: _emberData.default.attr("string"),
    signup_url: _emberData.default.attr("string", {
      allowNull: true
    }),
    details: _emberDataModelFragments.default.fragment("user-details", {
      allowNull: true
    }),
    role: _emberData.default.attr("string", {
      defaultValue: null
    })
  });

  _exports.default = _default;
});