define("admin/components/order-installation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['row'],
    installationStatuses: [{
      value: 'preparing',
      label: 'Preparing'
    }, {
      value: 'pending',
      label: 'Pending'
    }, {
      value: 'in-progress',
      label: 'In progress'
    }, {
      value: 'completed',
      label: 'Completed'
    }],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('editing', false);

      if (this.get('order.status')) {
        this.set('selectedOrderStatus', this.get('order.status'));
      }

      if (this.get('order.installation.status')) {
        this.set('selectedInstallationStatus', this.get('order.installation.status'));
      } else {
        this.set('selectedInstallationStatus', 'pending');
      }

      this.set('idx', this.get('index') + 1);
      this.resetNewTask();
      var electricians = this.get('store').peekAll('user').filter(function (user) {
        return user.get('role') === 'electrician';
      });
      this.set('electricians', electricians);
      this.set('isAddingTask', false);
    },
    saveInstallation: function saveInstallation() {
      var installation = this.get('order.installation');
      installation.set('status', this.get('selectedInstallationStatus')); // Unfortunately there is no consensus for the serializers extending EmbeddedRecordsMixin on how to save relationships, so we need to use this:

      return installation.content._internalModel.save(); // instead of simply using .save(). Method .save() apparently is not added to the model on components of an aggregated model that uses EmbeddedRecordsMixin in its serializer.
      // return shipping.save();
    },
    resetNewTask: function resetNewTask() {
      var contact = this.get('order.company.details.fleetManager');
      this.set('newTask', this.get('store').createRecord('installationTask', {
        orderInstallation: this.get('order.installation'),
        company: this.get('order.company'),
        type: 'installation',
        scheduledAt: moment(),
        devices: [],
        installationData: [],
        status: 'pending',
        address: this.get('order.installation.location'),
        // Is the fleet manager ok as a contact person?
        contactPerson: contact.get('fullName') + ', Phone: ' + contact.get('phone') + ', Email: ' + contact.get('email'),
        description: null,
        notes: null,
        startedAt: null,
        finishedAt: null,
        electricians: []
      }));
    },
    actions: {
      modify: function modify() {
        var _this = this;

        this.get('store').query('installation-task', {
          $where: {
            orderInstallationID: this.get('order.installation.id')
          }
        }).then(function (installationTasks) {
          _this.toggleProperty('editing');
        });
      },
      save: function save() {
        var _this2 = this;

        // Update the order in case its status has been modified
        if (this.get('order.status') !== this.get('selectedOrderStatus')) {
          this.set('order.status', this.get('selectedOrderStatus'));
          this.get('order').save().then(function (order) {
            _this2.saveInstallation().then(function () {
              _this2.set('editing', false);

              if (_this2.get('finishOrder')) {
                _this2.sendAction('finishOrder', order);
              }
            });
          });
        } else {
          this.saveInstallation().then(function () {
            _this2.set('editing', false);
          });
        }
      },
      addInstallationTask: function addInstallationTask() {
        this.set('isAddingTask', true);
      },
      cancelAddInstallationTask: function cancelAddInstallationTask() {
        this.get('newTask').unloadRecord();
        this.resetNewTask();
        this.set('isAddingTask', false);
      },
      copyToClipboard: function copyToClipboard(sn) {
        var el = document.createElement('textarea');
        el.value = sn;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    }
  });

  _exports.default = _default;
});