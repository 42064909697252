define("admin/models/vehicle-fuel", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    type: _emberData.default.attr("string"),
    capacity: _emberData.default.attr("number"),
    consumption: _emberDataModelFragments.default.fragment("vehicle-fuel-consumption", {
      allowNull: true
    }),
    tanks: _emberDataModelFragments.default.fragmentArray("vehicle-fuel-tank", {
      allowNull: true,
      defaultValue: []
    })
  });

  _exports.default = _default;
});