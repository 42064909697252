define("admin/services/device-stock-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    subscribe: function subscribe(devicesStockID, callback) {
      this.on("".concat(devicesStockID, ":moved-device"), callback);
    },
    unsubscribe: function unsubscribe(devicesStockID) {
      this.off("".concat(devicesStockID, ":moved-device"));
    },
    movedDevice: function movedDevice(devicesStockID, device) {
      this.trigger("".concat(devicesStockID, ":moved-device"), device);
    }
  });

  _exports.default = _default;
});