define("admin/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    role: Ember.computed("session.session.authenticated.profile.role", function () {
      Ember.Logger.log('User profile: ', this.get('session.session.authenticated.profile'));
      return this.get('session.session.authenticated.profile.role');
    }),
    isSuperAdmin: Ember.computed("role", function () {
      return this.get('role') === 'super-admin';
    }),
    isSalesAgent: Ember.computed("role", function () {
      return this.get('role') === 'sales-agent';
    }),
    isOperationalManager: Ember.computed("role", function () {
      return this.get('role') === 'operational-manager';
    }),
    isSupportEngineer: Ember.computed("role", function () {
      return this.get('role') === 'support-engineer';
    }),
    isElectrician: Ember.computed("role", function () {
      return this.get('role') === 'electrician';
    }),
    isFinancialOfficer: Ember.computed('role', function () {
      return this.get('role') === 'financial-officer';
    }),
    user: Ember.computed('session.session.isAuthenticated', 'session.session.content.authenticated.profile', function () {
      return this.get('session.session.content.authenticated.profile');
    })
  });

  _exports.default = _default;
});