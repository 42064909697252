define("admin/components/order-installation-task/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['list-group-item'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var selectedElectricians = this.get('installationTask.electricians').map(function (electricianID) {
        return _this.get('electricians').findBy('id', electricianID);
      });
      this.set('selectedElectricians', selectedElectricians);
      this.get('electricians').forEach(function (elec) {
        Ember.Logger.log(elec);
      });
      this.set('deleteProductItems', Ember.A([]));
    },
    actions: {
      selectScheduledAt: function selectScheduledAt(datetime) {
        this.set('installationTask.scheduledAt', datetime);
      },
      toggleItem: function toggleItem(item) {
        var found = this.get('installationTask.productItems').find(function (productItem) {
          return productItem.get('device.sn') === item.get('device.sn');
        });

        if (found) {
          item.set('installationTask', null);
          this.get('deleteProductItems').pushObject(item);
        } else {
          item.set('installationTask', this.get('installationTask'));
          this.get('deleteProductItems').removeObject(item);
        }
      },
      save: function save() {
        var _this2 = this;

        var callback = null;

        if (this.get('installationTask.isNew')) {
          callback = this.get('newTaskCallback');
        }

        this.set('installationTask.electricians', this.get('selectedElectricians').map(function (electrician) {
          return electrician.get('id');
        }));
        var deviceSNs = this.get('installationTask.productItems').map(function (item) {
          return item.get('device.sn');
        });
        this.set('installationTask.devices', deviceSNs);
        return this.get('installationTask').save().then(function (installationTask) {
          var promises = [];
          installationTask.get('productItems').forEach(function (item) {
            promises.push(item.save());
          });

          if (_this2.get('deleteProductItems.length')) {
            _this2.get('deleteProductItems').forEach(function (item) {
              promises.push(item.save());
            });
          }

          return Ember.RSVP.all(promises);
        }).then(function (result) {
          Ember.Logger.log('Successfully updated installation task product items');
          Ember.Logger.log(result);

          if (callback) {
            callback();
          }
        }).catch(function (error) {
          Ember.Logger.error(error);
        });
      }
    }
  });

  _exports.default = _default;
});