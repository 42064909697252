define("admin/routes/application", ["exports", "ember-simple-auth-auth0/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    actions: {
      login: function login() {
        var lockOptions = {
          authParams: {
            scope: 'openid'
          }
        };
        this.get('session').authenticate('authenticator:tracknamic-admin', lockOptions);
      },
      logout: function logout() {
        this.get('session').invalidate();
      }
    },
    sessionAuthenticated: function sessionAuthenticated(transition) {
      var roleLandingPages = {
        'electrician': 'devices',
        'super-admin': 'pipeline',
        'financial-officer': 'portfolio',
        'sales-agent': 'pipeline',
        'support-engineer': 'packets',
        'operational-manager': 'devices-stocks'
      };
      var role = this.get('currentUser.role');
      var landingPage = roleLandingPages[role];
      this.replaceWith(landingPage);
    }
  });

  _exports.default = _default;
});