define("admin/components/service-type-edit/component", ["exports", "admin/mixins/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validations.default, {
    classNames: ["list-group-item from-horizontal"],
    tagName: "a",
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('serviceType.isNew')) {
        this.set('isNewService', true);
        this.set('editing', true);
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        var serviceTypeValidations = this.validateServiceType(this.get('serviceType'));
        this.set('serviceTypeValidations', serviceTypeValidations);
        this.set('saveServiceClicked', true); // if (serviceTypeValidations.allValid) {

        this.get('serviceType').save().then(function () {
          _this.set('editing', false);

          if (_this.get('isNewService')) {
            _this.cancelNewService();
          }
        }).catch(function () {
          _this.get('serviceType').rollbackAttributes();

          if (_this.get('isNewService')) {
            _this.cancelNewService();
          }
        }); // }
      },
      cancel: function cancel() {
        if (this.get('isNewService')) {
          this.cancelNewService();
        }

        this.get('serviceType').rollbackAttributes();
        this.set('editing', false);
      },
      delete: function _delete() {
        this.get('serviceType').destroyRecord().then(function () {
          Ember.Logger.log('Service type deleted');
        }).catch(function (err) {
          return Ember.Logger.log('There was an error while deleting the service type', err);
        });
      }
    }
  });

  _exports.default = _default;
});