define("admin/models/payer-company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    TIN: _emberData.default.attr('string'),
    VAT: _emberData.default.attr('number'),
    country: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    contacts: _emberData.default.attr(),
    details: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    updatedAt: _emberData.default.attr(),
    companies: _emberData.default.hasMany('group', {
      async: true
    }),
    subscriptions: _emberData.default.hasMany('subscription-statistic'),
    stats: Ember.computed('subscriptions', function () {
      return this.get('subscriptions').reduce(function (stats, company) {
        stats.subscriptions++;
        stats.inUseDevicesQty += company.get('inUseDevicesQty');
        stats.subscriptionDevicesQty += company.get('subscriptionDevicesQty');
        stats.diff += company.get('diff') ? 1 : 0;
        stats.diffLicenses += company.get('diffLicenses') ? 1 : 0;
        stats.orderDevices += company.get('orderDevices').reduce(function (sum, _ref) {
          var qty = _ref.qty;
          return sum + qty;
        }, 0);
        stats.devices += company.get('devices').reduce(function (sum, _ref2) {
          var qty = _ref2.qty;
          return sum + qty;
        }, 0);
        stats.subscriptionLicenses += company.get('subscription.licenses');
        return stats;
      }, {
        inUseDevicesQty: 0,
        subscriptionDevicesQty: 0,
        diff: 0,
        diffLicenses: 0,
        subscriptionLicenses: 0,
        devices: 0,
        orderDevices: 0
      });
    })
  });

  _exports.default = _default;
});