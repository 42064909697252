define("admin/mixins/validations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * @param {*} validationObject // POJO Object for validations
     * @param {*} deviceType //ember data object
     */
    validateDeviceType: function validateDeviceType(
    /*validationObject, */
    deviceType) {
      var validationObject = {}; //validate name

      validationObject['name'] = this.isNotEmpty(deviceType.get('name')); // validate min price

      validationObject['priceMin'] = this.isSmallerThan(deviceType.get('price.min'), deviceType.get('price.max')); // validate max price

      validationObject['priceMax'] = this.isGreaterThan(deviceType.get('price.max'), deviceType.get('price.min')); // validate min monthlyFee

      validationObject['monthlyFeeMin'] = this.isSmallerThan(deviceType.get('monthlyFee.min'), deviceType.get('monthlyFee.max')); // validate max monthlyFee

      validationObject['monthlyFeeMax'] = this.isGreaterThan(deviceType.get('monthlyFee.max'), deviceType.get('monthlyFee.min')); //allValid check

      var validateAll = true;

      for (var propertyName in validationObject) {
        if (propertyName != 'allValid' && !validationObject[propertyName].valid) {
          validateAll = false;
        }
      }

      validationObject['allValid'] = validateAll;
      return validationObject;
    },
    validateServiceType: function validateServiceType(
    /*validationObject, */
    serviceType) {
      var validationObject = {}; //validate name

      validationObject['name'] = this.isNotEmpty(serviceType.get('name')); // validate min monthlyFee

      validationObject['monthlyFee'] = this.isGreaterThanZero(serviceType.get('monthlyFee'));
      validationObject['yearlyFee'] = this.isGreaterThanZero(serviceType.get('yearlyFee')); //allValid check

      var validateAll = true;

      for (var propertyName in validationObject) {
        if (propertyName != 'allValid' && !validationObject[propertyName].valid) {
          validateAll = false;
        }
      }

      validationObject['allValid'] = validateAll;
      return validationObject;
    },
    isNotEmpty: function isNotEmpty(string) {
      if (string || string == 0) {
        return {
          valid: true,
          message: ''
        };
      } else {
        return {
          valid: false,
          message: 'The field is empty'
        };
      }
    },
    isSmallerThan: function isSmallerThan(a, b) {
      var aIsNotEmpty = this.isNotEmpty(a);

      if (!aIsNotEmpty.valid) {
        return aIsNotEmpty;
      }

      if (isNaN(a)) {
        return {
          valid: false,
          message: 'The value should be a number.'
        };
      }

      if (Number(a) < Number(b) && !isNaN(b)) {
        return {
          valid: true,
          message: ''
        };
      } else {
        return {
          valid: false,
          message: 'The value of min is greather or equal than value of max.'
        };
      }
    },
    isGreaterThan: function isGreaterThan(a, b) {
      var aIsNotEmpty = this.isNotEmpty(a);

      if (!aIsNotEmpty.valid) {
        return aIsNotEmpty;
      }

      if (isNaN(a)) {
        return {
          valid: false,
          message: 'The value should be a number.'
        };
      }

      if (Number(a) > Number(b) && !isNaN(b)) {
        return {
          valid: true,
          message: ''
        };
      } else {
        return {
          valid: false,
          message: 'The value of max is smaller or equal than value of min.'
        };
      }
    },
    isGreaterThanZero: function isGreaterThanZero(a) {
      var aIsNotEmpty = this.isNotEmpty(a);

      if (!aIsNotEmpty.valid) {
        return aIsNotEmpty;
      }

      if (isNaN(a)) {
        return {
          valid: false,
          message: 'The value should be a number.'
        };
      }

      if (Number(a) > 0) {
        return {
          valid: true,
          message: ''
        };
      } else {
        return {
          valid: false,
          message: 'The value is not greater than zero.'
        };
      }
    }
  });

  _exports.default = _default;
});