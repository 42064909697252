define("admin/models/potential-customer", ["exports", "ember-data", "ember-data-model-fragments", "admin/models/company"], function (_exports, _emberData, _emberDataModelFragments, _company) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _company.default.extend({
    // name:       DS.attr( 'string' ),
    // details:    MF.fragment( "group-details", { defaultValue: { CUI: null, J: null, address: null, contractDate: null, pricePrDevice: null, subscriptionFee: null, adminPhone: null } } ),
    offers: _emberData.default.hasMany("offer", {
      async: true,
      inverse: "company"
    }),
    // modules:    DS.attr(),
    // salesAgent: DS.belongsTo('user'),
    // salesAgentID: DS.attr('string'),
    archived: _emberData.default.attr('boolean'),
    settings: _emberDataModelFragments.default.fragment("group-settings"),
    // companyID:  DS.attr('string')
    type: 'potential-customer',
    platform: _emberData.default.attr('string')
  });

  _exports.default = _default;
});