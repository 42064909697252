define("admin/components/edit-payer-company/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames: ['list-group-item'],
    init: function init() {
      this._super.apply(this, arguments);

      this.resetNewContact();

      if (this.get('payerCompany.isNew')) {
        this.set("editing", true);
      }
    },
    taxOptions: [{
      label: "Normala",
      value: 19
    }, {
      label: "SDD",
      value: 0
    }],
    resetNewContact: function resetNewContact() {
      this.set('newContact', Ember.Object.create({
        name: {
          first: null,
          last: null,
          department: null
        },
        type: 'person',
        details: Ember.A(),
        isNew: true
      }));
    },
    preventSave: Ember.computed('payerCompany.name', 'payerCompany.TIN', 'payerCompany.country', function () {
      var _this = this;

      var prevent = false;
      ['payerCompany.name', 'payerCompany.TIN', 'payerCompany.country'].forEach(function (field) {
        if (!_this.get(field)) {
          prevent = true;
        }
      });
      return prevent;
    }),
    actions: {
      edit: function edit() {
        this.set('editing', true);
      },
      save: function save() {
        var _this2 = this;

        var sendClose = this.get('payerCompany.isNew');
        this.get('payerCompany').save().then(function (response) {
          if (sendClose) {
            _this2.sendAction('cancel');
          } else {
            _this2.set('editing', false);
          }
        });
      },
      selectVAT: function selectVAT(value) {
        Ember.Logger.log(value);
        this.set('payerCompany.VAT', value);
      },
      cancel: function cancel() {
        if (this.get('payerCompany.isNew')) {
          this.sendAction('cancel');
        } else {
          this.set('editing', false);
        }
      },
      addContact: function addContact() {
        var newContact = this.get('newContact');
        Ember.Logger.log('Payer Company Contacts', this.get('payerCompany.contacts'));
        this.get('payerCompany.contacts').pushObject(Ember.Object.create({
          name: {
            first: newContact.get('name.first'),
            last: newContact.get('name.last'),
            department: newContact.get('name.department')
          },
          type: newContact.get('type'),
          details: newContact.get('details')
        }));
        this.resetNewContact();
      },
      removeContact: function removeContact(contact) {
        this.get('payerCompany.contacts').removeObject(contact);
      },
      removePayerCompany: function removePayerCompany() {
        this.sendAction('removePayerCompany', this.get('payerCompany'));
      }
    }
  });

  _exports.default = _default;
});