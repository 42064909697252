define("admin/models/order-product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    order: _emberData.default.belongsTo('order', {
      inverse: 'products'
    }),
    items: _emberData.default.hasMany('order-product-item', {
      inverse: 'orderProduct'
    }),
    deviceType: _emberData.default.belongsTo('device-type'),
    serviceType: _emberData.default.belongsTo('service-type'),
    quantity: _emberData.default.attr('number'),
    custody: _emberData.default.attr('boolean'),
    unitPrice: _emberData.default.attr('number'),
    unitSetupFee: _emberData.default.attr('number'),
    serviceFee: _emberData.default.attr('number'),
    custodyFee: _emberData.default.attr('number'),
    isValid: _emberData.default.attr('boolean', {
      allowNull: true
    })
  });

  _exports.default = _default;
});