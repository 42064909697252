define("admin/models/offer", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    company: _emberData.default.belongsTo('company', {
      polymorphic: true,
      async: true
    }),
    details: _emberDataModelFragments.default.fragmentArray('offer-details'),
    oldDetails: _emberDataModelFragments.default.fragmentArray('offer-details-old', {
      allowNull: true
    }),
    salesAgentID: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    approved: _emberData.default.attr('boolean'),
    invoiceID: _emberData.default.attr('string'),
    invoice: _emberData.default.belongsTo('invoice', {
      allowNull: true,
      async: true
    }),
    documentType: _emberData.default.attr('string'),
    documentNumber: _emberData.default.attr('number'),
    documentDate: _emberData.default.attr('date', {
      allowNull: true
    }),
    documentFileName: _emberData.default.attr('string'),
    demo: _emberData.default.attr('', {
      allowNull: true
    }),
    order: _emberData.default.belongsTo('order', {
      async: true,
      inverse: 'offer',
      allowNull: true
    }),
    date: Ember.computed('createdAt', function () {
      if (!this.get("createdAt")) return null;
      return moment(this.get("createdAt")).format("DD/MM - YYYY");
    }),
    approvedDate: Ember.computed('documentDate', function () {
      if (!this.get("documentDate")) return null;
      return moment(this.get("documentDate")).format("DD/MM - YYYY");
    }),
    platform: _emberData.default.attr('string'),
    archived: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});