define("admin/components/fuel-level-chart/component", ["exports", "apexcharts"], function (_exports, _apexcharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dc-chart'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var options = this.get('options');
      var data = this.get('data');

      if (!data) {
        return null;
      }

      var can = {
        name: "CAN",
        data: []
      };
      var sensor1 = {
        name: "Sensor 1",
        data: []
      };
      var sensor2 = {
        name: "Sensor 2",
        data: []
      };
      data.forEach(function (item) {
        if (item.canFuelLevel || item.canFuelLevel === 0) {
          can.data.push([item.timestamp, item.canFuelLevel]);
        }

        if (item.fuelLevels && item.fuelLevels.length) {
          if (item.fuelLevels[0] || item.fuelLevels[0] === 0) {
            sensor1.data.push([item.timestamp, item.fuelLevels[0]]);
          }

          if (item.fuelLevels.length > 1) {
            if (item.fuelLevels[1] || item.fuelLevels[1] === 0) {
              sensor2.data.push([item.timestamp, item.fuelLevels[1]]);
            }
          }
        }
      });

      if (can.data.length) {
        options.series.push(can);
      }

      if (sensor1.data.length) {
        options.series.push(sensor1);
      }

      if (sensor2.data.length) {
        options.series.push(sensor2);
      }

      if (!options.series.length) {
        this.set('noData', true);
        return;
      }

      if (options.series.length > 1) {
        options.tooltip.marker.show = true;
      }

      var chart = new _apexcharts.default(this.get('element'), options);
      this.set('chart', chart);
      chart.render();
    },
    init: function init() {
      this._super.apply(this, arguments);

      var options = {
        chart: {
          height: 250,
          width: "100%",
          type: 'line',
          toolbar: {
            show: false
          } // defaultLocale: i18n.get('locale'),
          // locales: [
          //     {
          //         name: 'en',
          //         options: {
          //             months: [i18n.t('months.january').string, i18n.t('months.february').string, i18n.t('months.march').string, i18n.t('months.april').string, i18n.t('months.may').string, i18n.t('months.june').string, i18n.t('months.july').string, i18n.t('months.august').string, i18n.t('months.september').string, i18n.t('months.october').string, i18n.t('months.november').string, i18n.t('months.december').string],
          //             shortMonths: [i18n.t('shortMonths.january').string, i18n.t('shortMonths.february').string, i18n.t('shortMonths.march').string, i18n.t('shortMonths.april').string, i18n.t('shortMonths.may').string, i18n.t('shortMonths.june').string, i18n.t('shortMonths.july').string, i18n.t('shortMonths.august').string, i18n.t('shortMonths.september').string, i18n.t('shortMonths.october').string, i18n.t('shortMonths.november').string, i18n.t('shortMonths.december').string],
          //             days: [i18n.t('weekDays.sunday').string, i18n.t('weekDays.monday').string, i18n.t('weekDays.tuesday').string, i18n.t('weekDays.wednesday').string, i18n.t('weekDays.thursday').string, i18n.t('weekDays.friday').string, i18n.t('weekDays.saturday').string],
          //             shortDays: [i18n.t('shortDays.sunday').string, i18n.t('shortDays.monday').string, i18n.t('shortDays.tuesday').string, i18n.t('shortDays.wednesday').string, i18n.t('shortDays.thursday').string, i18n.t('shortDays.friday').string, i18n.t('shortDays.saturday').string],
          //             toolbar: {
          //                 exportToSVG: i18n.t('exportSvg').string,
          //                 exportToPNG: i18n.t('exportPng').string,
          //                 selection: i18n.t('selection').string,
          //                 selectionZoom: i18n.t('selectionZoom').string,
          //                 zoomIn: i18n.t('zoomIn').string,
          //                 zoomOut: i18n.t('zoomOut').string,
          //                 pan: i18n.t('pan').string,
          //                 reset: i18n.t('reset').string,
          //                 menu: i18n.t('menu').string,
          //             },
          //         }
          //     },
          //     {
          //         name: 'ro',
          //         options: {
          //             months: [i18n.t('months.january').string, i18n.t('months.february').string, i18n.t('months.march').string, i18n.t('months.april').string, i18n.t('months.may').string, i18n.t('months.june').string, i18n.t('months.july').string, i18n.t('months.august').string, i18n.t('months.september').string, i18n.t('months.october').string, i18n.t('months.november').string, i18n.t('months.december').string],
          //             shortMonths: [i18n.t('shortMonths.january').string, i18n.t('shortMonths.february').string, i18n.t('shortMonths.march').string, i18n.t('shortMonths.april').string, i18n.t('shortMonths.may').string, i18n.t('shortMonths.june').string, i18n.t('shortMonths.july').string, i18n.t('shortMonths.august').string, i18n.t('shortMonths.september').string, i18n.t('shortMonths.october').string, i18n.t('shortMonths.november').string, i18n.t('shortMonths.december').string],
          //             days: [i18n.t('weekDays.sunday').string, i18n.t('weekDays.monday').string, i18n.t('weekDays.tuesday').string, i18n.t('weekDays.wednesday').string, i18n.t('weekDays.thursday').string, i18n.t('weekDays.friday').string, i18n.t('weekDays.saturday').string],
          //             shortDays: [i18n.t('shortDays.sunday').string, i18n.t('shortDays.monday').string, i18n.t('shortDays.tuesday').string, i18n.t('shortDays.wednesday').string, i18n.t('shortDays.thursday').string, i18n.t('shortDays.friday').string, i18n.t('shortDays.saturday').string],
          //             toolbar: {
          //                 exportToSVG: i18n.t('exportSvg').string,
          //                 exportToPNG: i18n.t('exportPng').string,
          //                 selection: i18n.t('selection').string,
          //                 selectionZoom: i18n.t('selectionZoom').string,
          //                 zoomIn: i18n.t('zoomIn').string,
          //                 zoomOut: i18n.t('zoomOut').string,
          //                 pan: i18n.t('pan').string,
          //                 reset: i18n.t('reset').string,
          //                 menu: i18n.t('menu').string,
          //             }
          //         }
          //     },
          //     {
          //         name: 'pl',
          //         options: {
          //             months: [i18n.t('months.january').string, i18n.t('months.february').string, i18n.t('months.march').string, i18n.t('months.april').string, i18n.t('months.may').string, i18n.t('months.june').string, i18n.t('months.july').string, i18n.t('months.august').string, i18n.t('months.september').string, i18n.t('months.october').string, i18n.t('months.november').string, i18n.t('months.december').string],
          //             shortMonths: [i18n.t('shortMonths.january').string, i18n.t('shortMonths.february').string, i18n.t('shortMonths.march').string, i18n.t('shortMonths.april').string, i18n.t('shortMonths.may').string, i18n.t('shortMonths.june').string, i18n.t('shortMonths.july').string, i18n.t('shortMonths.august').string, i18n.t('shortMonths.september').string, i18n.t('shortMonths.october').string, i18n.t('shortMonths.november').string, i18n.t('shortMonths.december').string],
          //             days: [i18n.t('weekDays.sunday').string, i18n.t('weekDays.monday').string, i18n.t('weekDays.tuesday').string, i18n.t('weekDays.wednesday').string, i18n.t('weekDays.thursday').string, i18n.t('weekDays.friday').string, i18n.t('weekDays.saturday').string],
          //             shortDays: [i18n.t('shortDays.sunday').string, i18n.t('shortDays.monday').string, i18n.t('shortDays.tuesday').string, i18n.t('shortDays.wednesday').string, i18n.t('shortDays.thursday').string, i18n.t('shortDays.friday').string, i18n.t('shortDays.saturday').string],
          //             toolbar: {
          //                 exportToSVG: i18n.t('exportSvg').string,
          //                 exportToPNG: i18n.t('exportPng').string,
          //                 selection: i18n.t('selection').string,
          //                 selectionZoom: i18n.t('selectionZoom').string,
          //                 zoomIn: i18n.t('zoomIn').string,
          //                 zoomOut: i18n.t('zoomOut').string,
          //                 pan: i18n.t('pan').string,
          //                 reset: i18n.t('reset').string,
          //                 menu: i18n.t('menu').string,
          //             }
          //         }
          //     }],

        },
        tooltip: {
          x: {
            format: 'yyyy-MM-dd HH:mm'
          },
          marker: {
            show: false
          }
        },
        colors: ['#FFDC36', '#34B082', '#C76563'],
        series: [],
        stroke: {
          width: 2
        },
        xaxis: {
          type: 'datetime',
          format: 'HH:mm',
          tooltip: false,
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM yy',
              day: 'dd MMM',
              hour: 'HH:mm'
            },
            style: {
              colors: '#727376',
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function formatter(value) {
              return isNaN(value) ? value : Number(value).toFixed(2);
            },
            style: {
              color: '#FFDC36',
              fontSize: '10px',
              cssClass: 'apexcharts-yaxis-label'
            }
          }
        }
      };
      this.set('options', options);
    }
  });

  _exports.default = _default;
});