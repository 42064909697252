define("admin/services/api", ["exports", "ember-get-config", "ember-ajax/services/ajax"], function (_exports, _emberGetConfig, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    namespace: _emberGetConfig.default.api.namespace,
    host: _emberGetConfig.default.api.host,
    session: Ember.inject.service(),
    localSettings: Ember.inject.service('local-settings'),
    headers: Ember.computed('session.session.content.authenticated.idToken', {
      get: function get() {
        var headers = {};
        var idToken = this.get('session.session.content.authenticated.idToken');

        if (idToken) {
          headers['Authorization'] = "Bearer ".concat(idToken);
        }

        var platform = this.get('localSettings').get('settings.selectedPlatform');

        if (platform) {
          Ember.Logger.log('Platform: ', platform);
          headers['trk-platform'] = platform.value;
        }

        return headers;
      }
    })
  });

  _exports.default = _default;
});