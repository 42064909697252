define("admin/helpers/get-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getProperty = getProperty;
  _exports.default = void 0;

  function getProperty(params
  /*, hash*/
  ) {
    var key = params[0];
    var object = params[1];

    if (key && object) {
      return object.get(key);
    } else {
      return null;
    }
  }

  var _default = Ember.Helper.helper(getProperty);

  _exports.default = _default;
});