define("admin/components/payer-company-stat/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['panel', 'panel-info'],
    activeCompaniesOnly: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('expanded', false);
    },
    filteredResults: Ember.computed('showIssuesOnly', 'activeCompaniesOnly', 'payerCompany.subscriptions', function () {
      if (this.get('expanded')) {
        var subscriptions = this.get("payerCompany.subscriptions");
        var issuesOnly = this.get('showIssuesOnly');
        var activeOnly = this.get('activeCompaniesOnly');

        var issuesCheck = function issuesCheck() {
          return true;
        };

        var activeCheck = function activeCheck() {
          return true;
        };

        if (issuesOnly) {
          issuesCheck = function issuesCheck(diff, diffLicenses) {
            return diff || diffLicenses;
          };
        }

        if (activeOnly) {
          activeCheck = function activeCheck(company) {
            return company.get('enabled');
          };
        }

        return subscriptions.filter(function (subscription) {
          return issuesCheck(subscription.get('diff'), subscription.get('diffLicenses')) && activeCheck(subscription);
        });
      } else return [];
    }),
    searchResults: Ember.computed('search', 'filteredResults', function () {
      if (this.get('expanded')) {
        var subscriptions = this.get("filteredResults");
        var search = this.get("search");

        if (search.length) {
          search = search.toLowerCase();
          return subscriptions.filter(function (subscription) {
            var name = subscription.get('name');
            return name ? name.toLowerCase().includes(search) || subscription.get('id').includes(search) : false;
          });
        } else {
          return subscriptions;
        }
      } else return [];
    })
  });

  _exports.default = _default;
});