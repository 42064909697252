define("admin/models/vehicle", ["exports", "ember-data", "ember-data-model-fragments", "moment"], function (_exports, _emberData, _emberDataModelFragments, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    group: _emberData.default.belongsTo("group", {
      allowNull: true,
      async: true
    }),
    company: _emberData.default.belongsTo("group", {
      allowNull: true,
      async: true
    }),
    // groupID:            DS.attr("string"),
    companyID: _emberData.default.attr("string"),
    userID: _emberData.default.attr("string"),
    make: _emberData.default.attr("string"),
    model: _emberData.default.attr("string"),
    vin: _emberData.default.attr("string"),
    year: _emberData.default.attr("number"),
    type: _emberData.default.attr("string"),
    color: _emberData.default.attr("string"),
    seats: _emberData.default.attr("number"),
    timetableID: _emberData.default.attr("string"),
    obuID: _emberData.default.attr("string"),
    licensePlate: _emberData.default.attr("string"),
    registrationDate: _emberData.default.attr("date"),
    payloadCapacity: _emberData.default.attr("number"),
    tireSize: _emberData.default.attr("string"),
    hasRelay: _emberData.default.attr("boolean"),
    fuel: _emberDataModelFragments.default.fragment("vehicle-fuel", {
      allowNull: true
    }),
    engine: _emberDataModelFragments.default.fragment("vehicle-engine", {
      allowNull: true
    }),
    status: _emberDataModelFragments.default.fragment("vehicle-status", {
      allowNull: true
    }),
    settings: _emberDataModelFragments.default.fragment("vehicle-settings", {
      allowNull: true
    }),
    sensors: _emberDataModelFragments.default.fragmentArray("vehicle-sensor"),
    conversions: _emberDataModelFragments.default.fragmentArray('vehicle-conversion', {
      allowNull: true
    }),
    devices: _emberData.default.hasMany('device', {
      allowNull: true,
      inverse: 'vehicle'
    })
  });

  _exports.default = _default;
});