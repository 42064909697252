define("admin/adapters/application", ["exports", "ember-data", "admin/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.api.host,
    namespace: _environment.default.api.namespace,
    authorizer: 'authorizer:jwt',
    session: Ember.inject.service(),
    localSettings: Ember.inject.service('local-settings'),
    headers: Ember.computed('session.data.authenticated.accessToken', function () {
      var headers = {};

      var _this$get = this.get('session.data.authenticated'),
          idToken = _this$get.idToken;

      if (this.get('session.isAuthenticated')) {
        // OAuth 2
        headers['Authorization'] = "Bearer ".concat(idToken);
      }

      var platform = this.get('localSettings').get('settings.selectedPlatform');

      if (platform) {
        headers['trk-platform'] = platform.value;
      }

      return headers;
    }),
    keyForRelationship: function keyForRelationship(key, relationship) {
      if (relationship === 'belongsTo') {
        return "".concat(key, "ID");
      }
    }
  });

  _exports.default = _default;
});