define("admin/components/vehicle-refuels-edit/component", ["exports", "admin/mixins/validations", "moment"], function (_exports, _validations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validations.default, {
    api: Ember.inject.service('api'),
    store: Ember.inject.service(),
    classNames: ["refuel-row"],
    tagName: "tr",
    editing: false,
    originalValue: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.originalValue = Object.assign({}, this.refuel);
    },
    actions: {
      toggleEdit: function toggleEdit() {
        this.set('editing', true);
      },
      cancelEdit: function cancelEdit() {
        this.set('refuel', Object.assign({}, this.originalValue));
        this.set('editing', false);
      },
      saveEdit: function saveEdit() {
        var d = new Date();
        this.refuel.timestamp = this.refuel.date.valueOf() + Math.abs(d.getTimezoneOffset()) * 60000;
        this.refuel.date = (0, _moment.default)(this.refuel.date).format('YYYY/MM/DD HH:mm');
        this.set('editing', false);
        this.get('api').post("refuels/".concat(this.get('refuel.id')), {
          'invoice': '007',
          'amount': Number(this.get('refuel.amount')),
          'timestamp': this.get('refuel.timestamp')
        }).then(function (data) {
          Ember.Logger.log(data);
        }).catch(function (err) {
          Ember.Logger.log(err);
        });
      },
      markAsFluctuation: function markAsFluctuation() {
        var _this = this;

        this.get('api').post("refuels/".concat(this.get('refuel.id'), "/infirm")).then(function (data) {
          _this.set('refuel.type', data.updatedRefuel.type);
        }).catch(function (err) {
          Ember.Logger.log(err);
        });
      },
      markAsDeleted: function markAsDeleted() {
        var _this2 = this;

        this.get('api').del('refuels/' + this.get('refuel.id')).then(function () {
          _this2.$().remove();
        }).catch(function (err) {
          Ember.Logger.log(err);
        });
      }
    }
  });

  _exports.default = _default;
});