define("admin/components/offer-detail-old-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    editing: false,
    custodyOptions: [{
      value: 'sold',
      placeholder: 'Sold'
    }, {
      value: 'custody',
      placeholder: 'In custody'
    }],
    formatMoney: function formatMoney(number, decPlaces, thouSeparator, decSeparator) {
      var n = number,
          decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
          decSeparator = decSeparator == undefined ? "." : decSeparator,
          thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
          sign = n < 0 ? "-" : "",
          i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
          j = (j = i.length) > 3 ? j % 3 : 0;
      return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
    },
    formatEuropean: function formatEuropean(number) {
      return this.formatMoney(number, 2, '.', ',');
    },
    invoicingIntervalEn: Ember.computed('invoicingInterval', function () {
      var intervals = {
        1: "Monthly",
        6: "Biannually",
        12: "Yearly"
      };

      if (this.get('invoicingInterval')) {
        return intervals[this.get('invoicingInterval')];
      } else {
        return intervals[1];
      }
    }),
    subscription: Ember.computed('invoicingInterval', 'detail.serviceType', function () {
      var subscriptions = {
        1: this.get('detail.serviceType.monthlyFee'),
        12: this.get('detail.serviceType.yearlyFee')
      };

      if (this.get('invoicingInterval')) {
        return subscriptions[this.get('invoicingInterval')];
      } else {
        return subscriptions[1];
      }
    }),
    custody: Ember.computed('invoicingInterval', 'detail.deviceType', function () {
      var subscriptions = {
        1: this.get('detail.deviceType.monthlyCustody'),
        12: this.get('detail.deviceType.yearlyCustody')
      };

      if (this.get('invoicingInterval')) {
        return subscriptions[this.get('invoicingInterval')];
      } else {
        return subscriptions[1];
      }
    }),
    costs: Ember.computed('detail.custody', 'detail.subscription', 'showCustody', 'invoicingInterval', function () {
      if (this.get('showCustody')) {
        return this.formatEuropean(parseFloat(this.get('detail.custody')) + parseFloat(this.get('detail.recurringServiceFee')));
      } else {
        return this.formatEuropean(parseFloat(this.get('detail.recurringServiceFee')));
      }
    }),
    showCustody: Ember.computed('detail.custodyStatus', function () {
      return this.get('detail.custodyStatus') === 'custody';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setDetailObjectDefaultValues();

      if (!this.get('detail')) {
        this.set('detail', this.get('store').createFragment('offer-details-old'));
      }

      if (this.get('isNewDetail')) {
        this.set('showAddButtonOnly', true);
      }

      if (!this.get('detail')) {
        this.set("detail", this.get("store").createFragment('offer-details-old'));
      }
    },
    setDetailObjectDefaultValues: function setDetailObjectDefaultValues() {
      var detail = this.get("detail");
      var defaultDetail = {
        deviceType: null,
        serviceType: null,
        recurringServiceFee: null,
        price: null,
        quantity: null,
        setup: null,
        custodyStatus: null,
        custody: null
      };

      if (detail) {
        defaultDetail = {
          deviceType: detail.get("deviceType"),
          serviceType: detail.get("serviceType"),
          recurringServiceFee: detail.get("recurringServiceFee"),
          price: detail.get("price"),
          quantity: detail.get("quantity"),
          setup: detail.get("setup"),
          custodyStatus: detail.get('custodyStatus'),
          custody: detail.get('custody')
        };
      }

      this.set("detailObject", this.get('store').createFragment('offer-details-old', defaultDetail));
    },
    rollback: function rollback() {
      var initialDetail = this.get("detailObject");

      if (this.get('detail')) {
        this.set('detail.deviceType', initialDetail.get('deviceType'));
        this.set('detail.serviceType', initialDetail.get('serviceType'));
        this.set('detail.recurringServiceFee', initialDetail.get('recurringServiceFee'));
        this.set('detail.price', initialDetail.get('price'));
        this.set('detail.quantity', initialDetail.get('quantity'));
        this.set('detail.setup', initialDetail.get('setup'));
        this.set('detail.custodyStatus', initialDetail.get('custodyStatus'));
        this.set('detail.custody', initialDetail.get('custody'));
      }
    },
    actions: {
      addDetail: function addDetail() {
        this.set('showAddButtonOnly', false);
      },
      save: function save() {
        var detail = this.get("detail");
        var isNewDetail = this.get("isNewDetail");

        if (isNewDetail) {
          this.get("addDetailToOffer")(detail);
        }

        this.set('editing', false);
      },
      cancel: function cancel() {
        this.rollback();
        this.set('editing', false);

        if (this.get('isNewDetail')) {
          this.get('cancelDetail')();
          this.set('showAddButtonOnly', true);
        }
      },
      remove: function remove() {
        var detail = this.get("detail");
        this.get("removeDetail")(detail);
      },
      selectCustodyStatus: function selectCustodyStatus(status) {
        this.set('detail.custodyStatus', status);
      },
      changedIsDeviceAddon: function changedIsDeviceAddon() {
        var isDeviceAddon = this.get('detail.isDeviceAddon') ? true : false;
        this.set('detail.isDeviceAddon', !isDeviceAddon);
      }
    }
  });

  _exports.default = _default;
});