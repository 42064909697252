define("admin/components/device-line/component", ["exports", "flat"], function (_exports, _flat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function abbr(text) {
    return text.toLowerCase().replace(/[._-]/g, ' ').split(' ').map(function (w) {
      return isNaN(w) ? w[0] : w;
    }).join('').toUpperCase();
  }

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    device: {},
    electricians: [],
    allVehicles: [],
    selectedGroupID: null,
    selectedGroup: null,
    states: [{
      id: 'service',
      name: 'Service'
    }, {
      id: 'faulty',
      name: 'Faulty'
    }, {
      id: 'uninstalled',
      name: 'Uninstalled'
    }],
    vehicles: Ember.computed('selectedGroupID', 'allVehicles.[]', function () {
      if (!this.get("selectedGroupID")) return [];
      return this.get('allVehicles').toArray().filterBy('companyID', this.get("selectedGroupID"));
    }),
    response: Ember.computed('testResponse.timestamp', function () {
      var data = [];
      var response = this.get('testResponse');

      if (!Ember.isEmpty(response)) {
        var trimmed = Ember.Object.create(response).getProperties(['timestamp', 'gps', 'engine_state', 'io.analog_1', 'io.analog_2', 'io.digital_in_1', 'io.digital_in_2', 'io.digital_in_3', 'io.digital_out_1', 'io.digital_out_2', 'io.power_ext', 'io.power_bat', 'io.ibutton_id', 'can', 'fuel_level', 'fuel_level_can', 'sensors.custom', 'sensors.door-back-left', 'sensors.door-back-right', 'sensors.door-front-left', 'sensors.door-front-right', 'sensors.door-trunk', 'sensors.engine-load', 'sensors.freezer', 'sensors.fuel-1', 'sensors.fuel-2', 'sensors.taxi-available', 'sensors.taxi-occupied', 'sensors.temperature', 'tacho.odometer', 'tacho.driver_id_1', 'tacho.driver_id_2', 'tacho.driver_time_1', 'tacho.driver_time_2', 'mobileye']);
        var flat = (0, _flat.default)(trimmed, {
          safe: true
        });

        for (var key in flat) {
          var item = {
            name: key,
            abbr: abbr(key),
            value: flat[key]
          };

          switch (key) {
            case 'timestamp':
              item.value = new Date(item.value).toISOString();
              break;

            case 'gps.coordinates':
              item.value = item.value ? [item.value[1].toFixed(4), item.value[0].toFixed(4)] : undefined;
              break;

            case 'gps.altitude':
              item.unit = 'm';
              break;

            case 'can.distance':
            case 'tacho.odometer':
              item.value = parseInt(item.value / 1000);
              item.unit = 'km';
              break;

            case 'can.fuel_used':
              item.value = item.value / 10;
              item.unit = 'L';
              break;

            case 'gps.speed':
            case 'can.speed':
              item.unit = 'km/h';
              break;

            case 'can.acceleration':
            case 'can.engine_load':
            case 'sensors.engine-load':
              item.unit = '%';
              break;

            case 'can.engine_temperature':
            case 'sensors.temperature':
              item.unit = '°C';
              break;

            case 'io.analog_1':
            case 'io.analog_2':
            case 'io.power_ext':
            case 'io.power_bat':
              item.value = item.value / 1000;
              item.unit = 'V';
              break;

            case 'fuel_level':
            case 'fuel_level_can':
            case 'sensors.fuel-1':
            case 'sensors.fuel-2':
              item.unit = 'L';
              break;
          }

          data.push(item);
        }
      }

      return data;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("selectedGroupID", this.get("device.companyID"));
      this.set("selectedGroup", this.get("device.group"));
      this.set('temporaryDevicesStock', this.get('device.devicesStock'));
      this.set("status", "default");
      this.set('testResponse', null);
      this.set('lastSent', null);
      this.set('edit', false);
      this.set('loadingVehicles', false);
    },
    matchElectrician: function matchElectrician(electrician, term) {
      var matchAgainst = electrician.get("firstName") + electrician.get("lastName") + electrician.get("email") + electrician.get("phoneNumber");
      return matchAgainst.toLowerCase().indexOf(term.toLowerCase());
    },
    matchVehicles: function matchVehicles(vehicle, term) {
      var searchString = vehicle.get("make") + vehicle.get("model") + vehicle.get("year") + vehicle.get("vin") + vehicle.get("licensePlate");
      return searchString.toLowerCase().indexOf(term.toLowerCase());
    },
    actions: {
      delete: function _delete() {
        this.sendAction("delete", this.get("device"));
      },
      edit: function edit() {
        this.get("device.group.id");
        this.toggleProperty("edit");
        this.sendAction("getElectricians");
        this.get("device").rollbackAttributes();
        this.set("selectedGroup", this.get("device.group"));
        this.set("selectedGroupID", this.get("device.group.id"));
        this.set('selectedVehicle', this.get('device.vehicle'));
        this.set("temporaryDevicesStock", this.get('device.devicesStock'));
      },
      update: function update() {
        Ember.Logger.log("Updating: ", this.get("device"));
        this.set("device.group", this.get("selectedGroup"));
        this.set("device.companyID", this.get("selectedGroupID"));
        this.set("device.devicesStock", this.get("temporaryDevicesStock"));
        this.set('device.vehicle', this.get('selectedVehicle'));
        this.sendAction("update", this.get("device"), this);
      },
      testDevice: function testDevice() {
        Ember.Logger.log("testing device");

        var _this = this;

        this.sendAction("testDevice", {
          device_sn: this.get("device.sn"),
          deviceComponent: _this
        });
      },
      unassignGroup: function unassignGroup() {
        this.set("selectedGroup", null);
        this.set("selectedGroupID", null);
        this.set("selectedVehicle", null);
        this.set("temporaryDevicesStock", this.get('device.devicesStock'));
      },
      unassignVehicle: function unassignVehicle() {
        this.set("selectedVehicle", null);
        this.set("temporaryDevicesStock", this.get('device.devicesStock'));
      },
      assignGroup: function assignGroup(group) {
        this.set("selectedGroup", group);
        this.set("selectedGroupID", group.get("companyID"));
        this.set("selectedVehicle", null);
        this.set("temporaryDevicesStock", null);
        Ember.Logger.log("Group company", group.get("companyID"));
      },
      assignVehicle: function assignVehicle(vehicle) {
        this.set("selectedVehicle", vehicle);
      },
      assignElectrician: function assignElectrician(electrician) {
        this.set("device.electrician", electrician);
      },
      unassignElectrician: function unassignElectrician() {
        this.set("device.electrician", null);
      },
      assignDevicesStock: function assignDevicesStock(devicesStock) {
        this.set("temporaryDevicesStock", devicesStock);
      },
      unassignDevicesStock: function unassignDevicesStock() {
        this.set("temporaryDevicesStock", null);
      },
      confirmDisconnect: function confirmDisconnect() {
        this.get('api').request("devices/".concat(this.get('device.sn'), "/disconnect")).then(function (_ref) {
          var device = _ref.device,
              meta = _ref.meta;
          Ember.Logger.log('Device: ', device), Ember.Logger.log('Meta data: ', meta);
        }).catch(function (error) {
          Ember.Logger.error('Attempt to disconnect the device has failed: ', error);
        });
      },
      confirmClearHistory: function confirmClearHistory() {
        this.get('api').request("devices/".concat(this.get('device.sn'), "/history"), {
          method: 'DELETE'
        }).then(function (_ref2) {
          var device = _ref2.device,
              meta = _ref2.meta;
          Ember.Logger.log('Device: ', device), Ember.Logger.log('Meta data: ', meta);
        }).catch(function (error) {
          Ember.Logger.error('Attempt to disconnect the device has failed: ', error);
        });
      },
      cancel: function cancel() {
        this.set('confirmationDisconnect', false);
        this.set('confirmationClearHistory', false);
        this.set('confirmationVisible', false);
      },
      disconnect: function disconnect() {
        this.set('confirmationDisconnect', true);
        this.set('confirmationVisible', true);
      },
      clearHostory: function clearHostory() {
        this.set('confirmationClearHistory', true);
        this.set('confirmationVisible', true);
      },
      changeVehicleState: function changeVehicleState(vehicle, state) {
        Ember.Logger.debug('Changing vehicle state', state, vehicle);

        if (Ember.isEmpty(vehicle)) {
          return;
        }

        this.get('api').request("vehicles/".concat(vehicle.get('id'), "/status"), {
          method: 'PATCH',
          data: {
            status: {
              state: state.id
            }
          }
        }).then(function () {
          vehicle.set('status.state', state.id);
        });
      }
    }
  });

  _exports.default = _default;
});