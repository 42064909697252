define("admin/components/pagination-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['paginationComponent'],
    allowedResultsPerPage: [5, 10, 25, 50],
    lastPage: Ember.computed("recordsCount", "resultPerPage", function () {
      return Math.ceil(this.get("recordsCount") / this.get("resultPerPage"));
    }),
    prevAvailable: Ember.computed("pageIndex", function () {
      return this.get("pageIndex") > 1;
    }),
    nextAvailable: Ember.computed("pageIndex", "lastPage", function () {
      return this.get("pageIndex") < this.get("lastPage");
    }),
    actions: {
      selecetOnFocus: function selecetOnFocus(event) {
        event.target.select();
      },
      // setResultPerPage(results) {
      //     if (!results) { return false; }
      //     this.get("updateResultsPerPage")(results)
      // },
      // showAll() {
      //     this.get("toggleShowAll")()
      // },
      turnPage: function turnPage(toPage) {
        var pageNumber = 1;
        var currentPage = Number(this.get("pageIndex"));

        if (isNaN(toPage)) {
          if (toPage === "prev") {
            pageNumber = currentPage - 1;
          } else if (toPage === "next") {
            pageNumber = currentPage + 1;
          }
        } else {
          if (toPage >= 1 && toPage <= this.get("lastPage")) {
            pageNumber = toPage;
          } else {
            if (toPage > this.get('lastPage')) {
              pageNumber = this.get("lastPage");
            } else {
              pageNumber = 1;
            }
          }
        }

        this.get("updatePage")(pageNumber);
      }
    }
  });

  _exports.default = _default;
});