define("admin/models/group-settings", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    locale: _emberData.default.attr("string", {
      allowNull: true
    }),
    timezone: _emberData.default.attr("string", {
      allowNull: true
    }),
    VAT: _emberData.default.attr('number', {
      allowNull: true
    }),
    showExternalID: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    share: _emberData.default.attr(),
    speedLimit: _emberData.default.attr(),
    countryCosts: _emberData.default.attr(),
    reports: _emberData.default.attr(),
    emailList: _emberData.default.attr(),
    phoneList: _emberData.default.attr(),
    order: _emberData.default.attr()
  });

  _exports.default = _default;
});