define("admin/serializers/invoice-payment", ["exports", "admin/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(model, hash, prop) {
      hash.id = "".concat(hash.invoiceID, "-").concat(hash.paymentID);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});