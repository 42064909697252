define("admin/components/company-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EqCX9spB",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-12\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"alert alert-info\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"note-content col-xs-12\"],[7],[0,\"\\n                    \"],[6,\"p\"],[7],[1,[20,[\"note\",\"content\"]],false],[8],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-sm-6 note-author\"],[7],[0,\"\\n                    \"],[6,\"i\"],[7],[0,\"\\n                        \"],[1,[20,[\"note\",\"name\"]],false],[0,\",\\n                        \"],[1,[20,[\"note\",\"email\"]],false],[0,\"\\n                    \"],[8],[0,\"\\n                \"],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-sm-6\"],[7],[0,\"\\n                    \"],[6,\"i\"],[9,\"class\",\"pull-right note-author\"],[7],[0,\"\\n                        \"],[1,[25,\"moment-format\",[[20,[\"note\",\"createdAt\"]],\"DD/MM YYYY HH:mm\"],null],false],[0,\"\\n                    \"],[8],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "admin/components/company-note/template.hbs"
    }
  });

  _exports.default = _default;
});