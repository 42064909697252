define("admin/components/vehicle-refuels-add/component", ["exports", "admin/mixins/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validations.default, {
    api: Ember.inject.service('api'),
    store: Ember.inject.service(),
    classNames: ["refuel-row"],
    tagName: "tr",
    options: {
      sources: Ember.A([{
        value: 'can',
        placeholder: 'CAN'
      }, {
        value: 'sensor',
        placeholder: 'Sensor'
      }]),
      types: Ember.A([{
        value: 'detected',
        placeholder: 'detected'
      }, {
        value: 'drop',
        placeholder: 'drop'
      }])
    },
    refuelDuration: 60,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('refuel', {
        amount: 0,
        timestamp: new Date(),
        duration: 0,
        source: 'can',
        type: 'detected',
        vehicleID: this.get('vehicle'),
        groupID: this.get('group')
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        Ember.Logger.log(this.refuelDate);
        this.refuel.duration = this.refuelDuration * 1000;
        Ember.Logger.log(this.refuel);
        this.get('api').post("refuels", {
          data: {
            refuel: this.refuel
          }
        }).then(function (data) {
          _this.sendAction('refreshRefuelData');

          Ember.Logger.log(data);

          _this.sendAction('triggerAdd');
        }).catch(function (err) {
          Ember.Logger.log(err);
        });
      },
      cancel: function cancel() {
        this.sendAction('triggerAdd');
      },
      selectRefuelType: function selectRefuelType(data) {
        this.set('refuel.type', data.value);
      },
      selectRefuelSource: function selectRefuelSource(data) {
        this.set('refuel.source', data.value);
      }
    }
  });

  _exports.default = _default;
});