define("admin/models/device-config-sim", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    phone_number: _emberData.default.attr("string"),
    imsi: _emberData.default.attr("string"),
    carrier: _emberData.default.attr("string"),
    pin: _emberData.default.attr("number"),
    puk: _emberData.default.attr("number")
  });

  _exports.default = _default;
});