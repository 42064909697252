define("admin/models/vehicle-fuel-tank", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    capacity: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    calibration: _emberDataModelFragments.default.fragmentArray("vehicle-fuel-tank-calibration", {
      defaultValue: [{
        level: null,
        voltage: null
      }]
    })
  });

  _exports.default = _default;
});