define("admin/models/offer-details", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    deviceTypeID: _emberData.default.attr('string'),
    serviceTypeID: _emberData.default.attr('string'),
    recurringServiceFee: _emberData.default.attr('number'),
    custody: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    setup: _emberData.default.attr('number'),
    custodyStatus: _emberData.default.attr('string'),
    deviceType: Ember.computed("deviceTypeID", function () {
      if (this.get('deviceTypeID')) {
        return this.get('store').peekRecord('device-type', this.get('deviceTypeID'));
      } else {
        return null;
      }
    }),
    serviceType: Ember.computed('serviceTypeID', function () {
      if (this.get('serviceTypeID')) {
        return this.get('store').peekRecord('service-type', this.get('serviceTypeID'));
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});