define("admin/components/payment-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['payment-edit'],
    paymentTypes: [{
      value: 'chitanta',
      label: 'Chitanță'
    }, {
      value: 'op',
      label: 'Ordin de plată'
    }],
    currencies: [{
      value: 'RON',
      label: 'RON'
    }, {
      value: 'EUR',
      label: 'EUR'
    }],
    store: Ember.inject.service(),
    hasInvoices: Ember.computed('payment.paymentInvoices.[]', function () {
      return this.get('payment.paymentInvoices.length');
    }),
    usedAmount: Ember.computed('payment.paymentInvoices.@each.amount', function () {
      return this.get('payment.paymentInvoices').toArray().reduce(function (total, paymentInvoice) {
        total += parseFloat(paymentInvoice.get('amount'));
        return total;
      }, 0);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('payment')) {
        this.set('payment', this.get('store').createRecord('payment', {
          currency: 'RON',
          type: 'op',
          exchange: 1
        }));
        this.set('selectedDate', new Date());
        this.set('isNew', true);
        this.set('editing', true);
        Ember.Logger.log('Created payment record locally.');
      } else {
        this.set('editing', false);
      }
    },
    matchCompanySearch: function matchCompanySearch(company, term) {
      var searchText = term.toLowerCase();

      if (company.get('name')) {
        var name = company.get('name').toLowerCase();
        var cui = company.get('details.CUI') ? company.get('details.CUI').toLowerCase() : '';
        return name.includes(searchText) || cui.includes(searchText) ? 1 : -1;
      } else {
        return false;
      }
    },
    invoiceNumbers: Ember.computed('payment.invoices', function () {
      var numbers = this.get('payment.paymentInvoices').toArray().map(function (paymentInvoice) {
        return "".concat(paymentInvoice.get('invoice.series')).concat(paymentInvoice.get('invoice.number'));
      });
      return numbers.join(',');
    }),
    actions: {
      selectPaymentType: function selectPaymentType(paymentType) {
        this.set('payment.type', paymentType);
      },
      selectInvoice: function selectInvoice(invoice) {
        var alreadySelected = this.get('payment.paymentInvoices').find(function (paymentInvoice) {
          return paymentInvoice.get('invoice.id') === invoice.get('id');
        });

        if (!alreadySelected) {
          this.get('payment.paymentInvoices').pushObject(this.get('store').createRecord('invoice-payment', {
            invoice: invoice,
            payment: this.get('payment'),
            amount: 0
          }));
          this.set('payment.currency', invoice.get('currency'));
        }
      },
      deselectInvoice: function deselectInvoice(invoicePayment) {
        this.get('payment.paymentInvoices').removeObject(invoicePayment);
      },
      payInFull: function payInFull(invoicePayment) {
        invoicePayment.set('amount', invoicePayment.get('invoice.total'));
      },
      sumPaymentInvoices: function sumPaymentInvoices() {
        this.set('payment.amount', this.get('usedAmount'));
      },
      selectCompany: function selectCompany(company) {
        var _this = this;

        this.set('selectedCompany', company);
        var companyId = this.get('selectedCompany.id');

        if (companyId) {
          return this.get('store').query('invoice', {
            $where: {
              companyID: this.get('selectedCompany.id'),
              '!=': {
                status: 'incasata'
              }
            }
          }).then(function (invoices) {
            _this.set('selectedCompanyInvoices', invoices);
          });
        } else {
          return Ember.RSVP.resolve(null);
        }
      },
      debug: function debug(date) {
        Ember.Logger.log('The fucking date: ');
        Ember.Logger.log(date);
        Ember.Logger.log(this.get('selectedDate')); // this.set('payment.date', moment(date));
      },
      save: function save() {
        var _this2 = this;

        if (!this.get('selectedDate')) {
          this.set('payment.date', new Date());
        } else {
          this.set('payment.date', this.get('selectedDate'));
        }

        this.get('payment').save().then(function (payment) {
          _this2.set('payment', payment); // Remove the leftover records.


          var paymentInvoices = _this2.get('payment.paymentInvoices').toArray();

          paymentInvoices.forEach(function (paymentInvoice) {
            if (!paymentInvoice.get('id')) {
              _this2.get('payment.paymentInvoices').removeObject(paymentInvoice);
            }
          });

          _this2.set('editing', false);
        }).catch(function (error) {
          Ember.Logger.error(error);
        });
      },
      toggleEdit: function toggleEdit() {
        var _this3 = this;

        this.set('editing', !this.get('editing'));

        if (this.get('editing')) {
          if (this.get('payment.paymentInvoices.length')) {
            this.get('payment.paymentInvoices.firstObject.invoice').then(function (invoice) {
              _this3.set('selectedCompany', invoice.get('company'));
            });
          }

          if (this.get('payment.date')) {
            this.set('selectedDate', this.get('payment.date'));
            Ember.Logger.log('Payment date', this.get('payment.date'));
          } else {
            this.set('selectedDate', new date());
          }
        }

        if (!this.get('payment.id')) {
          this.sendAction('cancelNewPayment');
        }
      },
      // Make sure that the payment per invoice amount does not excede the invoice amount.
      validateInvoicePaymentAmount: function validateInvoicePaymentAmount(paymentInvoice) {
        var toPay = paymentInvoice.get('invoice.remainingAmount');

        if (toPay < paymentInvoice.get('amount')) {
          paymentInvoice.set('amount', toPay);
        }
      }
    }
  });

  _exports.default = _default;
});